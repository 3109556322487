import React, { useEffect, useState } from 'react'
import { IoIosPlay } from "react-icons/io";
import { httpPost } from '../../../middelware/Http'
import Blinking from '../../Blinking/Blinking';
import { useNavigate } from "react-router-dom";
import { userAction } from '../../../redux/action/userAction';
import { useDispatch, useSelector } from 'react-redux';
import cricket from "../../../Assest/images/game/cricket.webp"
import inplay1 from "../../../../src/Assest/images/InPlay 2/inplayico.webp"
import inplayTennis from "../../../../src/Assest/images/InPlay 2/tennis.792e646.webp"
import inplayCricket from "../../../../src/Assest/images/InPlay 2/cricket.28f3d5c.webp"
import inplayElection from "../../../../src/Assest/images/InPlay 2/election.3202e38.webp"
import Soccer from "../../../../src/Assest/images/SubHeaderImage/Soccer.webp"
import moment from 'moment';



const Inplay = () => {
    const [inplayMatch, setInplayMatch] = useState([]);

    const { sportmatchlist } = useSelector(state => state.authentication);
    const navigate = useNavigate();

    useEffect(() => {
        let matches = [];

        if (sportmatchlist?.data) {
            matches = sportmatchlist.data;
        } else {
            // If sportmatchlist is not available, fetch data from localStorage
            let matchListData;
            try {
                matchListData = JSON.parse(localStorage.getItem('MatchListData')) || [];
            } catch (error) {
                console.error('Failed to parse localStorage data:', error);
                matchListData = [];
            }

            if (matchListData?.sportlist?.data) {
                matches = matchListData.sportlist.data;
            }
        }

        // Sort matches by matchDate
        const filteredMatches = matches.filter(match => match.sportId !== 999);
        const sortedMatches = filteredMatches.sort((a, b) => moment(a.matchDate, "DD-MM-YYYY HH:mm:ss").isBefore(moment(b.matchDate, "DD-MM-YYYY HH:mm:ss")) ? -1 : 1 );
        setInplayMatch(sortedMatches);
    }, [sportmatchlist]);

    // useEffect(() => {
    //     const matchListData = JSON.parse(localStorage.getItem("MatchListData"));
    //     if (matchListData && matchListData.sportlist && matchListData.sportlist.data) {
    //         setInplayMatch(matchListData.sportlist.data);
    //     }
    //     else {
    //         setInplayMatch(sportmatchlist?.data);
    //     }
    // }, []);




    const handalOnClick = (element) => {
        navigate(`/viewMatch/${element.marketId}/${element.eventId}`);
    }



    return (
        <section className='lg:flex space-x-1 lg:space-y-0 space-y-1 w-full px-2 py-1'>
            <div className='w-full'>
                <h5 className='flex px-2 py-2 uppercase items-center'>
                    <img src={inplay1} alt="lgoog" lazy-src={inplay1} className="pr-2" style={{ width: "1.9rem" }} />
                    <span className='text-white font-bold text-sm'>Inplay</span>
                </h5>
                <div className='relative space-y-4 uppercase'>
                    <div>
                        {inplayMatch && inplayMatch.length > 0 && inplayMatch.filter((e) => e.sportId === 4).length > 0 && (
                            <div className='rounded-2xl '>
                                <header className='bg-[#333333] rounded-t-2xl'>
                                    <div className=' items-center flex relative z-0'>
                                        <div className='w-4/12 rounded-tl-2xl py-2  bg-[#f36c21] flex'>
                                            <span className='inline-flex px-2  rounded items-center relative text-center overflow-hidden'>
                                                <img src={cricket} alt='sportImg' width={22} height={22} />
                                            </span>
                                            <span className='uppercase items-center inline-flex text-sm font-bold text-white'>
                                                cricket
                                            </span>
                                        </div>
                                        <div className='w-4/12'></div>
                                        <div className='w-4/12  bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center'>
                                            <div className=' w-full bg-[#333333] rounded-tr-2xl flex flex-wrap justify-center px-4 py-2 items-center'>
                                                <span className='w-4/12 text-white text-sm font-normal text-center'>1</span>
                                                <span className='w-4/12 text-white text-sm font-normal text-center'>X</span>
                                                <span className='w-4/12 text-white text-sm font-normal text-center'>2</span>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <hr />
                                {inplayMatch
                                    .filter((element) => element.sportId === 4)
                                    .map((element, index) => (
                                        <React.Fragment key={index}> {/* Ensure each element has a unique identifier */}
                                            <div className={`bg-white shadow-sm flex decoration-none whitespace-normal max-w-full ${index === inplayMatch.length - 1 ? 'rounded-b-0' : ''}`} onClick={() => handalOnClick(element)}>
                                                <div className='w-2/3  flex px-4'>
                                                    <div className='w-11/12  py-1 leading-3 flex items-center text-slate-700 '>
                                                        <span className="text-sm font-bold truncate">
                                                            {element.matchName !== null && element.matchName !== undefined ? element.matchName : element.sportName}
                                                            <span className='text-xs font-bold text-gray-500 flex items-center gap-1'>{element.matchDate}</span>
                                                        </span>
                                                    </div>
                                                    {
                                                        (() => {
                                                            const inputMoment = moment(element?.matchDate, "DD-MM-YYYY HH:mm:ss A");
                                                            const currentMoment = moment();
                                                            return currentMoment.isSameOrAfter(inputMoment) ?
                                                            <div className='flex items-center justify-center gap-x-2'>
                                                            <span className="relative flex h-2 w-2">
                                                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                                                <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
                                                            </span>
                                                        </div>
                                                                : '';
                                                        })()
                                                    }

                                                   
                                                </div>
                                                <div className='w-1/3 grid grid-cols-6 space-x-0.5'>
                                                    <span className='lg:col-span-1 col-span-3'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"1324.20"} color={"bg-[#a5d9fe]"} blinkColor={'bg-[#CDEBEB]'} />
                                                    </span>
                                                    <span className='lg:col-span-1 col-span-3'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"37.69"} color={"bg-[#f8d0ce]"} blinkColor={'bg-white'} />
                                                    </span>
                                                    <span className='lg:col-span-1 col-span-2 lg:block hidden'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={'747.51'} color={"bg-[#a5d9fe]"} />
                                                    </span>
                                                    <span className='lg:block hidden lg:col-span-1 col-span-2'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={'6.2'} color={"bg-[#f8d0ce]"} />
                                                    </span>
                                                    <span className='lg:col-span-1 col-span-2 lg:block hidden'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"11.62"} color={"bg-[#a5d9fe]"} blinkColor={'bg-[#CDEBEB]'} />
                                                    </span>
                                                    <span className='lg:block hidden lg:col-span-1 col-span-2'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"1128.79"} color={"bg-[#f8d0ce]"} blinkColor={'bg-white'} isLast={index === inplayMatch.length - 1} />
                                                    </span>
                                                </div>
                                            </div>
                                            {index < inplayMatch.length - 1 && <hr />}
                                        </React.Fragment>
                                    ))}
                            </div>
                        )}
                    </div>
                    {/* Repeat similar code for other sports with unique keys */}
                    <div>
                        {inplayMatch && inplayMatch.length > 0 && inplayMatch.filter((e) => e.sportId === 999).length > 0 && (
                            <div className='rounded-2xl '>
                                <header className='bg-[#333333] rounded-t-2xl'>
                                    <div className=' items-center flex relative z-0'>
                                        <div className='w-4/12 rounded-tl-2xl py-2  bg-[#f36c21] flex'>
                                            <span className='inline-flex px-2  rounded items-center relative text-center overflow-hidden'>
                                                <img src={inplayElection} alt='sportImg' width={22} height={22} />
                                            </span>
                                            <span className='uppercase items-center inline-flex text-sm font-bold text-white'>
                                                Election
                                            </span>
                                        </div>
                                        <div className='w-4/12'></div>
                                        <div className='w-4/12  bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center'>
                                            <div className=' w-full bg-[#333333] rounded-tr-2xl flex flex-wrap justify-center px-4 py-2 items-center'>
                                                <span className='w-4/12 text-white text-sm font-normal text-center'>1</span>
                                                <span className='w-4/12 text-white text-sm font-normal text-center'>X</span>
                                                <span className='w-4/12 text-white text-sm font-normal text-center'>2</span>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                {inplayMatch
                                    .filter((element) => element.sportId === 999)
                                    .map((element, index) => (
                                        <React.Fragment key={index}> {/* Ensure each element has a unique identifier */}
                                            <div className={`bg-white shadow-sm flex decoration-none whitespace-normal max-w-full ${index === inplayMatch.length - 1 ? 'rounded-b-2xl' : ''}`} onClick={() => handalOnClick(element)}>
                                                <div className='w-2/3  flex px-4'>
                                                    <div className='w-11/12  py-1 leading-3 flex items-center text-slate-700 '>
                                                        <span className="text-sm font-bold truncate">
                                                            {element.matchName !== null && element.matchName !== undefined ? element.matchName : element.sportName}
                                                            <span className='text-xs font-bold text-gray-500 flex items-center gap-1'>{element.matchDate}</span>
                                                        </span>
                                                    </div>
                                                    <div className='flex items-center justify-center gap-x-2'>
                                                        <span className="relative flex h-2 w-2">
                                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                                            <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='w-1/3 grid grid-cols-6 space-x-0.5'>
                                                    <span className='lg:col-span-1 col-span-3'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"1324.20"} color={"bg-[#a5d9fe]"} blinkColor={'bg-[#CDEBEB]'} />
                                                    </span>
                                                    <span className='lg:col-span-1 col-span-3'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"37.69"} color={"bg-[#f8d0ce]"} blinkColor={'bg-white'} />
                                                    </span>
                                                    <span className='lg:col-span-1 col-span-2 lg:block hidden'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={'747.51'} color={"bg-[#a5d9fe]"} />
                                                    </span>
                                                    <span className='lg:block hidden lg:col-span-1 col-span-2'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={'6.2'} color={"bg-[#f8d0ce]"} />
                                                    </span>
                                                    <span className='lg:col-span-1 col-span-2 lg:block hidden'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"11.62"} color={"bg-[#a5d9fe]"} blinkColor={'bg-[#CDEBEB]'} />
                                                    </span>
                                                    <span className='lg:block hidden lg:col-span-1 col-span-2'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"1128.79"} color={"bg-[#f8d0ce]"} blinkColor={'bg-white'} />
                                                    </span>
                                                </div>
                                            </div>
                                            {index < inplayMatch.length - 1 && <hr />}
                                        </React.Fragment>
                                    ))}
                            </div>
                        )}
                    </div>

                    <div>
                        {inplayMatch && inplayMatch.length > 0 && inplayMatch.filter((e) => e.sportId === 1).length > 0 && (
                            <div className='rounded-2xl '>
                                <header className='bg-[#333333] rounded-t-2xl'>
                                    <div className=' items-center flex relative z-0'>
                                        <div className='w-4/12 rounded-tl-2xl py-2  bg-[#f36c21] flex'>
                                            <span className='inline-flex px-2  rounded items-center relative text-center overflow-hidden'>
                                                <img src={inplayElection} alt='sportImg' width={22} height={22} />
                                            </span>
                                            <span className='uppercase items-center inline-flex text-sm font-bold text-white'>
                                                Football
                                            </span>
                                        </div>
                                        <div className='w-4/12'></div>
                                        <div className='w-4/12  bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center'>
                                            <div className=' w-full bg-[#333333] rounded-tr-2xl flex flex-wrap justify-center px-4 py-2 items-center'>
                                                <span className='w-4/12 text-white text-sm font-normal text-center'>1</span>
                                                <span className='w-4/12 text-white text-sm font-normal text-center'>X</span>
                                                <span className='w-4/12 text-white text-sm font-normal text-center'>2</span>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                {inplayMatch
                                    .filter((element) => element.sportId === 1)
                                    .map((element, index) => (
                                        <React.Fragment key={index}> {/* Ensure each element has a unique identifier */}
                                            <div className={`bg-white shadow-sm flex decoration-none whitespace-normal max-w-full ${index === inplayMatch.length - 1 ? 'rounded-b-2xl' : ''}`} onClick={() => handalOnClick(element)}>
                                                <div className='w-2/3  flex px-4'>
                                                    <div className='w-11/12  py-1 leading-3 flex items-center text-slate-700 '>
                                                        <span className="text-sm font-bold truncate">
                                                            {element.matchName !== null && element.matchName !== undefined ? element.matchName : element.sportName}
                                                            <span className='text-xs font-bold text-gray-500 flex items-center gap-1'>{element.matchDate}</span>
                                                        </span>
                                                    </div>
                                                    <div className='flex items-center justify-center gap-x-2'>
                                                        <span className="relative flex h-2 w-2">
                                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                                            <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='w-1/3 grid grid-cols-6 space-x-0.5'>
                                                    <span className='lg:col-span-1 col-span-3'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"1324.20"} color={"bg-[#a5d9fe]"} blinkColor={'bg-[#CDEBEB]'} />
                                                    </span>
                                                    <span className='lg:col-span-1 col-span-3'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"37.69"} color={"bg-[#f8d0ce]"} blinkColor={'bg-white'} />
                                                    </span>
                                                    <span className='lg:col-span-1 col-span-2 lg:block hidden'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={'747.51'} color={"bg-[#a5d9fe]"} />
                                                    </span>
                                                    <span className='lg:block hidden lg:col-span-1 col-span-2'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={'6.2'} color={"bg-[#f8d0ce]"} />
                                                    </span>
                                                    <span className='lg:col-span-1 col-span-2 lg:block hidden'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"11.62"} color={"bg-[#a5d9fe]"} blinkColor={'bg-[#CDEBEB]'} />
                                                    </span>
                                                    <span className='lg:block hidden lg:col-span-1 col-span-2'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"1128.79"} color={"bg-[#f8d0ce]"} blinkColor={'bg-white'} />
                                                    </span>
                                                </div>
                                            </div>
                                            {index < inplayMatch.length - 1 && <hr />}
                                        </React.Fragment>
                                    ))}
                            </div>
                        )}
                    </div>

                    <div>
                        {inplayMatch && inplayMatch.length > 0 && inplayMatch.filter((e) => e.sportId === 2).length > 0 && (
                            <div className='rounded-2xl '>
                                <header className='bg-[#333333] rounded-t-2xl'>
                                    <div className=' items-center flex relative z-0'>
                                        <div className='w-4/12 rounded-tl-2xl py-2  bg-[#f36c21] flex'>
                                            <span className='inline-flex px-2  rounded items-center relative text-center overflow-hidden'>
                                                <img src={inplayElection} alt='sportImg' width={22} height={22} />
                                            </span>
                                            <span className='uppercase items-center inline-flex text-sm font-bold text-white'>
                                                Tennis
                                            </span>
                                        </div>
                                        <div className='w-4/12'></div>
                                        <div className='w-4/12  bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center'>
                                            <div className=' w-full bg-[#333333] rounded-tr-2xl flex flex-wrap justify-center px-4 py-2 items-center'>
                                                <span className='w-4/12 text-white text-sm font-normal text-center'>1</span>
                                                <span className='w-4/12 text-white text-sm font-normal text-center'>X</span>
                                                <span className='w-4/12 text-white text-sm font-normal text-center'>2</span>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                {inplayMatch
                                    .filter((element) => element.sportId === 2)
                                    .map((element, index) => (
                                        <React.Fragment key={index}> {/* Ensure each element has a unique identifier */}
                                            <div className={`bg-white shadow-sm flex decoration-none whitespace-normal max-w-full ${index === inplayMatch.length - 1 ? 'rounded-b-2xl' : ''}`} onClick={() => handalOnClick(element)}>
                                                <div className='w-2/3  flex px-4'>
                                                    <div className='w-11/12  py-1 leading-3 flex items-center text-slate-700 '>
                                                        <span className="text-sm font-bold truncate">
                                                            {element.matchName !== null && element.matchName !== undefined ? element.matchName : element.sportName}
                                                            <span className='text-xs font-bold text-gray-500 flex items-center gap-1'>{element.matchDate}</span>
                                                        </span>
                                                    </div>
                                                    <div className='flex items-center justify-center gap-x-2'>
                                                        <span className="relative flex h-2 w-2">
                                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                                            <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='w-1/3 grid grid-cols-6 space-x-0.5'>
                                                    <span className='lg:col-span-1 col-span-3'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"1324.20"} color={"bg-[#a5d9fe]"} blinkColor={'bg-[#CDEBEB]'} />
                                                    </span>
                                                    <span className='lg:col-span-1 col-span-3'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"37.69"} color={"bg-[#f8d0ce]"} blinkColor={'bg-white'} />
                                                    </span>
                                                    <span className='lg:col-span-1 col-span-2 lg:block hidden'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={'747.51'} color={"bg-[#a5d9fe]"} />
                                                    </span>
                                                    <span className='lg:block hidden lg:col-span-1 col-span-2'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={'6.2'} color={"bg-[#f8d0ce]"} />
                                                    </span>
                                                    <span className='lg:col-span-1 col-span-2 lg:block hidden'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"11.62"} color={"bg-[#a5d9fe]"} blinkColor={'bg-[#CDEBEB]'} />
                                                    </span>
                                                    <span className='lg:block hidden lg:col-span-1 col-span-2'>
                                                        <Blinking price={Math.floor(Math.random() * 100)} size={"1128.79"} color={"bg-[#f8d0ce]"} blinkColor={'bg-white'} />
                                                    </span>
                                                </div>
                                            </div>
                                            {index < inplayMatch.length - 1 && <hr />}
                                        </React.Fragment>
                                    ))}
                            </div>
                        )}
                    </div>

                </div>

            </div>

        </section>
    )
}

export default Inplay;