export const userConstent = {
    USER_LOGIN_REQUEST : 'USER_LOGIN_REQUEST',
    USER_LOGIN_SUCCESS : 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILEAR : 'USER_LOGIN_FAILEAR',

    SPORT_MATCHLIST_REQUEST : 'SPORT_MATCHLIST_REQUEST',
    SPORT_MATCHLIST_SUCCESS : 'SPORT_MATCHLIST_SUCCESS',
    SPORT_MATCHLIST_FAILEAR : 'SPORT_MATCHLIST_FAILEAR',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

    USER_BALANCE_REQUEST: 'USER_BALANCE_REQUEST',
    USER_BALANCE_SUCCESS: 'USER_BALANCE_SUCCESS',
    USER_BALANCE_FAILURE: 'USER_BALANCE_FAILURE',

    SAVE_BET_REQUEST: 'SAVE_BET_REQUEST',
    SAVE_BET_SUCCESS: 'SAVE_BET_SUCCESS',
    SAVE_BET_FAILURE: 'SAVE_BET_FAILURE',

    SAVE_BET_FANCY_REQUEST: 'SAVE_BET_FANCY_REQUEST',
    SAVE_BET_FANCY_SUCCESS: 'SAVE_BET_FANCY_SUCCESS',
    SAVE_BET_FANCY_FAILURE: 'SAVE_BET_FANCY_FAILURE',

    DOMAIN_SETTING_BY_DOMAIN_NAME_REQUEST: 'DOMAIN_SETTING_BY_DOMAIN_NAME_REQUEST',
    DOMAIN_SETTING_BY_DOMAIN_NAME_SUCCESS: 'DOMAIN_SETTING_BY_DOMAIN_NAME_SUCCESS',
    DOMAIN_SETTING_BY_DOMAIN_NAME_FAILURE: 'DOMAIN_SETTING_BY_DOMAIN_NAME_FAILURE',

    USER_ACCOUNT_DETAILS_REQUEST: 'USER_ACCOUNT_DETAILS_REQUEST',
    USER_ACCOUNT_DETAILS_SUCCESS: 'USER_ACCOUNT_DETAILS_SUCCESS',
    USER_ACCOUNT_DETAILS_FAILURE: 'USER_ACCOUNT_DETAILS_FAILURE',

    USER_LEDGER_REQUEST: 'USER_LEDGER_REQUEST',
    USER_LEDGER_SUCCESS: 'USER_LEDGER_SUCCESS',
    USER_LEDGER_FAILURE: 'USER_LEDGER_FAILURE',

    MATKA_LIST_REQUEST: 'MATKA_LIST_REQUEST',
    MATKA_LIST_SUCCESS: 'MATKA_LIST_SUCCESS',
    MATKA_LIST_FAILURE: 'MATKA_LIST_FAILURE',


    Downline_List_REQUEST: 'Downline_List_REQUEST',
    Downline_List_SUCCESS: 'Downline_List_SUCCESS',
    Downline_List_FAILURE: 'Downline_List_FAILURE',



    LOGOUT: 'USERS_LOGOUT',
}

