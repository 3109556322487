import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../../../redux/action/userAction";
import { Link } from "react-router-dom";
import moment from "moment";
import { Col, Row } from "antd";


const MatkaList = () => {
    const [matkaList, setMatkaList] = useState([]);
    const dispatch = useDispatch()
    const { matkaListData, loading } = useSelector((state) => state.authentication);

    useEffect(() => {
        dispatch(userAction.matkaList())
    }, [dispatch])

    useEffect(() => {
        if (matkaListData) {
            const sortedSessions = matkaListData.sort((a, b) => a.priority - b.priority);
            const filteredData = sortedSessions.map((item, index) => ({
                key: `${item._id}-${index}`,
                isDeclare: item.isDeclare,
                isDeleted: item.isDeleted,
                _id: item._id,
                name: item.name,
                shortName: item.shortName,
                sportsId: item.sportsId,
                matkaEventId: item.matkaEventId,
                maxStake: item.maxStake,
                minStake: item.minStake,
                betStatus: item.betStatus,
                matkaStatus: item.matkaStatus,
                openTime: item.openTime,
                closeTime: item.closeTime,
                resultTime: item.resultTime,
                priority: item.priority,
                createdAt: item.createdAt,
            }));
            setMatkaList(filteredData);
        }
    }, [matkaListData]);

    return (
        <section className="text-white">
                  <div  className="py-2 flex justify-end items-center px-2">
                    <div >
                        <Link to='/app/matka-bet-list'>
                            <div style={{ color: "#26122D" }} className="bg-orange-500 py-2 text-md px-2 rounded uppercase font-bold flex text-center">
                                Matka Bet List
                            </div>
                        </Link>
                        </div>
                    </div>
                    <div>
                        {matkaList.map((item) => (
                            <div key={item.key} className="px-2">
                                <Link to={`/app/matka-type/${item.name}/${item.matkaEventId}`}>
                                    <div className="bg-orange-500 flex justify-center py-1 text-white uppercase">
                                        {item.name} - {moment().format('DD-MM-YYYY')}
                                    </div>
                                    <div className="flex justify-center bg-white text-black py-1">
                                        <span className="text-blue font-bold">
                                            {moment().format('DD-MM-YYYY')} {moment(item.openTime, 'HH:mm').format('hh:mm A')}
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
        </section>
    )
}

export default MatkaList;