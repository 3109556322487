import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { userAction } from "../../redux/action/userAction";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import logo from "../../Assest/images/business-logo.png";
import { FaUser } from "react-icons/fa";
import { IoMdLock } from "react-icons/io";
import bgimg from "../../Assest/images/register/drop.png"

const Login = ({ loggingIn, user, otpSent, users }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fieldslogin, setFieldsLogin] = useState({});
  const [errorslogin, setErrorsLogin] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("password");
  const [inputFocused, setInputFocused] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword); // password Hide and Show Icons
  };
  const handleInputFocus = () => {
    // Input Focus Hover
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsLogin((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
    setErrorsLogin((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };


  const loginSubmitDemo = async (e) => {


    setLoading(true);
    try {
      const data = {
        username: "demo",
        password: "123456",
        isClient: true,
        host: window.location.host,
      };

      const response = await dispatch(
        userAction.login(data, { loggingIn, user, otpSent, users })
      );

      if (response) {
        navigate("/app/dashboard");
      }
    } catch (error) {
      console.error("Login error:", error);
    } finally {
      setLoading(false);
    }

  };


  const loginSubmit = async (e) => {
    e.preventDefault();
    if (handleValidationLogin()) {
      setLoading(true); // Set loading state to true
      try {
        const data = {
          username: fieldslogin.username,
          password: fieldslogin.password,
          isClient: true,
          host: window.location.host,
        };

        const response = await dispatch(
          userAction.login(data, { loggingIn, user, otpSent, users })
        );

        if (
          response &&
          response.userinfo &&
          response.userinfo.data &&
          response.userinfo.data.isPasswordChanged === false
        ) {
          navigate("/app/dashboard");
          // navigate("/app/reset-password");
        } else {
          navigate("/app/dashboard");
        }
      } catch (error) {
        console.error("Login error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleValidationLogin = () => {
    let formIsValid = true;
    const newErrorsLogin = {};

    if (!fieldslogin["username"]) {
      formIsValid = false;
      newErrorsLogin["username"] = "User Name Cannot Be Blank.";
    }

    if (!fieldslogin["password"]) {
      formIsValid = false;
      newErrorsLogin["password"] = "Password Cannot Be Blank.";
    }

    setErrorsLogin(newErrorsLogin);
    return formIsValid;
  };
  const handalClickNavigate = (url) => {
    navigate(url)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      loginSubmit(e);
    }
  };


  return (
    <section>
      <div className="h-full w-full bg-black ">
        <div className="bg-cover h-screen flex items-center" style={{ backgroundImage: `url(${bgimg})` }}>
          <div className="w-full flex justify-center items-center sm:px-0 px-4 ">
            <div className=" bg-black bg-opacity-80 border-2 border-orange-500 py-4 w-[23rem] ">
              <div className="px-3 py-2">
                <div className="flex items-center justify-center sm:py-3 ">
                  <img
                    src={logo}
                    alt="logo"
                    className="sm:h-16 sm:w-44 h-12 w-36"
                  ></img>
                </div>
                <div className="flex  w-full gap-3 sm:py-4 py-2 px-1 md:mt-7 mt-5">
                  <div className="py-2.5 text-white">
                    <FaUser size={20} />
                  </div>
                  <div className="w-full pb-2">
                    <input
                      type="text"
                      name="username"
                      id="username"
                      value={fieldslogin.username}
                      className="py-2  text-sm text-white bg-transparent placeholder-gray-500 focus:outline-none border-b-2 border-gray-400 focus:border-[#f36c21] w-full"
                      placeholder="USER NAME"
                      onChange={inputChange}
                      onKeyPress={handleKeyPress}
                      required
                    />
                  </div>
                </div>

                <div className="flex items-center gap-3 py-2 px-1 ">
                  <div className="py-2.5 text-white">
                    <IoMdLock size={20} />
                  </div>

                  <div className="items-start right-0 pb-2 w-full relative">
                    <input
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      type={showPassword ? "text" : password}
                      name="password"
                      id="password"
                      value={fieldslogin.password}
                      className="py-2.5  text-sm text-white bg-transparent placeholder-gray-500 focus:outline-none border-b-2 border-gray-400 focus:border-[#f36c21] w-full "
                      placeholder="PASSWORD"
                      onChange={inputChange}
                      onKeyPress={handleKeyPress}
                    />

                    <button
                      onClick={toggleShowPassword}
                      className="absolute inset-y-0 right-0 flex items-center px-2 text-white"
                    >
                      {showPassword ? (
                        <BsFillEyeSlashFill
                          size={20}
                          className={inputFocused ? "text-[#f36c21]" : ""}
                        />
                      ) : (
                        <BsEyeFill
                          size={20}
                          className={inputFocused ? "text-[#f36c21]" : ""}
                        />
                      )}
                    </button>
                  </div>
                </div>
                <div className="py-3 px-2 flex items-center">
                  <input
                    type="checkbox"
                    value=""
                    class="p-1 sm:w-4 w-3 h-3 sm:h-4 text-[rgb(243,107,33)] bg-gray-100 border-[rgb(243,107,33)] rounded focus:ring-[rgb(243,107,33)] dark:focus:ring-[rgb(243,107,33)] dark:ring-offset-[rgb(243,107,33)]  "
                  />
                  <label
                    for="default-checkbox"
                    class="ms-2 text-xs font-medium text-white dark:text-white"
                  >
                    Remember Me
                  </label>
                </div>
                <div
                  onClick={loginSubmit}
                  className={`w-full sm:py-0 py-2  ${(fieldslogin.username || fieldslogin.password) ? "bg-[rgb(243,107,33)] text-white" : "bg-gray-500 text-white"} flex justify-center item-center rounded-lg`}>
                  <button
                    type="button"
                    disabled={loading}
                    className={`md:font-bold font-normaltext-white md:px-5 px-2 md:py-2 py-0.5  md:text-base text-sm`}
                  >
                    {loading ?
                      <div class="w-6 h-6 border-8 border-dashed rounded-full animate-spin border-white"></div>
                      : "LOGIN"}
                  </button>
                </div>

                <div
                  onClick={() => loginSubmitDemo()}
                  className={`w-full sm:py-0 py-2 mt-2 bg-orange-200 flex justify-center item-center text-md font-bold rounded-lg`}>
                  Login With Demo

                </div>

                <div className=" flex justify-center items-center gap-2 py-3 px-2">
                  <span className="text-white text-xs font-semibold">
                    FORGOT PASSWORD ?
                  </span>
                  <span className="text-white text-xs font-semibold">|</span>
                  <span onClick={() => handalClickNavigate('/registration')}>
                    <span className="text-xs font-semibold text-blue-600 underline cursor-pointer">
                      REGISTER
                    </span>
                  </span>
                </div>
              </div>
              {/* 
              <div className="opacity-100 flex justify-center item-center">
                <span className="text-orange-700 text-lg font-semibold cursor-pointer uppercase" onClick={() => loginSubmitDemo()}>
                  Continue as Guest
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
