import Inplay from "../../../../Assest/images/SubHeaderImage/Inplay.webp"
import Cricket from "../../../../Assest/images/SubHeaderImage/Cricket.webp"
import Soccer from "../../../../Assest/images/SubHeaderImage/Soccer.webp"
import Tennis from "../../../../Assest/images/SubHeaderImage/Tennis.webp"
import PremiumNotebook from "../../../../Assest/images/SubHeaderImage/Premium-Notebook.webp";
import LiveCasino from "../../../../Assest/images/SubHeaderImage/Live-Casino.webp";
import Election from "../../../../Assest/images/SubHeaderImage/Election.webp";
import LiveCard from "../../../../Assest/images/SubHeaderImage/Live-Card.webp";
import SlotGame from "../../../../Assest/images/SubHeaderImage/Slot-game.webp";
import More from "../../../../Assest/images/SubHeaderImage/More.png";
import SportsBook from "../../../../Assest/images/SubHeaderImage/Sportsbook.webp";
import aVIATOR from "../../../../Assest/images/aVIATOR.png"
import rgCasino from '../../../../Assest/images/RG ICON.png'
import Qtech from '../../../../Assest/images/Qtech.png'
import Matka from '../../../../Assest/images/matka.png'

const domainSetting = JSON.parse(localStorage.getItem('clientdomainSetting'));
let sportSetting = domainSetting?.sportsSetting


export const SubHeaderMenu = [
  {
    icon: Inplay,
    text: 'Home',
    link: '/app/dashboard',
    page: "dashboard",
    sportId: "",
    isVisible: true
  },
  {
    icon: Cricket,
    text: 'Cricket',
    link: '/app/sportsbook/page/',
    sportId: 4,
    isVisible: sportSetting?.isCricket ? sportSetting?.isCricket : true,
  },
  {
    icon: Soccer,
    text: 'Soccer',
    link: '/app/sportsbook/page/',
    sportId: 1,
    isVisible: sportSetting?.isSoccer ? sportSetting?.isSoccer : true,
  },
  {
    icon: Tennis,
    text: 'Tennis',
    link: '/app/sportsbook/page/',
    sportId: 2,
    isVisible: sportSetting?.isTennis ? sportSetting?.isTennis : true,
    
  },

  // {
  //   icon: PremiumNotebook,
  //   text: 'SportsBook',
  //   link: '#'
  // },
  {
    icon: LiveCasino,
    text: 'Int Casino',
    link: '/app/livecasino/page/',
    sportId: 111,
    isVisible: sportSetting?.isIntCasino ? sportSetting?.isIntCasino : true
  },

  {
    icon: LiveCard,
    text: 'Diamond',
    link: '/app/casino',
    sportId: '',
    isVisible: sportSetting?.isDiamondCasino ? sportSetting?.isDiamondCasino : true
  },
  {
    icon: aVIATOR,
    text: 'Aviator',
    link: '/app/iframe-casino/',
    sportId: 201206,
    isVisible: sportSetting?.isIntCasino ? sportSetting?.isIntCasino : true
  },

  {
    icon: Election,
    text: 'election',
    link: '/app/sportsbook/page/',
    sportId: 999,
    isVisible: sportSetting?.isPolitics ? sportSetting?.isPolitics : true
  },
  // {
  //   icon: aVIATOR,
  //   text: 'QTech',
  //   link: '/app/iframe-qtech-casino/',
  //   sportId: "SPB-aviator"
  // },


  {
    icon: LiveCard,
    text: 'Live Card',
    link: '/app/livecard/page/',
    sportId: 7,
    isVisible: true
  },
  {
    icon: SlotGame,
    text: 'Slot Games',
    link: '/app/slotgames/page/',
    sportId: 8,
    isVisible: true
    
  },

  {
    icon: Matka,
    text: 'Matka',
    link: '/app/matka-list',
    sportId: "",
    isVisible: sportSetting?.isMatka ? sportSetting?.isMatka : true
  },



];


export const SubHeaderMobile = [
  {
    icon: Inplay,
    text: 'Home',
    link: '/app/dashboard',
    sportId: "",
    isVisible: true

  },
  {
    icon: Cricket,
    text: 'Cricket',
    link: '/app/sportsbook/page/',
    sportId: 4,
    isVisible: sportSetting?.isCricket ? sportSetting?.isCricket : true,
  },
  {
    icon: Soccer,
    text: 'Football',
    link: "/app/sportsbook/page/",
    sportId: 1,
    isVisible: sportSetting?.isSoccer ? sportSetting?.isSoccer : true,
  },
  {
    icon: Tennis,
    text: 'Tennis',
    link: "/app/sportsbook/page/",
    sportId: 2,
    isVisible: sportSetting?.isTennis ? sportSetting?.isTennis : true,
  },


  {
    icon: Election,
    text: 'election',
    link: '/app/sportsbook/page/',
    sportId: 999,
    isVisible: sportSetting?.isPolitics ? sportSetting?.isPolitics : true,
  },

]


export const SubHeaderMobile2 = [

  {
    icon: LiveCasino,
    text: 'Casino',
    link: '/app/livecasino/page/',
    sportId: 111,
    isVisible: sportSetting?.isIntCasino ? sportSetting?.isIntCasino : true
  },
  {
    icon: LiveCard,
    text: 'Diamond',
    link: '/app/casino',
    sportId: "",
    isVisible: sportSetting?.isDiamondCasino ? sportSetting?.isDiamondCasino : true
  },



  {
    icon: aVIATOR,
    text: 'Aviator',
    link: '/app/iframe-casino/',
    sportId: 201206,
    isVisible: sportSetting?.isIntCasino ? sportSetting?.isIntCasino : true,
  },
  {
    icon: Qtech,
    text: 'Qtech',
    link: '/app/iframe-qtech-casino/',
    sportId: "SPB-aviator",
    isVisible: sportSetting?.isQteck ? sportSetting?.isQteck : true
  },
  {
    icon: Matka,
    text: 'Matka',
    link: '/app/matka-list',
    sportId: "",
    isVisible: sportSetting?.isMatka ? sportSetting?.isMatka : true
  },

]