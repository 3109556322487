import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { httpPost } from "../../../../middelware/Http";
import { BetPlaceMatka } from "../../../../component/Auth/BetPlace/BetPlaceMatka";
import { userAction } from "../../../../redux/action/userAction";
import BetListTableMatka from "../../../../component/Auth/MatkaBetList/BatListTableMatka";
import { Col, message, Row, Select, Typography } from "antd";


const DoublePattiMatka = () => {
  const [selectedGame, setSelectedGame] = useState('');
  const [sattaList, setSattaList] = useState({});
  const [domainSettingByDomainName, setDomainSettingByDomainName] = useState(null);
  // const [message, setMessage] = useState("");
  const [betChipsData, setBetChipsData] = useState([]);
  const [error, setError] = useState("");
  const [betSlipData, setBetSlipData] = useState({});
  const [backBetModal, setBackBetModal] = useState(false);
  const [backBetModalMobile, setBackBetModalMobile] = useState(false);
  const [time, setTime] = useState(7);
  const [isFetch, setIsFetch] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorType, setErrorType] = useState(0);
  const [resMessage, setResMessage] = useState("");
  const [totalProfitLoss, setTotalProfitLoss] = useState(0);
  const [betList, setBetList] = useState([]);
  const [LoadingBet, setLoadingBet] = useState(false);
  const [betresponse, setBetresponse] = useState(null);
  const section1Ref = useRef();
  const scrollTimeout = useRef(null);
  const inputRef = useRef();
  const [activeTab, setActiveTab] = useState(0)
  const [showBetPlaceModal, setShowBetPlaceModal] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState("");
  const [betShow, setBetShow] = useState(true);

  const dispatch = useDispatch();
  // const { loading, matkaBetSucess, matkaBetsPlace } = useSelector((state) => state.UserReducer);

  const params = useParams()
  useEffect(() => {
    const marketEvent = {
      "matkaEventId": params.marketEvent
    };

    getMatkaListByEventID()
    betListFunction(marketEvent);
    betChipData();
    checkWindowWidth();
  }, [params.marketEvent]);



  // useEffect(() => {
  //   if (matkaBetSucess === true) {
  //     betListFunction();
  //     dispatch(userBalance());
  //   }
  // }, [matkaBetSucess])

  const checkWindowWidth = () => {
    const isMobile = window.matchMedia('(max-width: 100vw)').matches;
    if (isMobile && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  };

  const betChipData = async () => {
    try {
      let betChipsDatas = JSON.parse(localStorage.getItem('betChipsData'));
      const myArray = betChipsDatas && Object.keys(betChipsDatas).length > 0 ? Object.values(betChipsDatas) : Object.values(betChipsData);
      setBetChipsData(myArray);
    } catch (error) {
      console.error('Error parsing JSON from localStorage: betChipsDatas', error);
      setBetChipsData(betChipsData);
    }
  };




  const openBets = () => {
    setBackBetModal(false);
  };


  const handleBackOpen = (data, sectionRef) => {

    if (!selectedMarket) {
      return message.error("Please Select Market Type")
    }
    if (ismatkaList.betStatus === false) {
      return message.error("Your Bet is Closed Please Connect With Upline")

    }

    if (data) {
      setBackBetModal(true);
      setBetSlipData({ ...data, stake: "0" });
      setTimeout(() => {
        if (sectionRef && sectionRef.current) {
          sectionRef.current.scrollIntoView({ behavior: 'smooth' });
          if (window.innerWidth <= 768 && inputRef.current && sectionRef && sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => {
              inputRef.current.focus();
            }, 0);
          }
        }
      }, 0);
    }
  };



  const updateStake = (event) => {
    try {
      let { value } = event.target;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      setBetSlipData(prevBetSlipData => ({
        ...prevBetSlipData,
        stake: value,
      }));
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };

  const updateStackOnclick = (num) => {
    setBetSlipData(prevBetSlipData => ({
      ...prevBetSlipData,
      stake: num,
    }));
  };



  const betListFunction = async (eventId) => {
    let betReq = {
      "matkaEventId": eventId?.matkaEventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      gameType: 'doublePatti'
    };

    let getCasinoDetails = await httpPost(`matka/matkaBetList`, betReq);
    let totalProfitLoss = 0;
    let betList = getCasinoDetails?.data;
    if (betList && betList.length > 0) {
      betList.map((bet) => {
        totalProfitLoss += Number(bet.profitLoss);
      });
    }
    if (getCasinoDetails) {
      setBetList(betList);
      setTotalProfitLoss(totalProfitLoss);
    }
  };

  const placeBet = async () => {
    try {
      let betObject = {
        "matkaEventId": betSlipData?.data?.matkaEventId,
        "gameType": betSlipData?.gameType,
        "betType": selectedMarket,
        "betNumber": betSlipData?.betNumber,
        "amount": Number(betSlipData.stake)

      };

      setLoadingBet(true);
      // if (ismatkaList?.jodiBet) {
      //   dispatch(matkaPlaceBet(betObject))
      // } 
      let saveBetOdds = await httpPost("matka/matkaPlaceBet", betObject);
      setLoadingBet(false);
      setBetShow(true)
      if (!saveBetOdds.error) {
        message.success("Success! Your bet has been placed");
        betListFunction(matkaEventId);
        dispatch(userAction.userBalance());
        setBackBetModal(false);
      } else {
        message.error("Sorry, your bet couldn't be placed.");
      }
    } catch (error) {
      console.error('Error placing bet:', error);
    }
  };

  const handleClose = () => {
    setBackBetModal(false);
    setBackBetModalMobile(false);
  };

  const handleBetStatus = () => {
    message.error('Your Bet is Closed Please Connect With Upline');
  };



  const cardData = Array.from({ length: 10 }, (_, index) => index + 1);







  const handalChnage = (value) => {
    setSelectedMarket(value);
    console.log(value, "Selected Market");

    // If a market is selected, open the modal
    if (value === "OPEN" || value === "CLOSE") {
      setShowBetPlaceModal(true);
    }
  };




  const getMatkaListByEventID = async () => {

    let betReq = {
      "matkaEventId": matkaEventId,

    };

    let getMatkaDetails = await httpPost(`matka/getMatkaByMatkaEventId`, betReq);
    if (getMatkaDetails?.data) {
      setIsmatkaList(getMatkaDetails.data ? getMatkaDetails?.data : {});
    }

  }

  let { matkaEventId } = useParams()
  const { Option } = Select
  const [ismatkaList, setIsmatkaList] = useState({});



  const showOpenBet = ismatkaList?.openBet;
  const showCloseBet = ismatkaList?.closeBet;

  const imageData = [
    [200, 110, 228, 255, 336, 499, 660, 688, 778],
    [300, 166, 229, 337, 355, 445, 599, 779, 788],
    [400, 112, 220, 266, 338, 446, 455, 699, 770],
    [500, 113, 122, 177, 339, 366, 447, 799, 889],
    [600, 114, 277, 330, 448, 466, 556, 880, 899],
    [700, 115, 133, 188, 223, 377, 449, 557, 566],
    [800, 116, 224, 233, 288, 440, 477, 558, 990],
    [900, 117, 144, 199, 225, 388, 559, 577, 667],
    [550, 668, 244, 299, 226, 488, 677, 118, 334],
    [100, 119, 155, 227, 335, 344, 399, 588, 669],
  ];




  return (
    // <>

    //   <Typography style={{ color: "#26122D" }} className=" gx-fs-xxl gx-font-weight-heavy gx-text-center gx-mb-1 gx-text-uppercase">{ismatkaList?.name ? ismatkaList?.name : '-'}-DOUBLE-PATTI</Typography>
    //   <Row justify={"center"}>

    //     <Col className="gx-bg-flex gx-justify-content-center gx-mt-2" span={18}>

    //       <Select size="large" placeholder="Market Type" onChange={handalChnage} style={{ width: 300 }}>
    //         {showOpenBet && <Option value="OPEN">Open Market</Option>}
    //         {showCloseBet && <Option value="CLOSE">Close Market</Option>}
    //       </Select>

    //     </Col>
    //   </Row>


    //   <Row justify={"space-between"} className="gx-mt-2">

    //     <Col className="gx-bg-flex gx-justify-content-start " xs={12} sm={8} md={8} lg={4}>
    //       <div className="gx-fs-md gx-font-weight-semi-bold ">Open Time:
    //         <span className="gx-text-blue gx-px-1">{ismatkaList?.openTime ? ismatkaList?.openTime : '-'}</span>
    //       </div>

    //     </Col>
    //     <Col className="gx-bg-flex gx-justify-content-end" xs={12} sm={8} md={4} lg={4}>
    //       <div className="gx-fs-md gx-font-weight-semi-bold">Close Time:
    //         <span className="gx-text-red gx-px-1">
    //           {ismatkaList?.closeTime ? ismatkaList?.closeTime : '-'}

    //         </span>
    //       </div>
    //     </Col>
    //   </Row>
    //   {/* <Row justify={"start"} className="">

    //     <Col className="gx-bg-flex gx-justify-content-start gx-py-1" xs={12} sm={8} md={8} lg={4}>
    //       <div className="gx-fs-md gx-font-weight-semi-bold">Close Time: {ismatkaList?.closeTime ? ismatkaList?.closeTime : '-'}</div>
    //     </Col>
    //   </Row> */}
    //   {/* 
    //   <Row justify={"start"} className="">

    //     <Col className="gx-bg-flex gx-justify-content-start gx-py-1" xs={12} sm={8} md={8} lg={4}>
    //       <div className="gx-fs-md gx-font-weight-semi-bold">Double Patti Matka : {ismatkaList?.betStatus ? 'Open' : 'Close'}</div>
    //     </Col>
    //   </Row> */}




    //   <Row justify={"space-between"} className="gx-mt-2">
    //     <Col className="" xs={12} sm={8} md={4} lg={4}>
    //       <span className="gx-fs-md gx-font-weight-heavy">Min: {ismatkaList?.minStake} </span>
    //     </Col>

    //     <Col className="gx-bg-flex gx-justify-content-end" xs={12} sm={8} md={4} lg={4}>
    //       <span className="gx-fs-md gx-font-weight-heavy">Max: {ismatkaList?.maxStake}</span>
    //     </Col>
    //   </Row>

    //   <Row justify={"start"}>

    //     {imageData.map((element, index) => (
    //       <>
    //         <div style={{ color: "#26122D" }} className="gx-px-2 gx-w-100 gx-my-2 gx-mx-2  gx-py-1 gx-bg-light-grey-7 gx-fs-xl gx-font-weight-heavy gx-bg-flex gx-justify-content-center">{index} </div>
    //         {element.map((card, index) => (
    //           <Col key={card} xs={12} sm={8} md={4} lg={4} >
    //             {/* {ismatkaList?.betStatus === true && showBetPlaceModal === true ? */}
    //             <div className="gx-bg-grey gx-font-weight-semi-bold gx-fs-md gx-py-2 gx-mt-2" onClick={() => handleBackOpen({
    //               data: ismatkaList, gameType: "DOUBLE_PATTI", betType: "OPEN",
    //               betNumber: card?.toString().padStart(1, "0")
    //             },)}>
    //               <span className="gx-px-1 gx-py-2 gx-bg-flex gx-justify-content-center gx-text-white">
    //                 {card.toString().padStart(2, "0")}
    //               </span>
    //             </div>
    //             {/* :
    //         <div className="gx-bg-grey gx-font-weight-semi-bold gx-fs-md gx-py-2 gx-mt-2"
    //           onClick={() => handleBetStatus()}
    //        >
    //         <span className="gx-px-1 gx-py-2 gx-bg-flex gx-justify-content-center gx-text-white">
    //             {card.toString().padStart(2, "0")}
    //           </span>
    //         </div>} */}

    //           </Col>
    //         ))}
    //       </>

    //     ))}
    //   </Row>

    //   {backBetModal === true ?
    //     <PlaceBetMobileBmx
    //       betSlipData={betSlipData}
    //       time={time}
    //       // count={count}
    //       betChipsData={betChipsData}
    //       section1Ref={section1Ref}
    //       inputRef={inputRef}
    //       updateStake={updateStake}
    //       placeBet={placeBet}
    //       updateStackOnclick={updateStackOnclick}
    //       handleClose={handleClose}
    //       LoadingBet={LoadingBet}
    //     />
    //     : null}

    //   {backBetModal === true ?
    //     <PlaceBetMobileBmxBet
    //       betSlipData={betSlipData}
    //       time={time}
    //       // count={count}
    //       betChipsData={betChipsData}
    //       section1Ref={section1Ref}
    //       inputRef={inputRef}
    //       updateStake={updateStake}
    //       placeBet={placeBet}
    //       updateStackOnclick={updateStackOnclick}
    //       handleClose={handleClose}
    //       LoadingBet={LoadingBet}
    //     />
    //     : null}

    //   <div className="gx-my-2">
    //     <BetListTableMatka betList={betList} />
    //   </div>
    // </>

    <section className="px-2 text-white">

      <Typography style={{ color: "#26122D" }} className=" text-lg rounded font-bold text-center uppercase py-2 bg-orange-500 mb-2">{ismatkaList?.name ? ismatkaList?.name : ''}-DOUBLE-PATTI</Typography>
      <div className="lg:flex gap-x-2">


        <div className="lg:w-3/4 full">
          <Row justify={"center"}>

            <Col className="flex justify-center mt-2" span={18}>

              <Select size="large" placeholder="Market Type" onChange={handalChnage} style={{ width: 300 }}>
                {showOpenBet && <Option value="OPEN">Open Market</Option>}
                {showCloseBet && <Option value="CLOSE">Close Market</Option>}
              </Select>

            </Col>
          </Row>


          <Row justify={"space-between"} className="mt-2 text-white">

            <Col className="flex justify-start " xs={12} sm={8} md={8} lg={4}>
              <div className="text-md gx-bold ">Open Time:
                <span className="text-blue-500 px-1">{ismatkaList?.openTime ? ismatkaList?.openTime : '-'}</span>
              </div>

            </Col>
            <Col className="flex justify-end" xs={12} sm={8} md={4} lg={4}>
              <div className="text-md font-bold">Close Time:
                <span className="text-red-500 px-1">
                  {ismatkaList?.closeTime ? ismatkaList?.closeTime : '-'}

                </span>
              </div>
            </Col>
          </Row>

          <Row justify={"space-between"} className="mt-2 text-white">
            <Col className="" xs={8} sm={8} md={4} lg={4}>
              <span className="gx-bold">Min: {ismatkaList?.minStake} </span>
            </Col>

            <Col className="flex justify-end" xs={8} sm={8} md={4} lg={4}>
              <span className="font-bold">Max: {ismatkaList?.maxStake}</span>
            </Col>
          </Row>

          <Row justify={"start"}>
            {imageData.map((element, index) => (
              <>
                <div style={{ color: "#26122D" }} className="px-2 w-full bg-orange-500 my-2  py-1 bg-light-grey-7 text-lg font-bold flex justify-center">{index} </div>
                <div className="grid md:grid-cols-5 grid-cols-3 gap-2 w-full mt-2">
                  {element.map((card, index) => (
                    <div key={card} className="flex justify-center bg-[#A5D9FE]" >
                      {ismatkaList?.betStatus === true && showBetPlaceModal === true ?
                        <div className=" font-bold py-2 mt-2" onClick={() => handleBackOpen({
                          data: ismatkaList, gameType: "DOUBLE_PATTI", betType: "OPEN",
                          betNumber: card?.toString().padStart(1, "0")
                        },)}>
                          <span className="px-1 py-2 text-black">
                            {card.toString().padStart(2, "0")}
                          </span>
                        </div>
                        :
                        <div className=" font-bold py-2 mt-2"
                          onClick={() => handleBetStatus()}
                        >
                          <span className="px-1 py-2 text-black">
                            {card.toString().padStart(2, "0")}
                          </span>
                        </div>}

                    </div>
                  ))}
                </div>

              </>

            ))}


          </Row>

          {betList && betList.length > 0 &&
            <div className="my-2">
              <BetListTableMatka betList={betList} />
            </div>}
        </div>

        <div className="lg:w-1/4 lg:block hidden">
          {backBetModal === true ?
            <div className="">

              <BetPlaceMatka
                openBets={openBets}
                // closeRow={closeRow}
                matchName={`${ismatkaList?.name}-JODI`}
                betSlipData={betSlipData}
                placeBet={placeBet}
                count={betSlipData?.betNumber}
                betLoading={LoadingBet}
                section1Ref={section1Ref}
                inputRef={inputRef}
                handleClose={handleClose}
              // increaseCount={increaseCount}
              // decreaseCount={decreaseCount}
              />
            </div> : null}
        </div>


        <div className="lg:w-1/4 lg:hidden block w-full z-50 top-4 left-0 fixed justify-center">
          {backBetModal === true ?
            <div className="">

              <BetPlaceMatka
                openBets={openBets}
                // closeRow={closeRow}
                matchName={`${ismatkaList?.name}-JODI`}
                betSlipData={betSlipData}
                placeBet={placeBet}
                count={betSlipData?.betNumber}
                betLoading={LoadingBet}
                section1Ref={section1Ref}
                inputRef={inputRef}
                handleClose={handleClose}
              // increaseCount={increaseCount}
              // decreaseCount={decreaseCount}
              />
            </div> : null}
        </div>
      </div>
    </section>

  )
};


export default DoublePattiMatka;

