import React, { useEffect, useState } from "react";
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa";
import { httpPost } from "../../../middelware/Http";

const ReferAndEarn = () => {

const [userDeatils, setUserDeatils] = useState({})

    useEffect(() => {
        
    }, [])

    let clientUserData = JSON.parse(localStorage.getItem('clientUser'));

    const hostname = window.location.origin;
    const referralCode = clientUserData?.data?.referralCode; 
    const clipboardText = `${hostname}/registration/${clientUserData?.data?.referralCode}`;



    return (
        <>
            <NavigatePathComponent showPathName={"Referral And Earn"} />
            <section className="w-full bg-black max-w-7xl mx-auto h-fit flex justify-center py-4 md:px-0 px-1">
                <div className="relative mx-auto text-center w-full bg-trasparent afterFooter shadow-xl ring-1 ring-gray-900/5 sm:rounded-md">
                    <div className="bg-orange-500">
                        <div className="w-full py-2 text-2xl font-bold text-white">
                            REFER AND EARN!
                            <br />
                            <span className="text-sm font-normal">
                                Keep your friends close and referral friends closer! Invite your
                                friends and family to sign up using your personal referral code
                                (mentioned below) and get of each and every one of their
                                deposits made thereafter!
                            </span>
                        </div>
                    </div>

                    <div className="bg-[#212121] mt-3 rounded-md">
                        <div className="w-full py-2 text-lg font-bold text-white">
                            Don’t wait, start making HUGE PROFITS NOW! Use my special code{" "}
                            {clientUserData?.data?.referralCode} to Sign-Up & Get a  Referral Bonus!
                        </div>
                       <div className="w-full py-2 text-lg font-bold text-white">
                        {hostname}/{clientUserData?.data?.referralCode}
                        </div>
                    </div>
                    <div className="flex justify-center items-center mt-2">
                        <CopyToClipboard text={clipboardText} >
                            <button className="py-2 px-4 bg-[#F26C20] text-white rounded-md flex space-x-2 transition">
                                Copy <FaCopy size={15} color="white" />
                            </button>
                        </CopyToClipboard>
                    </div>


                  <div className="bg-[#212121] mt-3 rounded-md ">
                        <div className="w-full py-2 text-lg font-bold text-white">{clientUserData?.data?.referralCode}</div>

                    </div>
                    <div className="flex justify-center items-center mt-2">
                        <CopyToClipboard text={referralCode} >
                            <button className="py-2 px-4 bg-[#F26C20] text-white rounded-md space-x-2 flex transition">
                                Copy <FaCopy size={15} color="white" />
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ReferAndEarn;
