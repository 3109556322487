import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { Button, Col, Modal, Row, Select, Form, Typography, message } from "antd";
// import BetListTableMatka from "../SattaMatka/BatListTableMatka";
import { httpPost } from "../../../../middelware/Http";
import { BetPlaceDesktop } from "../../../../component/Auth/BetPlace/BetPlaceDesktop";
import { BetPlaceMatka } from "../../../../component/Auth/BetPlace/BetPlaceMatka";
import { userAction } from "../../../../redux/action/userAction";
import BetListTableMatka from "../../../../component/Auth/MatkaBetList/BatListTableMatka";
import PlaceBetMobileBmxBet from "../../DiamondCasino/Casino/casino_components/PlaceBetMobileBmxBet";
import PlaceBetMobileBmx from "../../DiamondCasino/Casino/casino_components/PlaceBetMobileBmx";
// import PlaceBetMobileBmxBet from "../../../components/casino_components/PlaceBetMobileBmxBet/PlaceBetMobileBmxBet";
// import PlaceBetMobileBmx from "../../../components/casino_components/PlaceBetMobileBmx/PlaceBetMobileBmx";
// import { NotificationManager } from "react-notifications";
// import { matkaPlaceBet, userBalance } from "../../../appRedux/actions/User";



const SingleMatka = (props) => {

  const [betChipsData, setBetChipsData] = useState([]);
  const [betSlipData, setBetSlipData] = useState({});
  const [backBetModal, setBackBetModal] = useState(false);
  const [backBetModalMobile, setBackBetModalMobile] = useState(false);
  const [time, setTime] = useState(7);
  const [totalProfitLoss, setTotalProfitLoss] = useState(0);
  const [betList, setBetList] = useState([]);
  const [LoadingBet, setLoadingBet] = useState(false);
  const section1Ref = useRef();
  const scrollTimeout = useRef(null);
  const inputRef = useRef();
  const [selectedMarket, setSelectedMarket] = useState("");
  const [betShow, setBetShow] = useState(true);

  const [ismatkaList, setIsmatkaList] = useState({});

  const dispatch = useDispatch();
  let { matkaEventId } = useParams()
  console.log(matkaEventId, "matkaEventIdmatkaEventIdmatkaEventId");
  
  const { Option } = Select

  // const { loading, matkaBetSucess, matkaBetsPlace } = useSelector((state) => state.UserReducer);


  useEffect(() => {
    getMatkaListByEventID()
    betListFunction(matkaEventId);
    betChipData();
    checkWindowWidth();
  }, [matkaEventId]);

  // useEffect(() => {
  //   if (matkaBetSucess === true) {
  //     betListFunction();
  //     console.log("Balance");

  //     // dispatch(userBalance());
  //   }
  // }, [matkaBetSucess])

  const checkWindowWidth = () => {
    const isMobile = window.matchMedia('(max-width: 100vw)').matches;
    if (isMobile && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  };



  const betChipData = async () => {
    try {
      let betChipsDatas = JSON.parse(localStorage.getItem('betChipsData'));
      const myArray = betChipsDatas && Object.keys(betChipsDatas).length > 0 ? Object.values(betChipsDatas) : Object.values(betChipsData);
      setBetChipsData(myArray);
    } catch (error) {
      console.error('Error parsing JSON from localStorage: betChipsDatas', error);
      setBetChipsData(betChipsData);
    }
  };


  const handleBackOpen = (data, sectionRef) => {

    if (!selectedMarket) {
      return message.error("Please Select Market Type")
    }
    if (ismatkaList.betStatus === false) {
      return message.error("Your Bet is Closed Please Connect With Upline")

    }

    if (data) {
      setBackBetModal(true);
      setBetSlipData({ ...data, stake: "0" });
      setTimeout(() => {
        if (sectionRef && sectionRef.current) {
          sectionRef.current.scrollIntoView({ behavior: 'smooth' });
          if (window.innerWidth <= 768 && inputRef.current && sectionRef && sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => {
              inputRef.current.focus();
            }, 0);
          }
        }
      }, 0);
    }
  };

  // const handleBackOpen = async (data, sectionRef) => {
  //   if (!selectedMarket) {
  //     return message.error("Please Select Market Type")
  //   }
  //   // if (ismatkaList.betStatus === false) {
  //   //   return NotificationManager.error('Your Bet is Closed Please Connect With Upline', "Error", 1000, () => {
  //   //     alert('callback');
  //   //   });
  //   // }
  //   if (scrollTimeout.current) {
  //     clearInterval(scrollTimeout.current);
  //   }
  //   setBackBetModal(true);
  //   // setBackBetModalMobile(true);

  //   setBetSlipData({ ...data, stake: "0" });
  //   setTime(7);
  //   scrollTimeout.current = setInterval(() => {
  //     setTime(prevTime => {
  //       if (prevTime <= 0) {
  //         clearInterval(scrollTimeout.current);
  //         setBackBetModal(false);
  //       }
  //       return prevTime - 1;
  //     });
  //   }, 1000);
  //   setTimeout(() => {
  //     if (sectionRef && sectionRef.current) {
  //       sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  //       if (window.innerWidth <= 768 && inputRef.current && sectionRef && sectionRef.current) {
  //         sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  //         setTimeout(() => {
  //           inputRef.current.focus();
  //         }, 0);
  //       }
  //     }
  //   }, 0);
  // };


  const placeBet = async () => {
    try {
      let betObject = {
        "matkaEventId": betSlipData?.data?.matkaEventId,
        "gameType": betSlipData?.gameType,
        "betType": selectedMarket,
        "betNumber": betSlipData?.betNumber,
        "amount": Number(betSlipData.stake)
      };

      console.log(betObject, "jkjkkk");
      setLoadingBet(true);
      let saveBetOdds = await httpPost("matka/matkaPlaceBet", betObject);
      setLoadingBet(false);
      setBetShow(true)
      if (!saveBetOdds.error) {
        message.success("Success! Your bet has been placed");
        betListFunction(matkaEventId);
        dispatch(userAction.userBalance());
        setBackBetModal(false);
      } else {
        message.error("Sorry, your bet couldn't be placed.");
      }
    } catch (error) {
      console.error('Error placing bet:', error);
    }
  };

  // const placeBet = async () => {
  //   setLoadingBet(true);
  //   let betObject = {
  //     "matkaEventId": betSlipData?.data?.matkaEventId,
  //     "gameType": betSlipData?.gameType,
  //     "betType": selectedMarket,
  //     "betNumber": betSlipData?.betNumber,
  //     "amount": Number(betSlipData.stake)
  //   };

  //   console.log(betObject, "jkjkkk");

  //   // dispatch(matkaPlaceBet(betObject))
  //   handleClose();
  //   setLoadingBet(false);
  // };


  const updateStake = (event) => {
    try {
      let { value } = event.target;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      setBetSlipData(prevBetSlipData => ({
        ...prevBetSlipData,
        stake: value,
      }));
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };

  const updateStackOnclick = (num) => {
    setBetSlipData(prevBetSlipData => ({
      ...prevBetSlipData,
      stake: num,
    }));
  };



  const betListFunction = async () => {
    let betReq = {
      "matkaEventId": matkaEventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      gameType: 'single'
    };

    let getCasinoDetails = await httpPost(`matka/matkaBetList`, betReq);
    let totalProfitLoss = 0;
    let betList = getCasinoDetails?.data;
    if (betList && betList.length > 0) {
      betList.map((bet) => {
        totalProfitLoss += Number(bet.profitLoss);
      });
    }
    if (getCasinoDetails) {
      setBetList(betList);
      setTotalProfitLoss(totalProfitLoss);
    }
  };

  const handleClose = () => {
    setBackBetModal(false);
    // setBackBetModalMobile(false);
  };

  const openBets = () => {
    setBackBetModal(false);
  };

  const cardData = Array.from({ length: 10 }, (_, index) => index);

  const handalChnage = (value) => {
    setSelectedMarket(value);
    // if (value === "OPEN" || value === "CLOSE") {
    //   setShowBetPlaceModal(true);
    // }
  };

  const getMatkaListByEventID = async () => {
    let betReq = {
      "matkaEventId": matkaEventId,
    };

    let getMatkaDetails = await httpPost(`matka/getMatkaByMatkaEventId`, betReq);
    if (getMatkaDetails?.data) {
      setIsmatkaList(getMatkaDetails.data ? getMatkaDetails?.data : {});
    }

  }

  const showOpenBet = ismatkaList?.openBet;
  const showCloseBet = ismatkaList?.closeBet;

  return (
    <section className="px-2 ">


      <Typography style={{ color: "#26122D" }} className=" gx-fs-xxl rounded font-bold text-center uppercase py-2 bg-orange-500 mb-2">{ismatkaList?.name ? ismatkaList?.name : ''}-SINGLE</Typography>
      <div className="lg:flex gap-x-2">


        <div className="lg:w-3/4 full">

          <Row justify={"center"}>
            <Col className="flex gx-justify-content-center gx-w-100 mt-4" >

              <Select size="large" placeholder="Market Type" onChange={handalChnage} style={{ width: 300 }}>
                {showOpenBet && <Option value="OPEN">Open Market</Option>}
                {showCloseBet && <Option value="CLOSE">Close Market</Option>}
              </Select>
            </Col>
          </Row>

          <Row justify={"space-between"} className="mt-2 text-white">

            <Col className="flex justify-start " xs={12} sm={8} md={8} lg={4}>
              <div className="text-md gx-bold ">Open Time:
                <span className="text-blue-500 px-1">{ismatkaList?.openTime ? ismatkaList?.openTime : '-'}</span>
              </div>

            </Col>
            <Col className="flex justify-end" xs={12} sm={8} md={4} lg={4}>
              <div className="text-md font-bold">Close Time:
                <span className="text-red-500 px-1">
                  {ismatkaList?.closeTime ? ismatkaList?.closeTime : '-'}

                </span>
              </div>
            </Col>
          </Row>

          <Row justify={"space-between"} className="mt-2 text-white">
            <Col className="" xs={8} sm={8} md={4} lg={4}>
              <span className="gx-bold">Min: {ismatkaList?.minStake} </span>
            </Col>

            <Col className="flex justify-end" xs={8} sm={8} md={4} lg={4}>
              <span className="font-bold">Max: {ismatkaList?.maxStake}</span>
            </Col>
          </Row>

          <div className="grid grid-cols-5 gap-2 w-full mt-2">

            {cardData.map((card) => (
              <div key={card} className="flex justify-center bg-[#A5D9FE]">
                <div
                  className=" font-bold py-2 mt-2"
                  onClick={() => handleBackOpen({
                    data: ismatkaList,
                    gameType: "SINGLE",
                    betType: "OPEN",
                    betNumber: card?.toString().padStart(1, "0")
                  })}
                >
                  <span className="px-1 py-2 text-black">
                    {card.toString().padStart(1, "0")}
                  </span>
                </div>
              </div>
            ))}

          </div>

          {betList && betList.length > 0 &&
            <div className="my-2">
              <BetListTableMatka betList={betList} />
            </div>}
        </div>






        <div className="lg:w-1/4 lg:block hidden">
          {backBetModal === true ?
            <div className="">

              <BetPlaceMatka
                openBets={openBets}
                // closeRow={closeRow}
                matchName={`${ismatkaList?.name}-Single`}
                betSlipData={betSlipData}
                placeBet={placeBet}
                count={betSlipData?.betNumber}
                betLoading={LoadingBet}
                section1Ref={section1Ref}
                inputRef={inputRef}
                handleClose={handleClose}
              // increaseCount={increaseCount}
              // decreaseCount={decreaseCount}
              />
            </div> : null}


          {/* {backBetModal === true ?
        <PlaceBetMobileBmx
          betSlipData={betSlipData}
          time={time}
          // count={count}
          betChipsData={betChipsData}
          section1Ref={section1Ref}
          inputRef={inputRef}
          updateStake={updateStake}
          placeBet={placeBet}
          updateStackOnclick={updateStackOnclick}
          handleClose={handleClose}
          LoadingBet={LoadingBet}
        />
        : null} */}

          {/* {backBetModal === true ?
        <PlaceBetMobileBmxBet
          betSlipData={betSlipData}
          time={time}
          // count={count}
          betChipsData={betChipsData}
          section1Ref={section1Ref}
          inputRef={inputRef}
          updateStake={updateStake}
          placeBet={placeBet}
          updateStackOnclick={updateStackOnclick}
          handleClose={handleClose}
          LoadingBet={LoadingBet}
        />
        : null} */}

         

        </div>


        <div className="lg:w-1/4 lg:hidden block w-full z-50 top-4 left-0 fixed justify-center">
          {backBetModal === true ?
            <div className="">

              <BetPlaceMatka
                openBets={openBets}
                // closeRow={closeRow}
                matchName={`${ismatkaList?.name}-Single`}
                betSlipData={betSlipData}
                placeBet={placeBet}
                count={betSlipData?.betNumber}
                betLoading={LoadingBet}
                section1Ref={section1Ref}
                inputRef={inputRef}
                handleClose={handleClose}
              // increaseCount={increaseCount}
              // decreaseCount={decreaseCount}
              />
            </div> : null}
        </div>
      </div>
    </section>
  );
};

export default SingleMatka;