import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { httpPost } from "../../middelware/Http";
import logo from "../../Assest/images/business-logo.png";
import { FaGift, FaUser } from "react-icons/fa6";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { FaMoneyBillWave } from "react-icons/fa";
import { BsTelephoneInbound } from "react-icons/bs";
import bgimg from "../../Assest/images/register/drop.png"
import whatsapp from "../../Assest/images/social/whatsapp.svg"
import { IoMdLock } from "react-icons/io";
import { CONST } from "../../apis/userApis"
import { message } from "antd";
import { VscReferences } from "react-icons/vsc";
import { MdClose } from "react-icons/md";

function RegisterModal({ referralCode, setIsRegisterOpen }) {
    const navigate = useNavigate();
    // const { referral } = useParams();


    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("password");
    const [inputFocused, setInputFocused] = useState(false);
    const [user, setUser] = useState({
        name: "",
        mobileNo: "",
        username: "",
        password: "",
        referralCode: referralCode || ""
    });

    const auth = useSelector((state) => state.auth);
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        let truncatedValue = value;
        if (name === "mobileNo") {
            if (value.length > 10) {

                return setErrors({ ...errors, mobileNo: "Mobile number must be 10 digits" })
            }
            truncatedValue = value.slice(0, 10);
        }
        setUser({ ...user, [name]: truncatedValue });
        setErrors({ ...errors, [name]: "" });
    };


    const handleOnSubmit = async (e) => {
        e.preventDefault();


        if (!user.mobileNo || user.mobileNo.length !== 10) {
            setErrors({ ...errors, mobileNo: "Mobile number must be 10 digits" });
            return;
        }
        if (!user.name || user.name.length < 3) {
            setErrors({ ...errors, name: "Name must be at least 3 characters long" });
            return;
        }

        // Validate password
        if (!user.password || user.password.length < 6) {
            setErrors({ ...errors, password: "Password must be at least 6 characters long and contain must be Formate: Ab1234 " });
            return;
        }
        // Check for uppercase letters
        if (!/[A-Z]/.test(user.password)) {
            setErrors({ ...errors, password: "Password must contain at least one uppercase letter" });
            return;
        }
        // Check for lowercase letters
        if (!/[a-z]/.test(user.password)) {
            setErrors({ ...errors, password: "Password must contain at least one lowercase letter" });
            return;
        }
        // Check for numbers
        if (!/\d/.test(user.password)) {
            setErrors({ ...errors, password: "Password must contain at least one digit" });
            return;
        }

        // Check for special characters
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(user.password)) {
            setErrors({ ...errors, password: "Password must contain at least one special character" });
            return;
        }

        setLoading(true);
        try {
            const loginDetails = {
                domainUrl: CONST.BASE_DOMAIN,
                name: user.name,
                username: user.username,
                mobileNo: user.mobileNo,
                password: user.password,

            };
            if (user.referralCode) {
                loginDetails.referralCode = user.referralCode;
            }
         
            const response = await httpPost("website/registerClient", loginDetails);

            if (response) {
                setUser({
                    name: "",
                    username: "",
                    mobileNo: "",
                    password: "",
                    referralCode: ""
                });
                // navigate("/");
                message.success(response?.message)
                // const toastId = toast.success(response?.message);
                // setTimeout(() => toast.dismiss(toastId), 1000);

            }
            else {
                message.error("Registration failed. Please check your details.")

                // const toastId = toast.error("Registration failed. Please check your details.");
                // setTimeout(() => toast.dismiss(toastId), 1000);
            }
        } catch (error) {
            console.error("Error:", error);
            message.error("An error occurred during registration. Please try again later.")
            // const toastId = toast.error("An error occurred during registration. Please try again later.");
            // setTimeout(() => toast.dismiss(toastId), 1000);

        } finally {
            setLoading(false);
        }
    };

    const toggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword); // password Hide and Show Icons
    };

    const handleInputFocus = () => {
        // Input Focus Hover
        setInputFocused(true);
    };

    const handleInputBlur = () => {
        setInputFocused(false);
    };
    const handalClickNavigate = (url) => {
        navigate(url)
    }

    let domainSetting = JSON.parse(localStorage.getItem('clientdomainSetting'));
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleOnSubmit(e);
        }
    };


    return (
        <div className="w-full px-2 flex justify-center  overflow-y-auto ">

            <div className=" bg-black bg-opacity-80  border-2 border-orange-500 py-4 lg:w-5/12 w-full lg:px-10 px-3">
                <div className="px-3 lg:py-2 py-0 flex">

                    <div className=" py-4 w-full rounded-md">
                        <div className=" py-1 px-1 flex justify-end items-center  gap-2">

                            <div onClick={() => setIsRegisterOpen(false)} className="text-white w-5 h-5 bg-red-500 text-center font-bold rounded-full "><MdClose size={20} /></div><br />
                        </div>
                        <div className="px-4 py-2 space-y-2 mt-10">
                            {/* {/ <input type="text" name="name" className="form-input" placeholder="Enter Name" value={user.name} onChange={handleOnChange} /> /} */}
                            <div className="flex items-center gap-3 py-2 px-1 ">
                                <div className="py-2.5 text-white">
                                    <FaUser size={20} />
                                </div>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={user.name}
                                    className="py-2  text-sm text-white bg-transparent placeholder-gray-500 focus:outline-none border-b-2 border-gray-400 focus:border-[#f36c21] w-full"
                                    placeholder="Enter Name"
                                    onChange={handleOnChange}
                                    onKeyPress={handleKeyPress}
                                // required
                                />
                            </div>
                            {errors.name && <div className="text-red-500 text-sm mb-1">{errors.name}</div>}


                            <div className="flex items-center gap-3 py-2 px-1 ">
                                <div className="py-2.5 text-white">
                                    <FaUser size={20} />
                                </div>
                                <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    value={user.username}
                                    className="py-2  text-sm text-white bg-transparent placeholder-gray-500 focus:outline-none border-b-2 border-gray-400 focus:border-[#f36c21] w-full"
                                    placeholder="Enter User Name"
                                    onChange={handleOnChange}
                                    onKeyPress={handleKeyPress}
                                // required
                                />
                            </div>
                            {errors.name && <div className="text-red-500 text-sm mb-1">{errors.username}</div>}

                            <div className="flex  w-full gap-3 sm:py-4 py-2 px-1 ">
                                <div className="py-2.5 text-white">
                                    <BsTelephoneInbound size={20} />
                                </div>
                                <div className="w-full pb-2">
                                    <input
                                        type="tel"
                                        name="mobileNo"
                                        id="mobileNo"
                                        value={user.mobileNo}
                                        className="py-2  text-sm text-white bg-transparent placeholder-gray-500 focus:outline-none border-b-2 border-gray-400 focus:border-[#f36c21] w-full"
                                        placeholder="Enter Mobile No (10 digits)"
                                        onChange={handleOnChange}
                                        onKeyPress={handleKeyPress}
                                    // maxLength={10}
                                    // required
                                    />
                                </div>

                            </div>
                            {errors.mobileNo && <div className="text-red-500 text-sm mb-1">{errors.mobileNo}</div>}


                            <div className="flex items-center gap-3 py-2 px-1 ">
                                <div className="py-2.5 text-white">
                                    <IoMdLock size={20} />
                                </div>

                                <div className="items-start right-0 pb-2 w-full relative">
                                    <input
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputBlur}
                                        type={showPassword ? "text" : password}
                                        name="password"
                                        id="password"
                                        value={user.password}
                                        className="py-2.5  text-sm text-white bg-transparent placeholder-gray-500 focus:outline-none border-b-2 border-gray-400 focus:border-[#f36c21] w-full "
                                        placeholder="Password"
                                        onChange={handleOnChange}
                                        onKeyPress={handleKeyPress}
                                    // maxLength={10}
                                    />

                                    <button
                                        onClick={() => toggleShowPassword()}
                                        className="absolute inset-y-0 right-0 flex items-center px-2 text-white"
                                    >
                                        {showPassword ? (
                                            <BsFillEyeSlashFill
                                                size={20}
                                                className={inputFocused ? "text-[#f36c21]" : ""}
                                            />
                                        ) : (
                                            <BsEyeFill
                                                size={20}
                                                className={inputFocused ? "text-[#f36c21]" : ""}
                                            />
                                        )}
                                    </button>
                                </div>
                            </div>
                            {errors.password && <div className="text-red-500 text-sm mb-1">{errors.password}</div>}


                            {/* <div className="flex  w-full gap-3 sm:py-4 py-2 px-1 ">
                                <div className="py-2.5 text-white">
                                    <VscReferences size={20} />
                                </div>
                                <div className="w-full pb-2">
                                    <input
                                        type="text"
                                        name="referralCode"
                                        id="referralCode"
                                        value={user.referralCode || referralCode}
                                        className="py-2  text-sm text-white bg-transparent placeholder-gray-500 focus:outline-none border-b-2 border-gray-400 focus:border-[#f36c21] w-full"
                                        placeholder="Referral Code Optional"
                                        onChange={handleOnChange}
                                        onKeyPress={handleKeyPress}
                                    // maxLength={10}
                                    // required
                                    />
                                </div>

                            </div>
                            {errors.referralCode && <div className="text-red-500 text-sm mb-1">{errors.referralCode}</div>} */}



                            <button className={`w-full ${(user.name || user.password || user.username || user.mobileNo || user.pass) ? "bg-[#f36c21] text-white" : "bg-gray-500 text-white"}   font-bold py-2 px-4 rounded-full mt-4 ${loading ? "opacity-50 cursor-not-allowed" : ""}`} type="submit" disabled={loading} onClick={handleOnSubmit} >


                                {loading ? <div class="w-6 h-6 border-8 m-auto border-dashed rounded-full animate-spin border-white"></div> : "Register"}

                            </button>

                            {/* <div className="text-center text-sm font-normal text-white mt-3 capitalize">
                                Already have an account?
                                <span onClick={() => handalClickNavigate('/login')} className="text-blue-500 px-2 text-sm font-bold cursor-pointer underline">Login
                                </span>

                            </div> */}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default RegisterModal;