import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { httpPost } from "../../../../middelware/Http";
import { Col, Row } from "antd";
import sigale1 from '../../../../Assest/images/matkaNewImages/images/1_single.png'
import jodi2 from '../../../../Assest/images/matkaNewImages/images/2_jodi.png'
import singlepatti3 from '../../../../Assest/images/matkaNewImages/images/3_singlepatti.png'
import double from '../../../../Assest/images/matkaNewImages/images/4_double.png'
import triple from '../../../../Assest/images/matkaNewImages/images/5_triple.png'
import odd_even from '../../../../Assest/images/matkaNewImages/images/16_odd_even.png'

const MatkaType = () => {
    let { matkaEvent, matakaName } = useParams()
    const navigate = useNavigate()
    
    const [ismatkaList, setIsmatkaList] = useState({});

    useEffect(() => {
        getMatkaListByEventID()
    }, [])

 
    const getMatkaListByEventID = async () => {

        let betReq = {
            "matkaEventId": matkaEvent,
        };

        let getMatkaDetails = await httpPost(`matka/getMatkaByMatkaEventId`, betReq);
        if (getMatkaDetails?.data) {
            setIsmatkaList(getMatkaDetails.data ? getMatkaDetails?.data : {});
        }



    }

    return (
        <Row
        
        className="gx-ml-0 mt-2 px-2 "
        justify="center"
        align="middle"
      >
        <Col xs={24}>
          <Row justify={"center"}>
            <Col xs={24} className="text-xl text-center font-bold uppercase bg-orange-500 mt-2 rounded" style={{ color: "#26122D" }}> 
              {matakaName}
            </Col>
          </Row>
          <Row className="py-2 mt-4" justify={"center"}>
            <Col xl={4} md={10} xs={12}>
              <img onClick={() => navigate(`/app/matka-single/${matkaEvent}`)} alt="single" src={sigale1} />
            </Col>
            <Col xl={4} md={10} xs={12}>
            <img onClick={() => navigate(`/app/matka-jodi/${matkaEvent}`)} alt="jodi" src={jodi2} />
            </Col>
          </Row>
  
          <Row className="py-2" justify={"center"}>
            <Col xl={4} md={10} xs={12}>
              <img onClick={() => navigate(`/app/matka-single-patti/${matkaEvent}`)} alt="single" src={singlepatti3} />
            </Col>
            <Col xl={4} md={10} xs={12}>
              <img onClick={() => navigate(`/app/matka-double-patti/${matkaEvent}`)} alt="single" src={double} />
            </Col>
            
          </Row>
          <Row className="py-2" justify={"center"}>
          <Col xl={4} md={10} xs={12}>
              <img onClick={() => navigate(`/app/matka-tripple-patti/${matkaEvent}`)} alt="single" src={triple} />
            </Col>
            <Col xl={4} md={10} xs={12}>
              <img onClick={() => navigate(`/app/matka-odd-even/${matkaEvent}`)} alt="single" src={odd_even} />
            </Col>
          </Row>
        </Col>
      </Row>
    )
}

export default MatkaType;




