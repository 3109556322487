import React, { useEffect, useState } from "react";
import { DrawerList } from './drawerConstent'
import { DrawerListMatch } from './drawerConstentMatch'
import { Link, useNavigate } from "react-router-dom";
import Rg_icon from "../../../Assest/images/RG ICON.png";
import SEXY_ICON from "../../../Assest/images/AE SEXY ICON.png"
import LUDO from "../../../Assest/images/LUDO ICON.png"
import aVIATOR from "../../../Assest/images/aVIATOR.png"
import ezugI from "../../../Assest/images/ezugI.png";
import { useSelector } from "react-redux";
import { FaUserInjured } from "react-icons/fa";
import leftBlue from "../../../Assest/images/left-blue.webp"
import LiveCard from "../../../Assest/images/SubHeaderImage/Live-Card.webp";


export default function Drawer({ children, isOpen, setIsOpen }) {

    const navigate = useNavigate();
    const onClickMenu = (data) => {
        navigate('/app/iframe-casino/' + data)
        closeDrawer()
    }

    const onClickMenuQtech = (data) => {
        navigate('/app/iframe-qtech-casino/' + data)
        closeDrawer()
    }

    const [hoveredIndex, setHoveredIndex] = useState(null);
    // Handal Hover Mouse Change icon 
    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };
    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };
    const closeDrawer = () => {
        setIsOpen(false)
    }

    let user = JSON.parse(localStorage.getItem('clientUser'));
    const domainSetting = JSON.parse(localStorage.getItem('clientdomainSetting'));
    let sportSettingIntCasino = domainSetting?.sportsSetting?.isIntCasino ? domainSetting?.sportsSetting?.isIntCasino : true
    let sportSettingDiaCasino = domainSetting?.sportsSetting?.isDiamondCasino ? domainSetting?.sportsSetting?.isDiamondCasino : true
    const lastItemIndex = DrawerList.length - 1;



    const { sportmatchlist } = useSelector((state) => state.authentication);
    const [sportIdsWithLength, setSportIdsWithLength] = useState([]);

    useEffect(() => {
        if (sportmatchlist && sportmatchlist.data) {
            const filteredDataBySportId = {};
            sportmatchlist.data.forEach(item => {
                const sportId = item.sportId;
                if (!filteredDataBySportId[sportId]) {
                    filteredDataBySportId[sportId] = [];
                }
                filteredDataBySportId[sportId].push(item);
            });
            const sportIdsLength = Object.keys(filteredDataBySportId).map(sportId => ({
                sportId,
                length: filteredDataBySportId[sportId].length
            }));
            setSportIdsWithLength(sportIdsLength);
        }
    }, [sportmatchlist]);


    const handalClickNavigate = (url) => {
        navigate(url)

    }

    return (
        <main className={`fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ${isOpen ? 'block' : 'hidden'}`}>
            <section className={" w-screen max-w-[16rem] left-0 absolute bg-black  h-full shadow-xl overflow-y-auto"}          >
                <article className="relative w-screen max-w-[16rem] flex flex-col  h-full">
                    <header className="p-6 font-bold text-lg text-white flex justify-start items-center gap-2" style={{ backgroundImage: `url(${leftBlue})` }}>
                        <span><FaUserInjured size={40} className="text-orange-500" /></span>
                        <span className=" h-full flex items-center"> {user?.data?.username}</span>
                        <span className="flex items-center justify-center">
                            {user && user.data && `(${user.data.name})`}
                        </span>


                    </header>
                    {children}
                    <div className="h-full">
                        <ul className="font-medium">

                            {DrawerListMatch?.filter(items => items.isVisible === true)?.map((item, index) => (
                                <li key={index} className={index === lastItemIndex ? 'border-b bg-green-500' : 'border-b'} onClick={() => closeDrawer()}>
                                    <div
                                        onClick={() => handalClickNavigate(item.link + item.sportId)}
                                        className={index === lastItemIndex ? 'flex items-center p-3 text-gray-900 dark:text-white  hover:bg-green-500' : 'flex items-center p-3 text-gray-900 dark:text-white  transition duration-0 hover:duration-150 group hover:border-r-8 hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]'}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        {/* <img src={index === hoveredIndex ? item.icon2 : item.icon1} alt={item.title} className="h-5 w-5" /> */}
                                        <img src={item.icon} alt={item.title} className="h-5 w-5" />

                                        <span className="ms-3 text-sm text-white">{item.title}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {sportSettingDiaCasino && (
                            <>
                                <div onClick={() => navigate("/app/casino") || closeDrawer()} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                    <div className="py-1 flex gap-2 items-center">
                                        <span className="">
                                            <img src={LiveCard} alt="rg_icon" className="h-6 w-6" />
                                        </span>
                                        <span className="text-sm text-white group-hover:text-[#f36c21]">Diamond Casino</span>
                                    </div>
                                </div>
                                <hr />
                            </>
                        )}

                        {sportSettingIntCasino && (
                            <>
                                <div onClick={() => onClickMenu(900000)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                    <div className="py-1 flex gap-2 items-center">
                                        <span className="">
                                            <img src={Rg_icon} alt="rg_icon" className="h-6 w-6" />
                                        </span>
                                        <span className="text-sm text-white group-hover:text-[#f36c21]">RG Casino</span>
                                    </div>
                                </div><hr />
                                <div onClick={() => onClickMenu(100001)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                    <div className="py-1 flex gap-2 items-center">
                                        <span className="">
                                            <img src={ezugI} alt="ezugI" className="h-6 w-6" />
                                        </span>
                                        <span className="text-sm text-white group-hover:text-[#f36c21]">Ezugi</span>
                                    </div>


                                </div><hr />
                                <div onClick={() => onClickMenu(100000)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                    <div className="py-1 flex gap-2 items-center">
                                        <span className="">
                                            <img src={SEXY_ICON} alt="SEXY_ICON" className="h-6 w-6" />
                                        </span>
                                        <span className="text-sm text-white group-hover:text-[#f36c21]">Wheel</span>
                                    </div>


                                </div><hr />
                                <div onClick={() => onClickMenu(600113)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                    <div className="py-1 flex gap-2 items-center">
                                        <span className="">
                                            <img src={LUDO} alt="ludo" className="h-6 w-6" />
                                        </span>
                                        <span className="text-sm text-white group-hover:text-[#f36c21]">Ludo</span>
                                    </div>


                                </div><hr />
                                <div onClick={() => onClickMenu(201206)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                    <div className="py-1 flex gap-2 items-center">
                                        <span className="">
                                            <img src={aVIATOR} alt="aVIATOR" className="h-6 w-6" />
                                        </span>
                                        <span className="text-sm text-white group-hover:text-[#f36c21]">aviator</span>
                                    </div>


                                </div><hr />
                                <div onClick={() => onClickMenu(500001)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                    <div className="py-1 flex gap-2 items-center">
                                        <span className="">
                                            <img src={SEXY_ICON} alt="SEXY_ICON" className="h-6 w-6" />
                                        </span>
                                        <span className="text-sm text-white group-hover:text-[#f36c21]">Supernova</span>
                                    </div>


                                </div><hr />
                                <div onClick={() => onClickMenu(400000)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                    <div className="py-1 flex gap-2 items-center">
                                        <span className="">
                                            <img src={SEXY_ICON} alt="SEXY_ICON" className="h-6 w-6" />
                                        </span>
                                        <span className="text-sm text-white group-hover:text-[#f36c21]">AE Sexy</span>
                                    </div>


                                </div><hr />
                                <div onClick={() => onClickMenu(600000)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                    <div className="py-1 flex gap-2 items-center">
                                        <span className="">
                                            <img src={SEXY_ICON} alt="SEXY_ICON" className="h-6 w-6" />
                                        </span>
                                        <span className="text-sm text-white group-hover:text-[#f36c21]">Jilli</span>
                                    </div>


                                </div><hr />
                                <div onClick={() => onClickMenuQtech("SPB-aviator")} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                    <div className="py-1 flex gap-2 items-center">
                                        <span className="">
                                            <img src={aVIATOR} alt="aviator" className="h-6 w-6" />
                                        </span>
                                        <span className="text-sm text-white group-hover:text-[#f36c21]">QTech</span>
                                    </div>


                                </div>
                            </>
                        )}
                        <hr />
                        <ul className="font-medium">
                            {DrawerList?.map((item, index) => (
                                <li key={index} className={index === lastItemIndex ? 'border-b bg-green-500' : 'border-b'} onClick={() => closeDrawer()}>
                                    <Link
                                        to={item.link}
                                        className={index === lastItemIndex ? 'flex items-center p-3 text-gray-900 dark:text-white  hover:bg-green-500' : 'flex items-center p-3 text-gray-900 dark:text-white group transition duration-0 hover:duration-150 group hover:border-r-8 hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]'}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        {/* <img src={index === hoveredIndex ? item.icon2 : item.icon1} alt={item.title} className="h-5 w-5" /> */}
                                        <img src={item.icon1} alt={item.title} className="h-5 w-5" />

                                        <span className="ms-3 text-sm text-white">{item.title}</span>
                                    </Link>
                                </li>
                            ))}
                        </ul>

                    </div>

                </article>
            </section>
            <section
                className=" w-screen h-full cursor-pointer "
                onClick={() => {
                    setIsOpen(false);
                }}
            ></section>
        </main>
    );
}
