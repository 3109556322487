// import cricket from '../../../Assest/images/game/cricket.webp'
import cricket from '../../../Assest/images/game/cricket.webp'
import soccer from '../../../Assest/images/game/soccer.webp'
import tennis from '../../../Assest/images/game/tennis.webp'
import Election from "../../../Assest/images/SubHeaderImage/Election.webp";

const domainSetting = JSON.parse(localStorage.getItem('clientdomainSetting'));
let sportSetting = domainSetting?.sportsSetting


export const DrawerListMatch = [
  {
    icon: cricket,
    title: "Cricket",
    text: "Cricket",
    count: "9",
    link: "/app/sportsbook/page/",
    sportId: 4,
    isVisible: sportSetting?.isCricket ? sportSetting?.isCricket : true,
  },
  {
    icon: soccer,
    title: "Soccer",
    text: "Soccer",
    count: "32",
    link: "/app/sportsbook/page/",
    sportId: 1,
    isVisible: sportSetting?.isSoccer ? sportSetting?.isSoccer : true,
  },
  {
    icon: tennis,
    title: "Tennis",
    text: "Tennis",
    count: "7",
    link: "/app/sportsbook/page/",
    sportId: 2,
    isVisible: sportSetting?.isTennis ? sportSetting?.isTennis : true,
  },
  {
    icon: Election,
    title: "Election",
    text: "Election",
    link: "/app/sportsbook/page/",
    sportId: 999,
    isVisible: sportSetting?.isPolitics ? sportSetting?.isPolitics : true
  }
  
];