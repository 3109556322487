import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { httpPost } from "../../../middelware/Http";
import walletBank from "../../../Assest/images/wallet-bank.webp"
import InstantWithdrawals from "../../../Assest/images/Instant-Withdrawals.webp"
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { message } from "antd";

function Withdrawal() {
  const [active, setActive] = useState(false);
  const [fieldsChangePassword, setFieldsChangePassword] = useState({ amount: "" });
  const [errorsChangePassword, setErrorsChangePassword] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [accountData, setAccountData] = useState();
  const [accountDetails, setAccountDetails] = useState(null);
  const [existingAccount, setExistingAccount] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    accountNumber: '',
    ifscCode: '',
    accountHolder: '',
    bankName: '',
    branchName: ''
  });
  const [errors, setErrors] = useState({});
  const [request, setRequest] = useState(true);
  const [instent, setInstent] = useState(false);
  const Balance = JSON.parse(localStorage.getItem('clientBalance'));


  useEffect(() => {
    getBankDetails();
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    if (accountDetails) {
      // setFormData(accountDetails);
    }
  }, [accountDetails]);

  const toggleAccordion = () => {
    setActive(!active);
  };

  const inputChange = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      setFieldsChangePassword({
        ...fieldsChangePassword,
        [name]: value
      });
    }

    setErrorsChangePassword((prevState) => ({ ...prevState, [name]: '' }));
  };

  const changePassword = async (e) => {
    e.preventDefault();
    setIsFetching(true);
    if (handleValidationChangePassword()) {
      const changePasswordData = {
        amount: fieldsChangePassword.amount,
      };
      try {
        const response = await httpPost('website/withdrawReq', changePasswordData);
        message.success(response?.message)
        // const toastId = toast.success(response?.message);
        // setTimeout(() => toast.dismiss(toastId), 1000);

        setFieldsChangePassword((prevState) => ({ ...prevState, amount: '0' }));
      } catch (error) {
        message.success("Something went wrong!. Please try again later.")
        // const toastId = toast.error("Something went wrong!. Please try again later.");
        // setTimeout(() => toast.dismiss(toastId), 1000);
      }
    }
    setIsFetching(false);
  };

  const handleValidationChangePassword = () => {
    let errors = {};
    let formIsValid = true;

    if (!fieldsChangePassword.amount) {
      formIsValid = false;
      errors.amount = 'Amount cannot be blank.';
    }
    setErrorsChangePassword(errors);
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setOpenModal(false);
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.accountNumber) {
      errors.accountNumber = 'Please enter account number';
    }
    return errors;
  };

  const addBank = async () => {
    try {

      const res = await httpPost('website/saveAccountDetails', formData);
      setAccountData(res);
      getBankDetails();
      message.success("account details added successfully")
      // const toastId = toast.success("account details added successfully");
      // setTimeout(() => toast.dismiss(toastId), 1000);

    } catch (error) {
      message.error("somthing went wrong");
      // const toastId = toast.error("somthing went wrong");
      // setTimeout(() => toast.dismiss(toastId), 1000);
    }
  };

  const updateBank = async () => {
    try {

      const res = await httpPost('website/updateAccountDetails', formData);
      setAccountData(res);
      setExistingAccount(false)
      closeModal();
      getBankDetails()
      message.success("account details updated successfully");
      // setTimeout(() => toast.dismiss(toastId), 1000);
    } catch (error) {
      message.error("somthing went wrong");
      // setTimeout(() => toast.dismiss(toastId), 1000);
    }
  };


  const openModalGetAccountDetails = async () => {
    if (accountDetails) {
      setExistingAccount(true);
      setFormData(accountDetails);
      setOpenModal(true)
    }

  }

  const getBankDetails = async () => {

    try {
      const res = await httpPost('website/getAccountDetailsOfClient');
      setAccountDetails(res.data);
      if (res.data) {
        // setExistingAccount(true);        
      }
    } catch (error) {
      // Handle error
    }
  };




  const closeModal = () => {
    setOpenModal(false);
    setExistingAccount(false);
    setFormData({
      accountNumber: '',
      ifscCode: '',
      accountHolder: '',
      bankName: '',
      branchName: ''
    });
  };

  const openModalRequest = () => {
    setInstent(false);
    setRequest(true);
  };

  const openModalInstance = () => {
    setInstent(true);
    setRequest(false);
  };
  return (
    <>
      <NavigatePathComponent showPathName={"Choose Withdrawal Method"} />
      <section className="w-full bg-black max-w-7xl mx-auto h-fit flex justify-center lg:py-4 py-0.5 px-0.5 ">
        <div className="relative mx-auto text-center w-full max-w-4xl bg-trasparent afterFooter  shadow-xl ring-1 ring-gray-900/5 sm:rounded-md">
          <div className=" bg-[#353535]">
            <div className="h-14 bg-[#f36c21] py-2 px-1 flex justify-start items-center lg:gap-3 gap-1.5 text-sm font-bold text-black/80  " >
              <span className={request === true ? "border-b-4 border-b-white py-2" : ''}>
                <button className="py-2 rounded-full bg-white px-4 flex items-center gap-2 " onClick={() => openModalRequest()}>
                  <img src={walletBank} alt="walletBank" className="h-5 w-5" />
                  <span className="lg:text-sm lg:font-bold text-xs font-normal text-start">Request Withdrawal</span>
                </button>
              </span>
              {/* <span className={instent === true ? "border-b-4 border-b-white py-2" : ''}>
                <button className="py-2 rounded-full bg-white px-4 flex items-center gap-2  border-white" onClick={() => openModalInstance()}>
                  <img src={InstantWithdrawals} alt="InstantWithdrawals" className="h-5 w-5" />
                  <span className="lg:text-sm lg:font-bold text-xs font-normal text-start">Instant Withdrawal</span>
                </button>
              </span> */}
            </div>


            {request &&
              <>
                <div className=" bg-[#555555] py-2 px-1 flex justify-start items-center gap-3 text-sm font-bold text-black/80">
                  <div className="flex items-center text-base gap-2 px-2 text-white">
                    <span>Available withdrawal</span>
                    <span className={`${Balance > 0 ? "text-green-500" : "text-red-500"}`}>₹ {Balance && Balance ? parseFloat(Balance).toFixed(2) : "0"}</span>
                  </div>
                </div>

                <div className="divide-y px-3 bg-[#353535]">
                  <div>
                    <div className=" lg:hidden block space-y-1 px-2 text-white text-start py-2">
                      <div className="text-base font-bold text-start px-2 uppercase">Bank Details</div>
                      <div className="text-xs truncate font-bold px-2 capitalize">
                        Please add your bank details to place a withdrawal request.
                      </div>
                    </div>

                    <div className="py-3  mt-2 flex justify-between items-center gap-3 text-sm font-bold text-black/80">
                      <div className=" lg:block hidden space-y-3 px-2 text-white text-start">
                        <div className="text-base font-bold">Bank Details</div>
                        <div className="text-sm font-bold">
                          Please add your bank details to place a withdrawal request.
                        </div>
                      </div>

                      <div className="flex justify-center gap-2 items-center">
                        <div className="flex items-center text-base gap-2 px-3 py-1.5 rounded-full text-white bg-[#f36c21]"
                        // onClick={() => setOpenModal(true)}
                        >
                          <span className="text-xs text-start" onClick={() => openModalGetAccountDetails()}>Bank Account </span>
                          <span className="lg:h-5 lg:w-5 h-5 w-5  rounded-full bg-white text-black flex justify-center items-center">+</span>
                        </div>


                        <div className="flex items-center text-base gap-2 px-3 py-1.5 rounded-full text-white bg-[#f36c21]"

                          onClick={() => setOpenModal(true)}>
                          <span className="text-xs text-start">Add New</span>
                          <span className="lg:h-5 lg:w-5 h-5 w-5  rounded-full bg-white text-black flex justify-center items-center">+</span>
                        </div>

                      </div>
                    </div>
                    <div className="py-2 px-2 overflow-y-auto md:block hidden">
                      {accountDetails && (
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-gray-500 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr className="bg-[#1E1E1E]">
                              <th scope="col" className="px-6 lg:py-3 py-1.5">Account Number</th>
                              <th scope="col" className="px-6 lg:py-3 py-1.5">Account Holder</th>
                              <th scope="col" className="px-6 lg:py-3 py-1.5">Bank Name</th>
                              <th scope="col" className="px-6 lg:py-3 py-1.5">Branch Name</th>
                              <th scope="col" className="px-6 lg:py-3 py-1.5">IFSC Code</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="bg-white border-b py-2 dark:bg-gray-800 dark:border-gray-700 text-center  text-lg font-bold">
                              <td>{accountDetails?.accountNumber}</td>
                              <td>{accountDetails?.accountHolder}</td>
                              <td>{accountDetails?.bankName}</td>
                              <td>{accountDetails?.branchName}</td>
                              <td>{accountDetails?.ifscCode}</td>
                            </tr>
                          </tbody>
                        </table>
                      )}

                    </div>
                    {accountDetails && (
                      <div className="md:hidden block text-gray-500 uppercase  bg-gray-50 dark:bg-gray-700 dark:text-gray-400 py-2">


                        <div className="flex  items-center px-2 bg-white border-b py-2 dark:bg-gray-800 dark:border-gray-700 text-center  text-sm font-bold">
                          <span className="flex-1 text-start ">Account Number</span>
                          <span className="flex-1 text-left pl-4">{accountDetails?.accountNumber}</span>
                        </div>

                        <div className="flex  items-center px-2 bg-white border-b py-2 dark:bg-gray-800 dark:border-gray-700 text-center  text-sm font-bold">
                          <span className="flex-1 text-start">IFSC Code</span>
                          <span className="flex-1 text-left pl-4">{accountDetails?.ifscCode}</span>
                        </div>

                        <div className="flex  items-center px-2 bg-white border-b py-2 dark:bg-gray-800 dark:border-gray-700 text-center  text-sm font-bold">
                          <span className="flex-1 text-start">Account Holder</span>
                          <span className="flex-1 text-left pl-4">{accountDetails?.accountHolder}</span>
                        </div>

                        <div className="flex  items-center px-2 bg-white border-b py-2 dark:bg-gray-800 dark:border-gray-700 text-center  text-sm font-bold">
                          <span className="flex-1 text-start">Bank Name</span>
                          <span className="flex-1 text-left pl-4">{accountDetails?.bankName}</span>
                        </div>

                        <div className="flex  items-center px-2 bg-white py-1 dark:bg-gray-800 dark:border-gray-700 text-center  text-sm font-bold">
                          <span className="flex-1 text-start">Branch Name</span>
                          <span className="flex-1 text-left pl-4">{accountDetails?.branchName}</span>
                        </div>



                      </div>
                    )}
                    <div className="">
                      <form className="flex gap-4 items-center justify-start">
                        <div className="relative bg-white rounded-full">
                          <input
                            type="text"
                            id="amount"
                            name="amount"
                            value={fieldsChangePassword.amount}
                            onChange={inputChange}
                            placeholder="Enter Amount"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            className="peer w-full bg-white py-1.5 px-4 rounded-full border-b border-gray-300 placeholder:bg-white focus:border-white focus:outline-none"
                          />
                        </div>
                        <div className="space-x-2 py-5">
                          {console.log(accountDetails, "accountDetailsaccountDetailsaccountDetails")}
                          
                          {accountDetails ? (
                            <button
                              type="submit"
                              onClick={(e) => changePassword(e)}
                              className="bg-[#ED7935] px-3 w-full border-2 border-none text-white text-sm uppercase font-bold py-2 rounded-full tracking-widest"
                            >
                              Withdrawal
                            </button>
                          ) : (
                            <button
                              className="bg-[#555555] px-3 w-full border-2 border-none text-white text-sm uppercase font-bold py-2 rounded-full tracking-widest"
                              disabled
                            >
                              Withdrawal
                            </button>
                          )}
                        </div>
                      </form>
                    </div>

                  </div>

                  <div>
                    <div className="flex-1 py-3" >
                      <div className="w-full rounded-t-lg flex justify-between items-center px-3 py-3" onClick={() => toggleAccordion()}>
                        <span className="text-white text-sm font-normal leading-4 capitalize">condition</span>
                        <span className={`absolute right-3  transition-transform transform duration-200 ${active ? 'rotate-180' : ''}`}>
                          <MdKeyboardArrowDown size={20} className="text-white" />
                        </span>
                      </div>
                      {active && (
                        <>
                          <div className="duration-200  ease-out transition transform origin-bottom py-2 px-2 ">
                            <ul className="text-xs font-medium tracking-wide list-disc text-start text-white leading-4 space-y-2 border-2 border-[#F4DA7B] rounded-2xl px-6 py-2">
                              <li>Bonus is granted only on the first deposit of each registered user.</li>
                              <li>Criteria for bonus activation:</li>

                              <li>If a user only deposits and attempts to withdraw the money without placing a single bet, 100% of the amount will be withheld due to suspicious activity. If this is repeated, no withdrawal will be given to the user</li>

                              <li>Turnover in SPORTSBOOK and PREMIUM CRICKET will be considered only on bets above 1.35 odds.</li>

                              <li>Turnover on session bets will only be considered on the "NO" option.</li>
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            }
            {instent &&
              <>
                <div className=" bg-[#555555] py-2 px-1 flex justify-start items-center gap-3 text-sm font-bold text-black/80">
                  <div className="flex items-center text-base gap-2 px-2 text-white">
                    <span>Available withdrawal</span>
                    <span>₹ 0</span>
                  </div>
                </div>
                <div className="divide-y px-3 bg-[#353535]">
                  <div>

                    <div className="py-3  mt-2 flex justify-between items-center gap-3 text-sm font-bold text-black/80">
                      <div className=" space-y-3 px-2 text-white text-start">
                        <div className="text-base font-bold">Bank Details</div>
                        <div className="text-sm font-bold">
                          Please add your bank details to place a withdrawal request.
                        </div>
                      </div>
                      <div className="flex items-center gap-3 px-3 py-1.5 rounded-full text-white bg-[#f36c21]"

                      // onClick={() => setOpenModal(true)}
                      >
                        <span className="text-xs text-start">Add New</span>
                        <span className="lg:h-5 lg:w-5 w-9 h-6 rounded-full bg-white text-black flex justify-center items-center">+</span>
                      </div>
                    </div>
                    <div className="">
                      <form onSubmit={changePassword} className="flex gap-4 items-center justify-start">
                        <div className="relative bg-white rounded-full">
                          <input
                            type="text"
                            id="amount"
                            name="amount"
                            // value={fieldsChangePassword.amount}
                            // onChange={inputChange}
                            placeholder="Enter Amount"
                            className="peer w-full bg-white py-1.5 px-4 rounded-full border-b border-gray-300 placeholder:bg-white focus:border-white focus:outline-none"
                          />
                        </div>
                        <div className="space-x-2 py-5">
                          <button type="submit" className="bg-[#ED7935] px-3 w-full border-2 border-none text-white text-md uppercase font-normal py-1.5 rounded-full tracking-widest">Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>


                  <div>
                    <div className="flex-1 py-3" >
                      <div className="w-full rounded-t-lg flex justify-between items-center px-3 py-3" onClick={() => toggleAccordion()}>
                        <span className="text-white text-sm font-normal leading-4 capitalize">condition</span>
                        <span className={`absolute right-3  transition-transform transform duration-200 ${active ? 'rotate-180' : ''}`}>
                          <MdKeyboardArrowDown size={20} className="text-white" />
                        </span>
                      </div>
                      {active && (
                        <>
                          <div className="duration-200  ease-out transition transform origin-bottom py-2 px-2 ">
                            <ul className="text-xs font-medium tracking-wide list-disc text-start text-white leading-4 space-y-2 border-2 border-[#F4DA7B] rounded-2xl px-6 py-2">
                              <li>Bonus is granted only on the first deposit of each registered user.</li>
                              <li>Criteria for bonus activation:</li>

                              <li>If a user only deposits and attempts to withdraw the money without placing a single bet, 100% of the amount will be withheld due to suspicious activity. If this is repeated, no withdrawal will be given to the user</li>

                              <li>Turnover in SPORTSBOOK and PREMIUM CRICKET will be considered only on bets above 1.35 odds.</li>

                              <li>Turnover on session bets will only be considered on the "NO" option.</li>
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

              </>
            }
          </div>
        </div>
        {openModal && <>
          <div className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center`}>
            <div className="bg-[#121212] p-8 rounded-lg w-96">
              <div className="flex justify-between items-center mb-4">
              </div>
              <form onSubmit={handleSubmitForm}  >
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-300 mb-1" htmlFor="accountNumber">Account Number</label>
                  <input
                    type="text"
                    id="accountNumber"
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-md p-2 w-full  focus:ring focus:border-blue-500"
                  />
                  {errors.accountNumber && <p className="text-red-500 text-xs mt-1">{errors.accountNumber}</p>}
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-300 mb-1" htmlFor="confirmAccountNumber">Confirm Account Number</label>
                  <input
                    type="text"
                    id="confirmAccountNumber"
                    name="confirmAccountNumber"
                    value={existingAccount ? formData.accountNumber : formData.confirmAccountNumber}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring focus:border-blue-500"
                  />
                  {errors.confirmAccountNumber && <p className="text-red-500 text-xs mt-1">{errors.confirmAccountNumber}</p>}
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-300 mb-1" htmlFor="ifscCode">IFSC Code</label>
                  <input
                    type="text"
                    id="ifscCode"
                    name="ifscCode"
                    value={formData.ifscCode}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring focus:border-blue-500"
                  />
                  {errors.ifscCode && <p className="text-red-500 text-xs mt-1">{errors.ifscCode}</p>}
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-300 mb-1" htmlFor="accountHolder">Account Holder</label>
                  <input
                    type="text"
                    id="accountHolder"
                    name="accountHolder"
                    value={formData.accountHolder}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring focus:border-blue-500"
                  />
                  {errors.accountHolder && <p className="text-red-500 text-xs mt-1">{errors.accountHolder}</p>}
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-300 mb-1" htmlFor="bankName">Bank Name</label>
                  <input
                    type="text"
                    id="bankName"
                    name="bankName"
                    value={formData.bankName}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring focus:border-blue-500"
                  />
                  {errors.bankName && <p className="text-red-500 text-xs mt-1">{errors.bankName}</p>}
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-300 mb-1" htmlFor="branchName">Branch Name</label>
                  <input
                    type="text"
                    id="branchName"
                    name="branchName"
                    value={formData.branchName}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring focus:border-blue-500"
                  />
                  {errors.branchName && <p className="text-red-500 text-xs mt-1">{errors.branchName}</p>}
                </div>
                <div className="flex justify-start gap-x-2">
                  <button onClick={() => closeModal()} className="text-white p-2 text-xs bg-red-600 rounded">CLOSE</button>

                  {existingAccount ?
                    <button type="submit" className="text-white p-2 text-xs bg-green-600 rounded" onClick={() => updateBank()}>UPDATE</button>
                    : <>
                      {Object.values(formData).some(value => value === '') ? (
                        <button className="text-gray-700 p-2 text-xs" type="button">ADD</button>
                      ) : (
                        <button type="submit" className="text-white p-2 text-xs bg-green-600 rounded" onClick={() => addBank()}>ADD</button>
                      )}
                    </>
                  }
                </div>
              </form>
            </div>
          </div>
        </>
        }

      </section>
    </>

  )
}

export default Withdrawal;