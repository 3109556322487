import io from 'socket.io-client';
import { CONST } from '../../../apis/userApis';
import { socketDomain } from '../../../globleConstent/membership/membership';


let socket = null;
let domain = socketDomain
let user = JSON.parse(localStorage.getItem('clientUser'));

let userId = user?.data?.userId

export const initSocket = (token) => {
    if (!socket) {
        socket = io(CONST.BACKEND_SOCKET_URL, {
            // auth: {
            //     token: token
            // },
            reconnection: true, // Enable automatic reconnection
            reconnectionAttempts: 5, // Number of reconnection attempts
            reconnectionDelay: 1000, // Delay between reconnection attempts in milliseconds
            reconnectionDelayMax: 5000, // Maximum delay between reconnection attempts
            randomizationFactor: 0.5 // Randomization factor to vary the reconnection delay
        });


        socket.emit('reconnect', {userId, domain}, () => {
            console.warn('Socket reConnect');
        });


        socket.on('connect', () => {
            console.warn('Socket connected');
        });

        socket.on('disconnect', () => {
            console.log('Socket disconnected');
        });

        socket.on('reconnect', (attemptNumber) => {
            console.log(`Socket reconnected after ${attemptNumber} attempts`);
        });

        socket.on('reconnect_attempt', () => {
            console.log('Attempting to reconnect...');
        });

        socket.on('reconnect_error', (error) => {
            console.error('Reconnection attempt failed:', error);
        });
    }

    return socket;
};

export const getSocket = () => {
    return socket;
};
