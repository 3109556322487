import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import moment from "moment";
import { userAction } from "../../../redux/action/userAction";
import { useDispatch, useSelector } from "react-redux";
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";
import { message } from "antd";
import { httpPost } from "../../../middelware/Http";
import { IoMdLock } from "react-icons/io";
import { FcMoneyTransfer } from "react-icons/fc";
import { MdClose } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";

function DownlineList() {
    const [downlistData, setDownLineData] = useState({});
    const [userIdData, setUserIdData] = useState(false);
    const [user, setUser] = useState({
        amount: "",
        password: "",
    });
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const { downlineListData } = useSelector(state => state.authentication);
    let Balance = JSON.parse(localStorage.getItem('clientBalance'));

    useEffect(() => {
        dispatch(userAction.downLineLists());
    }, [dispatch]);

    const handleDepositModal = (item) => {
        setUserIdData(!userIdData);
        setDownLineData(item?.userId);
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        let updatedUser = { ...user, [name]: value };
        if (name === 'amount') {
            if (parseFloat(value) > Balance) {
                setErrors({ ...errors, amount: `You Can't Transfer Money More Then ${Balance}`  });
            } else {
                setErrors({ ...errors, amount: "" });
            }
        }
console.log(updatedUser, "updatedUserupdatedUserupdatedUserupdatedUser");

        setUser(updatedUser);
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (parseFloat(user.amount) > Balance) {
            setErrors({ ...errors, amount: `You Can't Transfer Money More Then ${Balance}` });
            return;
        }

        try {
            const loginDetails = {
                clientId: downlistData,
                amount: user.amount,
                password: user.password,
            };

            console.log(loginDetails);

            const response = await httpPost("daman/transferClientToClient", loginDetails);

            if (response) {
                setUser({ amount: "", password: "" });
                message.success(response?.message);
                setUserIdData(false)
            } else {
                // message.error("Registration failed. Please check your details.");
            }
        } catch (error) {
            message.error(error.message || "An error occurred.");
        }
    };

    return (
        <>
            <section className="w-full bg-black">
                <NavigatePathComponent showPathName={"Downline Lists"} />
                <div className="relative mx-auto text-center w-full max-w-6xl shadow-xl ring-1 ring-gray-900/5 sm:rounded-md py-4">
                    <div className="px-2 space-y-1 h-auto afterFooter">
                        <div className="py-4 space-y-1">
                            {downlineListData?.length > 0 && (
                                <div className="flex flex-col">
                                    <div className="overflow-x-auto shadow-md sm:rounded-lg">
                                        <div className="inline-block min-w-full align-middle">
                                            <div className="overflow-hidden">
                                                <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700">
                                                    <thead className="bg-gray-600 dark:bg-gray-700">
                                                        <tr>
                                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-100 uppercase dark:text-gray-400">User Name</th>
                                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-100 uppercase dark:text-gray-400">Date</th>
                                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-100 uppercase dark:text-gray-400">Action</th>
                                                        </tr>
                                                    </thead>
                                                    {downlineListData?.map((item, index) => (
                                                        <tbody key={index} className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700 text-start">
                                                            <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 uppercase">
                                                                <td className="py-4 px-6 text-sm font-medium text-gray-500 lg:whitespace-normal whitespace-nowrap dark:text-white">{item.username}</td>
                                                                <td className="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">{moment(item.createdAt).format('DD/MM/YYYY hh:mm:ss A')}</td>
                                                                <td className="py-4 px-6 text-sm font-bold whitespace-nowrap dark:text-white flex items-center " onClick={() => handleDepositModal(item)}>Transfer Wallet <GiMoneyStack size={30} color="green"/></td>
                                                            </tr>
                                                        </tbody>
                                                    ))}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {userIdData && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center px-2 w-full" onClick={() => setUserIdData(false)}>
                        <div className="bg-black border-[#EA580C] border-2 max-w-2xl w-full px-3" onClick={(e) => e.stopPropagation()}>
                            <div className="flex justify-between w-full py-2">
                                <span className="text-center px-2 text-white flex justify-center rounded ">
                                    Your Total Money:- <p className="text-green-700 px-2">{Balance}</p>
                                </span>
                                <span onClick={() => setUserIdData(false)} className="w-5 h-5 rounded-full bg-red-700 flex justify-center items-center font-bold text-lg">
                                    <MdClose color="black" />
                                </span>
                            </div>

                            <div className="px-4 py-2 space-y-2 mt-6">
                                <div className="flex items-center gap-3 py-2 px-1 ">
                                    <div className="py-2.5 text-white">
                                        <FcMoneyTransfer size={20} />
                                    </div>
                                    <input
                                        type="text"
                                        name="amount"
                                        id="amount"
                                        value={user.amount}
                                        className="py-2 text-sm text-white bg-transparent placeholder-gray-500 focus:outline-none border-b-2 border-gray-400 focus:border-[#f36c21] w-full"
                                        placeholder="Enter Amount"
                                        onChange={handleOnChange}
                                    />
                                </div>
                                {errors.amount && <div className="text-red-500 text-sm mb-1">{errors.amount}</div>}

                                <div className="flex items-center gap-3 py-2 px-1 ">
                                    <div className="py-2.5 text-white">
                                        <IoMdLock size={20} />
                                    </div>
                                    <input
                                        type="text"
                                        name="password"
                                        id="password"
                                        value={user.password}
                                        className="py-2.5 text-sm text-white bg-transparent placeholder-gray-500 focus:outline-none border-b-2 border-gray-400 focus:border-[#f36c21] w-full"
                                        placeholder="Your Password"
                                        onChange={handleOnChange}
                                    />
                                </div>

                                <button
                                    className={`w-full ${(user.amount && parseFloat(user.amount) <= Balance) ? "bg-[#f36c21] text-white" : "bg-gray-500 text-white"} font-bold py-2 px-4 rounded-full mt-4`}
                                    type="submit"
                                    onClick={handleOnSubmit}
                                    disabled={parseFloat(user.amount) > Balance}
                                >
                                    Transfer
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </>
    );
}

export default DownlineList;
