import React, { useState, useEffect } from "react";
import { SidebarRightList } from './SidebarRightList'
import { Link, NavLink, useNavigate } from "react-router-dom";
import whatsapp from '../../../Assest/images/social/whatsapp.svg'
import { toast } from "react-toastify";
import leftBlue from "../../../Assest/images/left-blue.webp"
import { B2C } from "../../../globleConstent/membership/membership";
import { message } from "antd";
import { httpPost } from "../../../middelware/Http";
import moment from "moment";
import profile from '../../../Assest/images/rightsidebar/profile.webp'
import RegisterModal from "../../../pages/Register/RegisterModal";
import { MdClose } from "react-icons/md";
import { FcMoneyTransfer } from "react-icons/fc";
import { IoMdLock } from "react-icons/io";
import { FaUser } from "react-icons/fa";



export default function SidebarRight({ children, isOpenRightSidebar, setIsOpenRightSidebar, isOpen }) {

    const navigate = useNavigate();
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [isExposerOpen, setIsExposerOpen] = useState(false);
    const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const [isDownlineModal, setIsDownlineModal] = useState(false);
    const [userData, setUserData] = useState({
        amount: "",
        password: "",
        name: ''
    });
    const [errors, setErrors] = useState({});


    const [exposerData, setExposerData] = useState([])

    const handleItemClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    useEffect(() => {
        // const savedIndex = localStorage.getItem('activeIndex');
        // if (savedIndex !== null) {
        //     setActiveIndex(parseInt(savedIndex));
        // }

        if (isExposerOpen || isDownlineModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isExposerOpen, isDownlineModal]);

    // useEffect(() => {
    //     if (activeIndex !== null) {
    //         localStorage.setItem('activeIndex', activeIndex);
    //     } else {
    //         localStorage.removeItem('activeIndex');
    //     }
    // }, [activeIndex]);

    // Handal Hover Mouse Change icon 
    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };
    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    let Balance = JSON.parse(localStorage.getItem('clientBalance'));
    let Exposure = JSON.parse(localStorage.getItem('clientExposure'));
    let user = JSON.parse(localStorage.getItem('clientUser'));

    function logoutUser() {
        // dispatch(logout())
        localStorage.clear()
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientBalance');
        localStorage.removeItem('clientExposure');
        navigate('/')
        message.success("User! Logout successfully.")
        // const toastId = toast.error("User! Logout successfully.");
        // setTimeout(() => toast.dismiss(toastId), 1000);
    }
    const handleCloseBack = () => {
        setIsOpenRightSidebar(false)
    }

    const handleExposerModal = async () => {

        let betReq = {
            "fancyBet": true,
            "oddsBet": true,
            // "casinoBet": true,
            "isDeclare": false,
            "diamondBet": true,
            "isClientExposure": true
        };
        const userBetHistory = await httpPost('sports/betsList', betReq);
        if (userBetHistory && userBetHistory.data) {
            setExposerData(userBetHistory.data)

        }
        setIsExposerOpen(true)
    }

    const handleRegister = () => {
        setIsRegisterOpen(true)
    }

    const handleDownlineDepositModal = (item) => {
        setIsDownlineModal(!isDownlineModal);
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        let updatedUser = { ...userData, [name]: value };
        if (name === 'amount') {
            if (parseFloat(value) > Balance) {
                setErrors({ ...errors, amount: `You Can't Transfer Money More Then ${Balance}` });
            } else {
                setErrors({ ...errors, amount: "" });
            }
        }


        setUserData(updatedUser);
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (parseFloat(userData?.amount) > Balance) {
            setErrors({ ...errors, amount: `You Can't Transfer Money More Then ${Balance}` });
            return;
        }


        try {
            const loginDetails = {
                // clientId: downlistData,
                amount: userData.amount,
                password: userData.password,
                username: userData.name
            };

            const response = await httpPost("daman/transferClientToClient", loginDetails);

            if (response) {
                setUserData({ amount: "", password: "", name: '' });
                message.success(response?.message);
                setIsDownlineModal(false)
            } else {
                // message.error("Registration failed. Please check your details.");
            }
        } catch (error) {
            message.error(error.message || "An error occurred.");
        }
    };




    let domainSetting = JSON.parse(localStorage.getItem('clientdomainSetting'));
    return (
        <main className={`fixed overflow-hidden z-10 bg-gray-900 bg-opacity-50 inset-0 transform ease-in-out ${isOpenRightSidebar ? 'block' : 'hidden'}`}>
            <section className={" w-screen max-w-[17rem] right-0 absolute bg-black  h-full shadow-xl"}>
                <article className="relative w-screen max-w-[17rem] flex flex-col overflow-y-auto  h-full border-l border-l-[#f36c21]">
                    <header className="py-6 px-2 font-light text-md text-white " style={{ backgroundImage: `url(${leftBlue})` }}>
                        <span className="font-bold">Hi {user?.data?.username}</span><br />
                        <span>You are in the <b>Blue</b> Tier</span>
                    </header>
                    {children}
                    <div className="h-full text-white ">
                        <div className="py-2 w-full px-3 ">
                            <div className="flex justify-center items-center py-1">
                                <span className="flex-1 font-normal text-sm">Wallet Amount
                                    <br /><p className="text-[10px]">(Inclusive bonus)</p></span>
                                <span className="text-[#f36c21] flex-1 text-md font-bold">
                                    {Math.abs(Math.floor(Number(Balance) * 100) / 100).toFixed(2)}
                                    {/* {Balance} */}

                                </span>
                            </div>

                            <div className="flex justify-center items-center py-1" >
                                <span className="flex-1 font-normal text-sm">Net Exposure</span>
                                <span className="text-red-600 flex-1 text-md font-bold" onClick={() => handleExposerModal()}>
                                    {Math.abs(Math.floor(Number(Exposure) * 100) / 100).toFixed(2)}

                                    {/* {Math.abs(Exposure)} */}


                                </span>

                            </div>

                            <div className="flex justify-center items-center py-1">
                                <span className="flex-1 font-normal text-sm">Bonus</span>
                                <span className="text-[#f36c21] flex-1 text-md font-bold">0</span>
                            </div>

                            <div className="flex justify-center items-center py-1">
                                <span className="flex-1 font-normal text-sm">Available Withdrawal</span>
                                <span className="text-[#f36c21] flex-1 text-md font-bold">

                                    {Math.abs(Math.floor(Number(Balance - (Exposure > 0 ? -Exposure : 0)) * 100) / 100).toFixed(2)}

                                    {/* {Balance - (Exposure > 0 ? -Exposure : 0)} */}
                                </span>
                            </div>


                        </div>

                        <div className="py-2">
                            <div className="px-2 py-2">
                                <div className=" w-full flex justify-between px-4 py-2 text-sm uppercase font-bold rounded-full border border-[#f36c21]">
                                    <span className=" ">Awaiting Bonus</span>
                                    <span className="text-[#f36c21]">0</span>
                                </div>
                            </div>
                            {B2C === true && (
                                <div className="py-1.5 w-full px-2 flex gap-1 ">
                                    <Link to={'/app/wallet/deposit'} className="w-1/2">
                                        <div className="px-6 max-w-2/6 py-2 bg-[#f36c21] text-sm rounded-full" onClick={() => handleCloseBack()}>
                                            <span className="whitespace-nowrap">Deposit</span>
                                        </div>
                                    </Link>

                                    <Link to={'/app/wallet/withdrawal'} className="w-1/2">
                                        <div className=" max-w-4/6 px-4 py-2 text-sm rounded-full bg-[#f36c21]" onClick={() => handleCloseBack()}>
                                            <span className="whitespace-nowrap ">Withdrawal</span>
                                        </div>
                                    </Link>
                                </div>
                            )}

                            <Link to={'/app/reffer-and-earn'} className="w-1/2">
                                <div className="py-1.5 w-full px-2 flex gap-3" onClick={() => handleCloseBack()}>
                                    <div className="text-center uppercase px-4 w-full py-2 bg-[#f36c21] text-sm rounded-full ">
                                        <span className="whitespace-nowrap  ">Refer And Earn</span>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        {/* list of the sidebar data  */}
                        <div className="py-1">
                            <ul className="">
                                {SidebarRightList?.map((item, index) => {
                                    // Conditional rendering for "My Wallet" based on B2C
                                    if (item.title === "My Wallet" && !B2C) {
                                        return null;
                                    }

                                    return (
                                        <li
                                            key={index}
                                            className={`border-t ${item.title === "Open Bets" ? "bg-[rgb(243,107,33)]" : ""} ${activeIndex === index ? "bg-[rgb(243,107,33)]" : ""}`}
                                        >
                                            <NavLink
                                                to={item.link}
                                                className='flex items-center p-4 text-gray-900 dark:text-white group hover:bg-[rgb(243,107,33)]'
                                                onMouseEnter={() => handleMouseEnter(index)}
                                                onMouseLeave={handleMouseLeave}
                                                onClick={() => handleCloseBack() || handleItemClick(index)}
                                            >
                                                <img
                                                    src={(index === hoveredIndex || index === activeIndex) ? item.icon2 : item.icon1}
                                                    alt={item.title}
                                                    className="h-5 w-5"
                                                />
                                                <span className=" text-sm px-3 text-white">{item.title}</span>
                                            </NavLink>
                                        </li>
                                    );
                                })}

                            </ul>



                            <ul className="">
                                <li className={`flex items-center p-4 text-gray-900 dark:text-white group border-t`} onClick={() => handleRegister()}>
                                    <img
                                        src={profile}
                                        alt={`profile`}
                                        className="h-5 w-5"
                                    />
                                    <span className=" text-sm px-3 text-white">Create Downline</span>

                                </li>
                            </ul>

                            <ul className="">
                                <NavLink
                                    to={'/app/downline-list'}
                                    className='flex items-center p-4 border-t text-gray-900 dark:text-white group '
                                    onMouseEnter={() => handleMouseEnter()}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() => handleCloseBack()}
                                >
                                    <li className={`flex`}>
                                        <img
                                            src={profile}
                                            alt={`profile`}
                                            className="h-5 w-5"
                                        />
                                        <span className=" text-sm px-3 text-white">Downline List</span>

                                    </li>

                                </NavLink>

                            </ul>














                            <ul className="">
                                {/* <NavLink
                                    to={'/app/transfer-wallet'}
                                    className='flex items-center p-4 border-t text-gray-900 dark:text-white group '
                                    onMouseEnter={() => handleMouseEnter()}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() => handleCloseBack()}
                                > */}
                                <li className={`flex items-center p-4 border-t text-gray-900 dark:text-white group `} onClick={() => handleDownlineDepositModal()}>
                                    <img
                                        src={profile}
                                        alt={`profile`}
                                        className="h-5 w-5"
                                    />
                                    <span className=" text-sm px-3 text-white">Transfer Wallet</span>

                                </li>

                                {/* </NavLink> */}

                            </ul>
                        </div>

                        <div className="py-1.5 h-24 bg-[#424242] w-full text-center px-2 space-y-1 ">
                            <span className="whitespace-nowrap  w-full">Connect with us on</span>
                            <a href={`https://api.whatsapp.com/send?phone=${domainSetting?.whatsappNumber}`} title="Whatsapp" target="_blank" >
                                <div className="uppercase px-4 w-full py-2 bg-[#424242] cursor-pointer hover:bg-green-500 border flex justify-center items-center text-sm rounded-full gap-2 ">
                                    <span className="[&>svg]:h-6 [&>svg]:w-6 ">
                                        <img src={whatsapp} alt="whatapp" className="w-6 h-6" />
                                    </span>
                                    <span className="text-white">Whatsapp</span>
                                </div>
                            </a>
                        </div>
                        <div className="pb-20 w-full  flex gap-3">
                            <div className="text-center uppercase px-4 w-full py-2 bg-green-500 text-sm cursor-pointer" onClick={() => logoutUser()}>
                                <span className="whitespace-nowrap  " >Logout</span>
                            </div>
                        </div>
                    </div>
                </article>
            </section>
            <section
                className=" w-screen h-full cursor-pointer "
                onClick={() => { setIsOpenRightSidebar(false); }}
            ></section>

            {isExposerOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
                    <div className="bg-white p-1 rounded-lg shadow-lg md:w-1/2 w-[360px] max-h-[600px]">


                        <div class="relative overflow-auto max-h-[400px]">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Match
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Stake
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Rate
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Type
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Time
                                        </th>
                                        <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                            Market Type
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {exposerData && exposerData.length > 0 ? exposerData?.map((item, index) => (
                                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">

                                                {item?.market}
                                            </th>
                                            <td class="px-6 py-4">
                                                {item?.amount}
                                            </td>


                                            <td class="px-6 py-4">
                                                {item?.rate}
                                            </td>
                                            <td class="px-6 py-4">
                                                {item?.type}
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap">
                                                {moment(item?.time).format("YYYY-MM-DD hh:mm:ss a")}
                                            </td>
                                            <td class="px-6 py-4">
                                                {item?.marketType}
                                            </td>

                                        </tr>

                                    )) : null}

                                </tbody>
                            </table>
                        </div>

                        <div className="flex justify-end w-full">
                            <button
                                className="mt-4 px-4 py-2 bg-red-500 text-white rounded "
                                onClick={() => setIsExposerOpen(false)}
                            >

                                Close
                            </button>
                        </div>

                    </div>
                </div>
            )}

            {isRegisterOpen && (
                <>
                    <div className="fixed inset-0 bg-black md:bg-opacity-50 z-50 flex justify-center items-center w-full">

                        <RegisterModal referralCode={user.data.referralCode} setIsRegisterOpen={setIsRegisterOpen} />



                    </div>

                </>

            )}


            {isDownlineModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center px-2 w-full" onClick={() => setIsDownlineModal(false)}>
                    <div className="bg-black border-[#EA580C] border-2 max-w-2xl w-full px-3" onClick={(e) => e.stopPropagation()}>
                        <div className="flex justify-between w-full py-2">
                            <span className="text-center px-2 text-white flex justify-center rounded ">
                                Your Total Money:- <p className="text-green-700 px-2">{Balance}</p>
                            </span>
                            <span onClick={() => setIsDownlineModal(false)} className="w-5 h-5 rounded-full bg-red-700 flex justify-center items-center font-bold text-lg">
                                <MdClose color="black" />
                            </span>
                        </div>

                        <div className="px-4 py-2 space-y-2 mt-6">

                            <div className="flex items-center gap-3 py-2 px-1 ">
                                <div className="py-2.5 text-white">
                                    <FaUser size={20} />
                                </div>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={user.name}
                                    className="py-2 text-sm text-white bg-transparent placeholder-gray-500 focus:outline-none border-b-2 border-gray-400 focus:border-[#f36c21] w-full"
                                    placeholder="Enter Username"
                                    onChange={handleOnChange}
                                />
                            </div>
                            {errors.name && <div className="text-red-500 text-sm mb-1">{errors.name}</div>}









                            <div className="flex items-center gap-3 py-2 px-1 ">
                                <div className="py-2.5 text-white">
                                    <FcMoneyTransfer size={20} />
                                </div>
                                <input
                                    type="number"
                                    name="amount"
                                    id="amount"
                                    value={user.amount}
                                    className="py-2 text-sm text-white bg-transparent placeholder-gray-500 focus:outline-none border-b-2 border-gray-400 focus:border-[#f36c21] w-full"
                                    placeholder="Enter Amount"
                                    onChange={handleOnChange}
                                />
                            </div>
                            {errors.amount && <div className="text-red-500 text-sm mb-1">{errors.amount}</div>}

                            <div className="flex items-center gap-3 py-2 px-1 ">
                                <div className="py-2.5 text-white">
                                    <IoMdLock size={20} />
                                </div>
                                <input
                                    type="text"
                                    name="password"
                                    id="password"
                                    value={user.password}
                                    className="py-2.5 text-sm text-white bg-transparent placeholder-gray-500 focus:outline-none border-b-2 border-gray-400 focus:border-[#f36c21] w-full"
                                    placeholder="Your Password"
                                    onChange={handleOnChange}
                                />
                            </div>


                            <button
                                className={`w-full ${(userData?.amount && parseFloat(userData?.amount) <= Balance) ? "bg-[#f36c21] text-white" : "bg-gray-500 text-white"} font-bold py-2 px-4 rounded-full mt-4`}
                                type="submit"
                                onClick={handleOnSubmit}
                                disabled={parseFloat(userData?.amount) > Balance}
                            >
                                Transfer
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </main>
    );
}
