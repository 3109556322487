import React from "react";
import businessLogo from '../../Assest/images/business-logo.png'
import { FooterNavCompany, FooterNavGame, FooterNavResources, FooterNavFaq, FooterNavSocial } from './footerConstent'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useTranslation } from "react-i18next";
import {websiteName} from "../../globleConstent/membership/membership"
import gC from "../../Assest/images/gc.png"

const Footer = () => {
    const { t } = useTranslation()
    const [openIndex, setOpenIndex] = React.useState(null);
    const toggleSattaMatka = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    return (
    <>
    <footer className="">
        <div className=" w-full bg-[#272727] lg:py-4 py-0 px-5">
            <div className="xl:w-9/12 w-full flex justify-center lg:items-center mx-auto lg:px-6">
                <div className=" grid grid-cols-2 gap-3 md:grid-cols-4 lg:grid-cols-12">
                    <div className="w-full lg:py-12 py-2 lg:col-span-3   col-span-2 md:col-span-4 lg:border-b-0 border-b border-b-gray-700 ">
                        <span className=" ">
                            <img src={businessLogo} alt="footer-log" className="lg:h-14 w-30 h-9" />
                            <div className="py-2">
                                <span className="text-white lg:text-xs text-[10px]">
                                    © Established in 2019, {websiteName} is the most trusted betting exchange and leading online casino.
                                </span>
                            </div>
                        </span>
                    </div>
                    <div className="lg:py-6 py-4 lg:col-span-2 w-full ">

                        <span className="lg:text-2xl lg:pl-0  text:lg font-500 text-white ">GAME</span>
                        <div className="lg:pl-0 ">
                            {FooterNavGame.map((item, index) => (
                                <div key={index} className="lg:py-1 py-1 text-[13px] italic text-white">
                                    {item.text &&
                                        // <a href={item.link} className="hover:text-[rgb(243,107,33)] lg:text-sm text-xs">{t(`footergame.${item.text.toLowerCase()}`)}</a>
                                        <a href={item.link} className="hover:text-[rgb(243,107,33)] lg:text-sm text-xs">{item.text}</a>
                                    }
                                    {item.SattaMatka && (
                                        <div>

                                            <span className="flex gap-2 items-center cursor-pointer "> 
                                                {/* {t(`footergame.${'satta matka'}`)} */}
                                                satta matka
                                                <span onClick={() => toggleSattaMatka(index)}>
                                                    {openIndex === index ? (
                                                        <IoIosArrowUp size={12} className="" />
                                                    ) : (
                                                        <IoIosArrowDown size={12} className="" />
                                                    )}
                                                </span>
                                            </span>
                                            {openIndex === index && (


                                                <ul className="text-sm">
                                                    {item.SattaMatka.map((subItem, subIndex) => (
                                                        <li key={subIndex} className="lg:pl-7 pl-4 py-0.5 hover:text-[rgb(243,107,33)]">
                                                            {/* <a href={subItem.link}>{t(`footergame.${subItem.text.toLowerCase()}`)}</a> */}
                                                            <a href={subItem.link}> {subItem.text}</a>
                                                        </li>
                                                    ))}
                                                </ul>

                                            )}
                                        </div>
                                    )}
                                    {item.OnlineBetting && (
                                        <div className="">
                                            <span className="flex gap-2 items-center cursor-pointer ">
                                                
                                                {/* {t(`footergame.${'online betting'}`)} */}
                                                online betting
                                                <span onClick={() => toggleSattaMatka(index)}>
                                                    {openIndex === index ? (
                                                        <IoIosArrowUp size={12} className="" />
                                                    ) : (
                                                        <IoIosArrowDown size={12} className="" />
                                                    )}
                                                </span>
                                            </span>
                                            {openIndex === index && (
                                                <ul>
                                                    {item.OnlineBetting.map((subItem, subIndex) => (
                                                        <li key={subIndex} className="lg:pl-7 pl-4 hover:text-[rgb(243,107,33)] py-0.5">
                                                            {/* <a href={subItem.link}>{t(`footergame.${subItem.text.toLowerCase()}`)}</a> */}
                                                            <a href={subItem.link}>{subItem.text}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    )}

                                    {item.esports && (
                                        <div>
                                            <span className="flex gap-2 items-center cursor-pointer">
                                            esports
                                                {/* {t(`footergame.${'esports'}`)} */}
                                                <span onClick={() => toggleSattaMatka(index)}>
                                                    {openIndex === index ? (
                                                        <IoIosArrowUp size={12} className="" />
                                                    ) : (
                                                        <IoIosArrowDown size={12} className="" />
                                                    )}
                                                </span>
                                            </span>
                                            {openIndex === index && (
                                                <ul>
                                                    {item.esports.map((subItem, subIndex) => (
                                                        <li key={subIndex} className="lg:pl-7 pl-4 py-0.5 hover:text-[rgb(243,107,33)]">
                                                            {/* <a href={subItem.link}>{t(`footergame.${subItem.text.toLowerCase()}`)}</a> */}
                                                            <a href={subItem.link}>{subItem.text}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    )}
                                    <div>
                                        {item.liveCards && (
                                            <div>
                                                <span
                                                    className="flex gap-2 items-center cursor-pointer "

                                                >live cards
                                                    {/* {t(`footergame.${'live cards'}`)} */}
                                                    
                                                    
                                                    <span onClick={() => toggleSattaMatka(index)}>
                                                        {openIndex === index ? (
                                                            <IoIosArrowUp size={12} className="" />
                                                        ) : (
                                                            <IoIosArrowDown size={12} className="" />
                                                        )}
                                                    </span>
                                                </span>
                                                {openIndex === index && (
                                                    <ul>
                                                        {item.liveCards.map((subItem, subIndex) => (
                                                            <li key={subIndex} className="lg:pl-7 pl-4 hover:text-[rgb(243,107,33)] py-0.5">
                                                                {/* <a href={subItem.link}>{t(`footergame.${subItem.text.toLowerCase()}`)}</a> */}
                                                                <a href={subItem.link}>{subItem.text}</a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                </div>
                            ))}
                        </div>

                    </div>
                    <div className="lg:py-6 py-4 pl-4 lg:col-span-2 w-full">
                        {/* <span>3</span> */}
                        <span className="lg:text-2xl lg:pl-0 pl-4 text:lg font-500 text-white">Company</span>
                        <div className="lg:pl-0 pl-4">
                            <ul>
                                {FooterNavCompany.map((com, index) => (
                                    <li className="py-1 text-[13px] italic text-white lg:text-sm text-xs" key={index}>
                                        {com.text &&
                                            <a href={com.link}>
                                                {com.text}
                                                {/* {com.text} */}
                                                </a>
                                        }
                                    </li>

                                ))}
                            </ul>
                        </div>

                    </div>
                    <div className="lg:py-6 py-2 lg:col-span-2 w-full">
                        {/* <span>4</span> */}
                        <span className="lg:text-2xl lg:pl-0  text:lg font-500 text-white">Resources</span>
                        <div className="lg:pl-0 ">
                            <ul>
                                {FooterNavResources.map((res, index) => (
                                    <li className="py-1 text-[13px] italic text-white lg:text-sm text-xs" key={index}>
                                        {res.text &&
                                            <a href={res.link}>
                                                {/* {t(`footerresources.${res.text.toLowerCase()}`)} */}
                                                {res.text}

                                                </a>
                                        }
                                    </li>

                                ))}
                            </ul>
                        </div>

                    </div>
                    <div className=" lg:py-6 py-8 lg:pt-12 pt-4 lg:pl-0 pl-4 lg:col-span-1 w-full">
                        {/* <span>5</span> */}
                        <div className="relative ">
                            <ul className="absolute lg:inset-x-0  inset-x-10">
                                {FooterNavFaq.map((res, index) => (
                                    <li className="py-1 text-[13px] italic text-white lg:text-sm text-xs" key={index}>
                                        {res.text &&
                                            <a href={res.link}>
                                                {/* {t(`footernavfaq.${res.text.toLowerCase()}`)} */}
                                               {res.text}
                                                </a>
                                        }
                                    </li>

                                ))}
                            </ul>
                        </div>


                    </div>

                    <div className="lg:py-6 py-4 w-full lg:col-span-2 lg:pl-4  col-span-2 md:col-span-4 lg:border-b-0 border-b border-b-gray-700 ">
                        {/* social icon */}

                        <div className="lg:grid lg:grid-cols-3  lg:gap-0 flex sm:justify-start justify-center gap-3 pt-8">
                            {FooterNavSocial.map((item, index) => (
                                <div className="py-2 w-10" key={index}>
                                    <a href={item.link} target="_blank" className="">
                                        <img src={item.icon} alt={item.alt} className="h-10 w-10" />
                                    </a>
                                </div>
                            ))}
                        </div>


                    </div>
                    {/* <span className="lg:col-span-12 border-b "></span> */}

                    <div className="w-full py-4 lg:col-span-2 ">
                        <span>
                            <img src={gC} className="h-14 w-25" />
                        </span>
                    </div>
                    <div className="lg:col-span-10 py-4 w-full lg:block hidden">
                        <div className="text-sm text-white ">Disclaimer : </div>
                        <span className="text-[12px] text-[#fff]">
                            Please note that Gaming involves a financial risk and could be addictive over time if not practised within limits. Only 18+ people should use the services and should use it responsibly. Players should be aware of any financial risk and govern themselves accordingly.
                        </span>

                    </div>
                    <div className="  lg:hidden  text-white p-6">
                        <span className="rounded-full flex items-center justify-center bg-[rgb(243,107,33)] h-10 w-10">+18</span>
                    </div>                         
                    <div className="w-full py-6  lg:col-span-11  col-span-2 md:col-span-4 lg:border-t-0 border-t border-t-gray-700 ">
                        <div>
                            <span className="text-sm text-white">© 2024 &nbsp;
                                {websiteName}. All Rights Reserved.</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
            </footer>
        </>
    )
}

export default Footer;