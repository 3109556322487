
import Inplay from "../../../../Assest/images/SubHeaderImage/Inplay.webp"
import Cricket from "../../../../Assest/images/SubHeaderImage/Cricket.webp"
import Soccer from "../../../../Assest/images/SubHeaderImage/Soccer.webp"
import Tennis from "../../../../Assest/images/SubHeaderImage/Tennis.webp"
import PremiumNotebook from "../../../../Assest/images/SubHeaderImage/Premium-Notebook.webp";
import LiveCasino from "../../../../Assest/images/SubHeaderImage/Live-Casino.webp";
import Election from "../../../../Assest/images/SubHeaderImage/Election.webp";
import LiveCard from "../../../../Assest/images/SubHeaderImage/Live-Card.webp";
import SlotGame from "../../../../Assest/images/SubHeaderImage/Slot-game.webp";
import More from "../../../../Assest/images/SubHeaderImage/More.png";
import SportsBook from "../../../../Assest/images/SubHeaderImage/Sportsbook.webp";
import aVIATOR from "../../../../Assest/images/aVIATOR.png"
import rgCasino from '../../../../Assest/images/RG ICON.png'
import Qtech from '../../../../Assest/images/Qtech.png'
import Matka from '../../../../Assest/images/matka.png'


const matchList = JSON.parse(localStorage.getItem("MatchListData"));
const filteredData = matchList?.sportlist?.data ? matchList?.sportlist?.data?.filter((item) => item.sportId == 999) : [];
const domainSetting = JSON.parse(localStorage.getItem('clientdomainSetting'));
let sportSetting = domainSetting?.sportsSetting


export const SubHeaderMenu = [
  {
    icon: Inplay,
    text: 'Home',
    link: '/',
    isVisible: true
  },
  {
    icon: Cricket,
    text: 'Cricket',
    link: '/login',
    count: "4",
    isVisible: sportSetting?.isCricket ? sportSetting?.isCricket : true,
  },
  {
    icon: Soccer,
    text: 'Soccer',
    link: '/login',
    count: "1",
    isVisible: sportSetting?.isSoccer ? sportSetting?.isSoccer : true,
  },
  {
    icon: Tennis,
    text: 'Tennis',
    link: '/login',
    count: "2",
    isVisible: sportSetting?.isTennis ? sportSetting?.isTennis : true,
  },
  {
    icon: Election,
    text: 'Election',
    link: `/viewMatch/${filteredData[0]?.marketId}/${filteredData[0]?.eventId}`, 
    count: "999",
    isVisible: sportSetting?.isPolitics ? sportSetting?.isPolitics : true
  },
  {
    icon: LiveCasino,
    text: 'Int Casino',
    link: '/login',
    isVisible: sportSetting?.isIntCasino ? sportSetting?.isIntCasino : true
  },
  {
    icon: LiveCard,
    text: 'Diamond',
    link: '/login',
    isVisible: sportSetting?.isDiamondCasino ? sportSetting?.isDiamondCasino : true
  },
  {
    icon: LiveCard,
    text: 'Live Card',
    link: '/login',
    isVisible:  true
  },
  {
    icon: SlotGame,
    text: 'Slot Games',
    link: '/login',
    isVisible: true
  },

  {
    icon: Matka,
    text: 'Matka',
    link: '/login',
    sportId: "",
    isVisible: sportSetting?.isMatka ? sportSetting?.isMatka : true
  },

];


export const SubHeaderMobile = [
  {
    icon: Inplay,
    text: 'Home',
    link: '/',
    isVisible: true
  },
  {
    icon: Cricket,
    text: 'Cricket',
    link: '/login',
    count: "4",
    isVisible: sportSetting?.isCricket ? sportSetting?.isCricket : true,
  },
  {
    icon: Soccer,
    text: 'Football',
    link: '/login',
    count: "1",
    isVisible: sportSetting?.isSoccer ? sportSetting?.isSoccer : true,
  },
  {
    icon: Tennis,
    text: 'Tennis',
    link: '/login',
    count: "2",
    isVisible: sportSetting?.isTennis ? sportSetting?.isTennis : true,
  },
  {
    icon: Election,
    text: 'Election',
    link: '/login',
    count: "999",
    isVisible: sportSetting?.isPolitics ? sportSetting?.isPolitics : true
  },
  // {
  //   icon: LiveCasino,
  //   text: 'Casino',
  //   link: '/login',
  //   count: "",
  // },

  // {
  //   icon: aVIATOR,
  //   text: 'QTeck',
  //   link: '/login',
  //   count: "",
  // },

]

export const SubHeaderMobile2 = [
  
  {
    icon: LiveCard,
    text: 'Diamond',
    link: '/login',
    isVisible: sportSetting?.isDiamondCasino ? sportSetting?.isDiamondCasino : true
  },
  {
    icon: LiveCasino,
    text: 'Int Casino',
    link: '/login',
    count: "",
    isVisible: sportSetting?.isIntCasino ? sportSetting?.isIntCasino : true
  },

  {
    icon: aVIATOR,
    text: 'Aviator',
    link: '/login',
    count: "",
    isVisible: sportSetting?.isIntCasino ? sportSetting?.isIntCasino : true
  },
  {
    icon: Qtech,
    text: 'Qtech',
    link: '/login',
    count: "",
    isVisible: sportSetting?.isQteck ? sportSetting?.isQteck : true
  },
  {
    icon: Matka,
    text: 'Matka',
    link: '/login',
    count: "",
    isVisible: sportSetting?.isMatka ? sportSetting?.isMatka : true
  },

]