import React, { useState } from 'react';
import livecardimg from "../../../Assest/images/FooterCards/Live-Cards.928ca44.webp"
import crashGamesimg from "../../../Assest/images/FooterCards/fundlist.3fc3ffb.webp"
import aVIATOR from "../../../Assest/images/aVIATOR.png"
import LiveCard from "../../../Assest/images/SubHeaderImage/Live-Card.webp";

import { useNavigate } from 'react-router-dom';
function MobileFooter() {
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const handalClickNavigate = (url) => {
        navigate('/app/iframe-casino/' + url)
    }
    const qtechNavigate = (url) => {
        navigate('/app/iframe-qtech-casino/' + url)
    }
    const casinoNavigate = (url) => {
        navigate('/app/livecasino/page/' + url)
    }

    const liveCardNavigate = (url) => {
        navigate(url)
    }


    return (
        <div className="fixed bottom-0 left-0 right-0 bg-black text-white lg:hidden block  ">
            <div className="relative">
                <div className={`bg-gradient-to-t from-gray-800 rounded-t-md to-gray-600 py-1`}>
                    <div className="flex justify-center items-center divide-x">
                        <div className="flex flex-1 justify-center items-center w-full">
                            <div onClick={() => handalClickNavigate(201206)}>
                                <span className='flex justify-center w-full item-center'>
                                    <img src={aVIATOR} className="text-center h-5 w-5" /></span>
                                <span className='text-sm font-normal '>Aviator</span>
                            </div>
                        </div>
                        <div className="flex flex-1 justify-center items-center w-full">
                            <div onClick={() => casinoNavigate(111)}>
                                <span className='flex justify-center w-full item-center'>
                                    <img src={LiveCard} className="text-center h-5 w-5" /></span>
                                <span className='text-sm font-normal inplay-blink'>Casino</span>
                            </div>
                        </div>
                        <div className="flex flex-1 justify-center items-center w-full">
                            <div onClick={() => qtechNavigate("SPB-aviator")}>

                                <span className='flex justify-center w-full item-center'>
                                    <img src={aVIATOR} className="text-center h-5 w-5" /></span>
                                <span className='text-sm font-normal '>QTech</span>
                            </div>
                        </div>
                        <div className="flex flex-1 justify-center items-center w-full">
                            <div onClick={() => liveCardNavigate("/app/livecard/page/" + 7)}>
                                <span className='flex justify-center w-full item-center'>
                                    <img src={livecardimg} className="text-center h-5 w-5" /></span>
                                <span className='text-sm font-normal'>Live Card</span>
                            </div>
                        </div>

                        <div className="flex flex-1 justify-center items-center w-full">
                            <div onClick={() => liveCardNavigate("/app/slotgames/page/" + 8)}>
                                <span className='flex justify-center w-full item-center'>
                                    <img src={crashGamesimg} className="text-center h-5 w-5" /></span>
                                <span className='text-sm font-normal'>Solt Game</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileFooter;
