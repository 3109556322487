// import React from "react";
// import { SportlistCostent } from './sportlistConstent'
// import { Link, useNavigate } from "react-router-dom";
// import Rg_icon from "../../Assest/images/RG ICON.png";
// import SEXY_ICON from "../../Assest/images/AE SEXY ICON.png"
// import LUDO from "../../Assest/images/LUDO ICON.png"
// import aVIATOR from "../../Assest/images/aVIATOR.png"
// import ezugI from "../../Assest/images/ezugI.png"


// const SportList = () => {
//     const navigate = useNavigate();

//     const onClickMenu = (data) => {
//         navigate('/app/iframe-casino/' + data)

//     }

//     const onClickMenu2 = (data) => {
//         navigate('/app/sportsbook/page/' + data)

//     }

//     return (
//         <>
//             <div>
//                 <div className="w-full bg-black flex justify-center">
//                     <div className="w-11/12 border-2 border-[#f36c21]  rounded-3xl">
//                         <div className=" ">
//                             <div className="py-4 items-center ">
//                                 {SportlistCostent.map((sport, index) => (

//                                     <div key={index} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]" onClick={() => onClickMenu2(sport.link)}>

//                                         <div className="py-1 flex gap-2 items-center">
//                                             <span className="">
//                                                 <img src={sport.icon} alt={sport.title} className="h-6 w-6" />
//                                             </span>
//                                             <span className="text-sm text-white group-hover:text-[#f36c21]">{sport.text}</span>
//                                         </div>
//                                         <div className="flex items-center">
//                                             {sport.count && (
//                                                 <div className="h-5 w-5 bg-[#f36c21] rounded-full flex justify-center">
//                                                     <span className="text-sm text-white flex items-center">{sport.count}</span>
//                                                 </div>
//                                             )}
//                                         </div>

//                                     </div>

//                                 ))}


//                                 <div onClick={() => onClickMenu(900000)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

//                                     <div className="py-1 flex gap-2 items-center">
//                                         <span className="">
//                                             <img src={Rg_icon} alt="rg_icon" className="h-6 w-6" />
//                                         </span>
//                                         <span className="text-sm text-white group-hover:text-[#f36c21]">RG Casino</span>
//                                     </div>
//                                 </div>
//                                 <div onClick={() => onClickMenu(201153)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

//                                     <div className="py-1 flex gap-2 items-center">
//                                         <span className="">
//                                             <img src={ezugI} alt="ezugI" className="h-6 w-6" />
//                                         </span>
//                                         <span className="text-sm text-white group-hover:text-[#f36c21]">Ezugi</span>
//                                     </div>


//                                 </div>
//                                 <div onClick={() => onClickMenu(100000)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

//                                     <div className="py-1 flex gap-2 items-center">
//                                         <span className="">
//                                             <img src={SEXY_ICON} alt="SEXY_ICON" className="h-6 w-6" />
//                                         </span>
//                                         <span className="text-sm text-white group-hover:text-[#f36c21]">Wheel</span>
//                                     </div>


//                                 </div>
//                                 <div onClick={() => onClickMenu(600113)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

//                                     <div className="py-1 flex gap-2 items-center">
//                                         <span className="">
//                                             <img src={LUDO} alt="ludo" className="h-6 w-6" />
//                                         </span>
//                                         <span className="text-sm text-white group-hover:text-[#f36c21]">Ludo</span>
//                                     </div>


//                                 </div>
//                                 <div onClick={() => onClickMenu(201206)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

//                                     <div className="py-1 flex gap-2 items-center">
//                                         <span className="">
//                                             <img src={aVIATOR} alt="aVIATOR" className="h-6 w-6" />
//                                         </span>
//                                         <span className="text-sm text-white group-hover:text-[#f36c21]">Aviato</span>
//                                     </div>


//                                 </div>
//                                 <div onClick={() => onClickMenu(500001)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

//                                     <div className="py-1 flex gap-2 items-center">
//                                         <span className="">
//                                             <img src={SEXY_ICON} alt="SEXY_ICON" className="h-6 w-6" />
//                                         </span>
//                                         <span className="text-sm text-white group-hover:text-[#f36c21]">Supernova</span>
//                                     </div>


//                                 </div>
//                                 <div onClick={() => onClickMenu(400000)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

//                                     <div className="py-1 flex gap-2 items-center">
//                                         <span className="">
//                                             <img src={SEXY_ICON} alt="SEXY_ICON" className="h-6 w-6" />
//                                         </span>
//                                         <span className="text-sm text-white group-hover:text-[#f36c21]">AE Sexy</span>
//                                     </div>


//                                 </div>

//                                 <div onClick={() => onClickMenu(600000)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

//                                     <div className="py-1 flex gap-2 items-center">
//                                         <span className="">
//                                             <img src={SEXY_ICON} alt="SEXY_ICON" className="h-6 w-6" />
//                                         </span>
//                                         <span className="text-sm text-white group-hover:text-[#f36c21]">Jilli</span>
//                                     </div>


//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }


// export default SportList;



import React, { useEffect, useState } from "react";
import { SportlistCostent } from './sportlistConstent'
import { Link, useNavigate } from "react-router-dom";
import Rg_icon from "../../../Assest/images/RG ICON.png";
import SEXY_ICON from "../../../Assest/images/AE SEXY ICON.png"
import LUDO from "../../../Assest/images/LUDO ICON.png"
import aVIATOR from "../../../Assest/images/aVIATOR.png"
import ezugI from "../../../Assest/images/ezugI.png";
import { useDispatch, useSelector } from "react-redux";
import Qtech from '../../../Assest/images/Qtech.png';
// import { userAction } from "../../../../redux/action/userAction";

const SportList = () => {
    const navigate = useNavigate();
    const onClickMenu = (data) => {
        navigate("/login")
    }
    const onClickMenu2 = (data) => {
        navigate('login')

    }

    const { sportmatchlist } = useSelector((state) => state.authentication);
    const [sportIdsWithLength, setSportIdsWithLength] = useState([]);

    const localMatchlist = JSON.parse(localStorage.getItem('MatchListData'));
    const domainSetting = JSON.parse(localStorage.getItem('clientdomainSetting'));
    let sportSetting = domainSetting?.sportsSetting?.isIntCasino ? domainSetting?.sportsSetting?.isIntCasino : true



    useEffect(() => {
        const MatchListData = sportmatchlist ? sportmatchlist : localMatchlist?.sportlist;
        if (MatchListData && MatchListData?.data) {
            const filteredDataBySportId = {};
            MatchListData?.data?.forEach(item => {
                const sportId = item.sportId;
                if (!filteredDataBySportId[sportId]) {
                    filteredDataBySportId[sportId] = [];
                }
                filteredDataBySportId[sportId].push(item);
            });
            const sportIdsLength = Object.keys(filteredDataBySportId).map(sportId => ({
                sportId,
                length: filteredDataBySportId[sportId].length
            }));
            setSportIdsWithLength(sportIdsLength);
        }
    }, [sportmatchlist]);



    return (
        <>
            <div>
                <div className="w-full bg-black flex justify-center">
                    <div className="w-11/12 border-2 border-[#f36c21]  rounded-3xl">
                        <div className=" ">
                            <div className="py-4 items-center ">
                                {/* {SportlistCostent.map((sport, index) => (
                                    <div key={index} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]" onClick={() => onClickMenu2(sport.link)}>

                                        <div className="py-1 flex gap-2 items-center">
                                            <span className="">
                                                <img src={sport.icon} alt={sport.title} className="h-6 w-6" />
                                            </span>
                                            <span className="text-sm text-white group-hover:text-[#f36c21]">{sport.text}</span>
                                        </div>
                                        <div className="flex items-center">
                                            {sport.count && (
                                                <div className="h-5 w-5 bg-[#f36c21] rounded-full flex justify-center">
                                                    <span className="text-sm text-white flex items-center">{sport.count}</span>
                                                </div>
                                            )}
                                        </div>

                                    </div>

                                ))} */}

                                {SportlistCostent?.filter(items => items.isVisible === true)?.map((sport, index) => (
                                    <div key={index} className="transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]" onClick={() => onClickMenu2(sport.link)}>

                                        <div className="py-1 flex gap-2 items-center">
                                            <span>
                                                <img src={sport.icon} alt={sport.title} className="h-6 w-6" />
                                            </span>
                                            <span className="text-sm text-white group-hover:text-[#f36c21]">{sport.text}</span>
                                        </div>
                                        <div className="flex items-center">
                                            {sportIdsWithLength.map((count, index) => {
                                                if (sport.count == count.sportId) {
                                                    return <div className="h-6 w-6 bg-[#f36c21] rounded-full flex justify-center items-center" key={index}>
                                                        <span className="text-xs font-bold text-white flex justify-center items-center">{count.length}</span>
                                                    </div>
                                                }
                                                return null;
                                            })}
                                        </div>
                                    </div>
                                ))}

                                {sportSetting && (
                                <>



                                    <div onClick={() => onClickMenu(900000)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">
                                        <div className="py-1 flex gap-2 items-center">
                                            <span className="">
                                                <img src={Rg_icon} alt="rg_icon" className="h-6 w-6" />
                                            </span>
                                            <span className="text-sm text-white group-hover:text-[#f36c21]">RG Casino</span>
                                        </div>
                                    </div>
                                    <div onClick={() => onClickMenu(100001)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                        <div className="py-1 flex gap-2 items-center">
                                            <span className="">
                                                <img src={ezugI} alt="ezugI" className="h-6 w-6" />
                                            </span>
                                            <span className="text-sm text-white group-hover:text-[#f36c21]">Ezugi</span>
                                        </div>

                                    </div>
                                    <div onClick={() => onClickMenu(100000)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">
                                        <div className="py-1 flex gap-2 items-center">
                                            <span className="">
                                                <img src={SEXY_ICON} alt="SEXY_ICON" className="h-6 w-6" />
                                            </span>
                                            <span className="text-sm text-white group-hover:text-[#f36c21]">Wheel</span>
                                        </div>


                                    </div>
                                    <div onClick={() => onClickMenu(600113)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                        <div className="py-1 flex gap-2 items-center">
                                            <span className="">
                                                <img src={LUDO} alt="ludo" className="h-6 w-6" />
                                            </span>
                                            <span className="text-sm text-white group-hover:text-[#f36c21]">Ludo</span>
                                        </div>


                                    </div>
                                    <div onClick={() => onClickMenu(201206)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                        <div className="py-1 flex gap-2 items-center">
                                            <span className="">
                                                <img src={aVIATOR} alt="aVIATOR" className="h-6 w-6" />
                                            </span>
                                            <span className="text-sm text-white group-hover:text-[#f36c21]">Aviator</span>
                                        </div>


                                    </div>

                                    <div onClick={() => onClickMenu("SPB-aviator")} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                        <div className="py-1 flex gap-2 items-center">
                                            <span className="">
                                                <img src={Qtech} alt="aVIATOR1" className="h-6 w-6" />
                                            </span>
                                            <span className="text-sm text-white group-hover:text-[#f36c21]">QTech</span>
                                        </div>


                                    </div>
                                    <div onClick={() => onClickMenu(500001)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                        <div className="py-1 flex gap-2 items-center">
                                            <span className="">
                                                <img src={SEXY_ICON} alt="SEXY_ICON" className="h-6 w-6" />
                                            </span>
                                            <span className="text-sm text-white group-hover:text-[#f36c21]">Supernova</span>
                                        </div>


                                    </div>
                                    <div onClick={() => onClickMenu(400000)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                        <div className="py-1 flex gap-2 items-center">
                                            <span className="">
                                                <img src={SEXY_ICON} alt="SEXY_ICON" className="h-6 w-6" />
                                            </span>
                                            <span className="text-sm text-white group-hover:text-[#f36c21]">AE Sexy</span>
                                        </div>


                                    </div>
                                    <div onClick={() => onClickMenu(600000)} className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">

                                        <div className="py-1 flex gap-2 items-center">
                                            <span className="">
                                                <img src={SEXY_ICON} alt="SEXY_ICON" className="h-6 w-6" />
                                            </span>
                                            <span className="text-sm text-white group-hover:text-[#f36c21]">Jilli</span>
                                        </div>


                                    </div>
                                    
                                </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default SportList;
