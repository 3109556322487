
export const memberShip = [
  {
    title: 'Sliver',
    amount: '₹1K',
    benefitTitle: 'Benefit',
    benefits: [
      '3% Bonus on every Deposit',
      '1% Weekly Lossback bonus for Live Casino and Live Cards',
      '1% Weekly Lossback bonus for Sports Exch and SportsBook',
    ],
    note: '(No turnover conditions, direct bonus in your wallet)',
    backgroundColor: 'from-gray-200 via-gray-100 to-gray-50',
  },
  {
    title: 'Gold',
    amount: '₹10L',
    benefitTitle: 'Benefit',
    benefits: [
      '3% Bonus on every Deposit',
      '1% Weekly Lossback bonus for Live Casino and Live Cards',
      '1% Weekly Lossback bonus for Sports Exch and SportsBook',
    ],
    note: '(No turnover conditions, direct bonus in your wallet)',
    backgroundColor: 'from-yellow-500 via-yellow-300 to-yellow-200',
  },
  {
    title: 'Platinum',
    amount: '₹1Cr',
    benefitTitle: 'Benefit',
    benefits: [
      '3% Bonus on every Deposit',
      '1% Weekly Lossback bonus for Live Casino and Live Cards',
      '1% Weekly Lossback bonus for Sports Exch and SportsBook',
    ],
    note: '(No turnover conditions, direct bonus in your wallet)',
    backgroundColor: 'from-gray-300 via-gray-400 to-gray-200',
  },

];

export const websiteName = "DollarBet";
export const B2C = true;
export const socketDomain = 'dollarbet999.com';
export const encruptedDataFlag = true;
export const decryptedDataFlag = false;



