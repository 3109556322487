import React, { useState, useEffect } from "react";
import businessLogo from "../../../Assest/images/business-logo.png";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { RiMenu2Fill } from "react-icons/ri";
import SubHeader from "./Subheader/SubHeader";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { headerIcon } from "../../Header/headerconstent";
import { userAction } from "../../../redux/action/userAction";
import { MarqueeNotifection } from "../../marquee/marquee";
import MoreGame from "../../moregame/moreGame";
import { B2C } from "../../../globleConstent/membership/membership"

export const Header = ({ setIsOpen, loggingIn, user, otpSent, users }) => {
  const [isGame, setIsGame] = React.useState(false);
  const [fieldslogin, setFieldsLogin] = useState({});
  const [errorslogin, setErrorsLogin] = useState({});
  const [loading, setLoading] = useState(false);
  const { i18n, t } = useTranslation();
  const [password, setPassword] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isGame) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isGame]);

  // useEffect(() => {
  //   const initData = localStorage.getItem("MatchListData");
  //   // const matchListFalg = localStorage.getItem("MatchListFlag");

  //   if (!initData) {
  //     dispatch(userAction.sportMatchList());
      
  //   } else {
  //     const intervalId = setInterval(() => {
  //       localStorage.removeItem("MatchListData");
      
  //     }, 2000);

  //     return () => clearInterval(intervalId);
  //   }
  // }, [dispatch]);

  useEffect(() => {
    const initData = localStorage.getItem("MatchListData");
  
    // Set an interval to remove MatchListData every 2 seconds
    const intervalId = setInterval(() => {
      localStorage.removeItem("MatchListData");
    }, 1000 * 60 * 5);
    if (!initData) {
      dispatch(userAction.sportMatchList());
    }
    return () => clearInterval(intervalId);
  }, [dispatch]);
  




  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsLogin((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
    setErrorsLogin((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    if (handleValidationLogin()) {
      setLoading(true);
      try {
        const data = {
          username: fieldslogin.username,
          password: fieldslogin.password,
          isClient: true,
          host: window.location.host,
        };

        const response = await dispatch(
          userAction.login(data, { loggingIn, user, otpSent, users })
        );

        if (
          response &&
          response.userinfo &&
          response.userinfo.data &&
          response.userinfo.data.isPasswordChanged === false
        ) {
          navigate("/app/dashboard");
          // navigate("/app/reset-password");
        } else {
          navigate("/app/dashboard");
        }
      } catch (error) {
        console.error("Login error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleValidationLogin = () => {
    let formIsValid = true;
    const newErrorsLogin = {};

    if (!fieldslogin["username"]) {
      formIsValid = false;
      newErrorsLogin["username"] = "User Name Cannot Be Blank.";
    }

    if (!fieldslogin["password"]) {
      formIsValid = false;
      newErrorsLogin["password"] = "Password Cannot Be Blank.";
    }

    setErrorsLogin(newErrorsLogin);
    return formIsValid;
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };

  const handalClickNavigate = (url) => {
    navigate(url)
  }

  let domainSetting = JSON.parse(localStorage.getItem('clientdomainSetting'));



  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      loginSubmit(e);
    }
  };



  return (
    <>
      <header>
        <MarqueeNotifection />
        <nav className=" border-gray-200 px-2 lg:px-4 pt-2.5 bg-black">
          <div className="flex justify-between items-center mx-auto ">
            <div className="flex items-center ">
              <span className="flex items-center gap-2">
                <RiMenu2Fill
                  size={25}
                  color="white"
                  onClick={() => setIsOpen(true)}
                  className=""
                />

                <img
                  src={businessLogo}
                  alt="logo"
                  title="business-log"
                  className="mr-3 h-9 sm:h-9"
                  onClick={() => handalClickNavigate("/")}
                />
              </span>

            </div>

            <div className="flex justify-end items-center  gap-4">
              <div
                class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
                id="mobile-menu-2"
              >
                <div className="  flex flex-col mt-4 font-medium lg:flex-row lg:space-x-4 lg:mt-0">
                  <div className=" g-red-800 block pt-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
                    <input
                      type="text"
                      name="username"
                      id="username"
                      value={fieldslogin.username}
                      className="outline-none py-2.5  text-sm bg-transparent text-gray-200 placeholder:text-gray-500 focus:border-[#f36c21] border-b-2  w-full lg:w-52 border-gray-400"
                      placeholder="USER NAME"
                      onChange={inputChange}
                      onKeyPress={handleKeyPress}
                      required
                    />
                    <div className="text-right text-xs pt-2 capitalize">
                      <input type="checkbox" class="rounded-full" />
                      <label className="text-[#f36c21] px-1 text-right">Remember me </label>

                    </div>
                  </div>
                  <div className="w-full  relative block py-2 pr-4  text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
                    <input
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      type={showPassword ? "text" : password}
                      name="password"
                      id="password"
                      value={fieldslogin.password}
                      className="py-2.5  text-sm text-gray-200 bg-transparent placeholder-gray-500 focus:outline-none border-b-2 border-gray-400 focus:border-[#f36c21] w-full lg:w-52"
                      placeholder="PASSWORD"
                      onChange={inputChange}
                      onKeyPress={handleKeyPress}
                    />
                    <button
                      onClick={toggleShowPassword}
                      className="absolute  top-[15%] right-0 flex items-center px-2 text-white"
                    >
                      {showPassword ? (
                        <BsFillEyeSlashFill
                          size={20}
                          className={inputFocused ? "text-[#f36c21]" : ""}
                        />
                      ) : (
                        <BsEyeFill
                          size={20}
                          className={inputFocused ? "text-[#f36c21]" : ""}
                        />
                      )}
                    </button>
                    <div className="text-right text-xs capitalize">
                      <p className="text-[#f36c21] px-1 pt-2 text-right text-xs ">forgot password ? </p>

                    </div>
                  </div>
                </div>
              </div>

              {/* <div class="lg:flex hidden justify-between items-center lg:order-2 ">
                <button
                  onClick={loginSubmit}
                  className="text-white w-[100px] [40px] bg-[#f36c21] gap-4 flex justify-center items-center  font-medium rounded-lg text-sm md:px-4 px-4 lg:px-6 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                >
                  {loading ?
                    <div class="w-6 h-6 border-8 border-dashed rounded-full animate-spin border-white"></div>
                    : "Login"}

                </button>
                {domainSetting?.isRegister === true ? (
                  <button
                    onClick={() => handalClickNavigate('/registration')}
                    className="text-white w-[100px] [40px] bg-green-400  font-medium rounded-lg text-sm px-2 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                  >
                    Ragister
                  </button>
                ) : (null)}

              </div> */}

              <div class="lg:flex hidden justify-between items-center lg:order-2 ">
                <button
                  onClick={loginSubmit}
                  className="text-white w-[100px] [40px] bg-[#f36c21] gap-4 flex justify-center items-center  font-medium rounded-lg text-sm md:px-4 px-4 lg:px-6 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                >
                  {loading ?
                    <div class="w-6 h-6 border-8 border-dashed rounded-full animate-spin border-white"></div>
                    : "Login"}

                </button>
                {B2C === true && (
                  <button
                    onClick={() => handalClickNavigate('/registration')}
                    className="text-white bg-green-400  font-medium rounded-lg text-sm px-2 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                  >
                    Register
                  </button>
                  )}

              </div>





              <div class="lg:hidden flex justify-between items-center lg:order-2">
                <button
                  onClick={() => handalClickNavigate('/login')}
                  className="text-white bg-[#f36c21] gap-4 flex justify-center items-center  font-medium rounded-lg text-sm md:px-2 px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                >
                  <span>Login</span>

                </button>
          
                {B2C === true && (
                  <button
                    onClick={() => handalClickNavigate('/registration')}
                    className="text-white bg-green-400  font-medium rounded-lg text-sm px-2 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                  >
                    Register
                  </button>
                  )}

              </div>
            </div>

            {/* <div className=" bg-red-800 flex justify-end items-center space-x-4 gap-4">

              <div
                class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
                id="mobile-menu-2"
              >
                <div className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-4 lg:mt-0">
                  <div className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
                    <input
                      type="text"
                      name="username"
                      id="username"
                      value={fieldslogin.username}
                      className="outline-none py-2.5  text-sm bg-transparent text-gray-200 placeholder:text-gray-500 focus:border-[#f36c21] border-b-2  w-full lg:w-52 border-gray-400"
                      placeholder="Email/Mobile Number"
                      onChange={inputChange}
                      required
                    />
                  </div>
                  <div className="w-full relative block py-2 pr-4  text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
                    <input
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      type={showPassword ? "text" : password}
                      name="password"
                      id="password"
                      value={fieldslogin.password}
                      className="py-2.5  text-sm text-gray-200 bg-transparent placeholder-gray-500 focus:outline-none border-b-2 border-gray-400 focus:border-[#f36c21] w-full lg:w-52"
                      placeholder="password"
                      onChange={inputChange}
                    />

                    <button
                      onClick={toggleShowPassword}
                      className="absolute inset-y-0 right-0 flex items-center px-2 text-white"
                    >
                      {showPassword ? (
                        <BsFillEyeSlashFill
                          size={20}
                          className={inputFocused ? "text-[#f36c21]" : ""}
                        />
                      ) : (
                        <BsEyeFill
                          size={20}
                          className={inputFocused ? "text-[#f36c21]" : ""}
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div class="lg:flex hidden justify-between items-center lg:order-2 ">
                <button
                  onClick={loginSubmit}
                  className="text-white bg-[#f36c21] gap-4 flex justify-center items-center  font-medium rounded-lg text-sm md:px-2 px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                >
                  <span>Login</span>

                </button>

                <button className="text-white bg-green-400  font-medium rounded-lg text-sm px-2 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                  onClick={() => handalClickNavigate("/registration")}>
                  Ragister
                </button>

              </div>
              <div class="lg:hidden flex justify-between items-center lg:order-2">

                <button
                  onClick={() => handalClickNavigate("/login")}
                  className="text-white bg-[#f36c21] gap-4 flex justify-center items-center  font-medium rounded-lg text-sm md:px-2 px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                >
                  <span>Login</span>

                </button>


                <button
                  onClick={() => handalClickNavigate("/registration")}
                  className="text-white bg-green-400  font-medium rounded-lg text-sm px-2 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                >
                  Ragister
                </button>

              </div>
            </div> */}
          </div>
        </nav>
        <SubHeader setIsGame={setIsGame} />
        {/* <MoreGame isGame={isGame} setIsGame={setIsGame} /> */}
      </header>
    </>
  );
};
