
import React, { useRef, useState, useEffect } from 'react'
import { RiInformationFill } from "react-icons/ri";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from 'react-router-dom';
import BetPlaceCounter from './BetPlaceCounter';



export const betChipsData = {
  "1000": 1000,
  "2000": 2000,
  "5000": 5000,
  "10000": 10000,
  "20000": 20000,
  "50000": 50000,
  "100000": 100000,
  "250000": 250000,
};


export function BetPlaceMatka(props) {
  let { openBets, matchName, betSlipData, placeBet, count, betLoading, increaseCount, decreaseCount } = props;

console.log(count, "count");
console.log(betSlipData, "betSlipData");

  

const betChipsLocalStorage =  localStorage.getItem("clientbetChipsData") ? JSON.parse(localStorage.getItem("clientbetChipsData"))  : betChipsData


  const myArray = Object.values(betChipsLocalStorage);
  const modalRef = useRef();
  const [positions, setPositionData] = useState(0);

  useEffect(() => {
    if (betSlipData && betSlipData.position && betSlipData.position.length > 0) {
      betSlipData && betSlipData.position.forEach((eles) => {
        if (betSlipData.selectionId == eles._id) {
          setPositionData(eles.position);
        }
      });
    }

    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // handleClose(); // Close modal when clicking outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [betSlipData]);


  const [stake, setStack] = useState(0);
  let [placeButton, setPlaceButton] = useState(false)
  const updateInputValue = (event) => {
    const newValue = parseFloat(event.target.value);
    setStack(() => {
      const newStack = !isNaN(newValue) ? (newValue >= 0 ? newValue : 0) : 0;
      betSlipData.stake = newStack;
      if (betSlipData.stake > 0) {
        setPlaceButton(true);
      }
      if (betSlipData.stake <= 0) {
        setPlaceButton(false);
      }
      updateOddsPostModal()
      return newStack;
    });
  };


  const updateFinalBalance = (amount) => setStack(prevStack => {
    const newStack = prevStack + amount
    betSlipData.stake = newStack;
    if (betSlipData.stake > 0) {
      setPlaceButton(true);
    }
    if (betSlipData.stake <= 0) {
      setPlaceButton(false);
    }
    return newStack
  });


  if (betSlipData.oddsType == "fancy") {
    // filterdata = runCount.session.filter(session => session.Selection_id == betSlipData.data.Selection_id);
  }

  if (betSlipData.oddsType == "bookmaker") {
    // filterdata = runCount.team_data.filter(session => session.selectionid == betSlipData.data.selectionid);

  }




  const arrayData = (element) => {
    if (element > 0) {
      updateFinalBalance(element);
      updateOddsPostModal()
    }
  };

  const updateOddsPostModal = async () => {

    let oddsType = betSlipData?.oddsType
    let positionArray = {}
    let positionArrayNew = {}

    

    if (oddsType == "Match Odds" || oddsType == "Tied Match") {
      betSlipData?.nameOther?.map((oddsData) => {

        if (oddsData.selectionId == betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionId] = betSlipData.stake * (betSlipData.odds - 1)
        }
        if (oddsData.selectionId == betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionId] = -1 * betSlipData.stake * (betSlipData.odds - 1)
        }
        if (oddsData.selectionId != betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionId] = -1 * betSlipData.stake
        }
        if (oddsData.selectionId != betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionId] = betSlipData.stake
        }

        let currentPos = betSlipData.position[oddsData.selectionId] ? betSlipData.position[oddsData.selectionId] : 0
        let calculatePos = positionArray[oddsData.selectionId]

        
        

        positionArray[oddsData.selectionId] = Number(calculatePos) + Number(currentPos)
        positionArrayNew[oddsData.selectionId] = Number(calculatePos)

      })
    }

    if (oddsType == "toss" || oddsType == "bookmaker") {
      betSlipData?.nameOther.map((oddsData) => {
       
        if (oddsData.selectionid == betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionid] = betSlipData.stake * (betSlipData.odds)
        }
        if (oddsData.selectionid == betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionid] = -1 * betSlipData.stake * (betSlipData.odds)
        }
        if (oddsData.selectionid != betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionid] = -1 * betSlipData.stake
        }
        if (oddsData.selectionid != betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionid] = betSlipData.stake
        }

        let currentPos = betSlipData.position[oddsData.selectionid] ? betSlipData.position[oddsData.selectionid] : 0
        let calculatePos = positionArray[oddsData.selectionid]



        positionArray[oddsData.selectionid] = Number(calculatePos) + Number(currentPos)
        positionArrayNew[oddsData.selectionid] = Number(calculatePos)

      })
    }


    betSlipData.oldPos = betSlipData.position
    betSlipData.position = positionArray

  }


  useEffect(() => {
    AOS.init();
    AOS.refresh();

  }, []);




  return (
    <section className='px-2'>
      <div ref={modalRef} className={`border-4 bg-white  rounded-lg  relative ${betSlipData.betType === "Y" || betSlipData.betType === "L" ? "border-[#A5D9FE]" : "border-[#F8D0CE]"}`}>
        <div className='py-4 px-2 '>
          <div className='text-sm font-normal space-y-1 uppercase'>
            <div>{matchName}</div>
            <div>{betSlipData.name}</div>
          </div>
          <div className='text-sm font-normal py-2 flex items-center space-x-3'>
            <div className="relative mb-2 flex-1  flex rounded border bg-transparent">
              <button className="ml-3 " onClick={decreaseCount}>-</button>
              <input
                type="text"
                className="peer block min-h-[auto] w-full text-center text-black  px-3 py-[0.32rem] leading-[1.6] outline-none focus:placeholder:opacity-100 peer-focus:text-primary"
                id="exampleFormControlInput1"
                value={count}
              />
              <label className="pointer-events-none outline-none bg-white absolute left-3 top-[-0.8rem] px-2 py-0.5 max-w-[80%] truncate text-neutral-500"> Odds </label>
              <button className="mr-3 " onClick={increaseCount}>+</button>
            </div>
            <div class="relative mb-2 flex-1">
              <input
                type="text"
                class="peer block min-h-[auto] w-full text-center text-black rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-black dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                id="exampleFormControlInput1"
                placeholder=" Min. 100 Max. 25000 "
                value={stake ? stake : ""}
                onChange={updateInputValue}
              />
              <label
                className="pointer-events-none outline-none bg-white absolute left-3 top-[-0.8rem] px-2 py-0.5 max-w-[80%] truncate text-neutral-500"
              >
                Stack
              </label>
            </div>
          </div>

          <div className=' px-2 flex justify-between items-center'>
            <span className='px-1 py-1 text-sm font-normal'>or Choose You Stake Size</span>
            <NavLink to={"/app/setting"} className='px-2 py-1 text uppercase text-sm font-normal hover:bg-[#FEF4EE] hover:rounded-md text-[#F37027]'>Edit Stake</NavLink>
          </div>

          <div className=' grid grid-cols-4 py-2 px-2 gap-2 items-center'>
            {myArray && myArray.map((element, index) => (
              <div key={index} className="flex justify-center items-center bg-[#F07934] border rounded-full" onClick={() => arrayData(element)}>
                <span className='px-4 py-1.5 text-white text-xs font-light'>{element}</span>
              </div>
            ))}
          </div>

          <div className='text-sm font-normal py-2 px-2 gap-2 flex items-center'>
            <div class="relative mb-2 flex-1 text-center px-2  py-2 rounded-full border text-[#F37027] border-[#F37027] cursor-pointer text-sm uppercase" onClick={() => openBets()}>
              <span className='py-4'>Cancel</span>
            </div>



            {placeButton == false ? <>
              <div
                class="relative mb-2 flex-1 px-2  text-center py-2 rounded-full bg-gray-300 text-gray-200 text-sm uppercase">
                <span className='py-4'>placebet</span>
              </div>
            </> :
              <div onClick={() => { placeBet() }}
                class="relative mb-2 flex-1 px-2  text-center  py-2 rounded-full bg-green-500 text-white text-sm font-bold uppercase">
                <span className='py-4'>Place Bet</span>

              </div>}


          </div>
          <div className='flex justify-between gap-x-2 items-center px-2'>
            <div className="text-sm font-bold text-gray-800  text-start">
              {Array.isArray(betSlipData.nameOther) ? betSlipData.nameOther.map((other, index) => (
                <React.Fragment key={index}>
                  <div>{other && other.selectionName ? other.selectionName : other.team_name}</div>
                </React.Fragment>
              )) : null}
            </div>
            <div>
              {Array.isArray(betSlipData?.nameOther) && betSlipData.nameOther.length <= 3 ? betSlipData.nameOther.map((other, index) => (
                <React.Fragment key={index}>
                  <div className="text-sm font-bold">
             
                    {betSlipData.oddsType == "Match Odds" || betSlipData.oddsType == "Tied Match" ?
                      <span className={`${!isNaN(betSlipData.position[other.selectionId]) && parseFloat(betSlipData.position[other.selectionId]).toFixed(2).replace(/\.?0+$/, "") < 0 ? "text-red-500" : "text-green-700"} font-bold col-12 text-center`}>
                        {(!isNaN(betSlipData.position[other.selectionId]) ? parseFloat(betSlipData.position[other.selectionId]).toFixed(2).replace(/\.?0+$/, "") : "")}
                      </span>
                      : betSlipData.oddsType == "fancy" ? (
                        null
                      ) :
                        <span className={`${!isNaN(betSlipData.position[other.selectionid]) && parseFloat(betSlipData.position[other.selectionid]).toFixed(2).replace(/\.?0+$/, "") < 0 ? "text-red-500" : "text-green-700"} font-bold col-12 text-center`}>
                          {(!isNaN(betSlipData.position[other.selectionid]) ? parseFloat(betSlipData.position[other.selectionid]).toFixed(2).replace(/\.?0+$/, "") : "")}
                        </span>
                    }
                  </div>
                </React.Fragment>
              )) :
                null
              }
            </div>
          </div>
        </div>

        {betLoading === true ? (
          <div className='border-2 bg-black opacity-80 rounded-lg h-full w-full absolute top-0 flex justify-center items-center'>
            <div role="status" className='text-red-900 font-bold text-xxl'>
              <BetPlaceCounter />
            </div>
          </div>
        ) : (
          null)}
      </div>
    </section>
  )
}
