import React, { useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { HeaderAuth } from "../Auth/Header/Header";
import Dashboard from "../../pages/Auth/Dashboard/Dashboard";
import ResetPassword from "../Auth/ResetPassword/ResetPassword";
import ChangeMobileNo from "../../pages/Auth/ChangeMobileNo/ChangeMobileNo";
import Deposit from "../../pages/Auth/Deposit/Deposit";
import Bets from "../Bets/Bets";
import Wallet from "../Auth/Wallet/Wallet";
import EditStake from "../../pages/Auth/EditStake/EditStake";
import MatchViewDeatils from "../../pages/Auth/MatchViewDetails/MatchViewDeatils";
import ProfitAndLoss from "../../pages/Auth/ProfitAndLoss/ProfitAndLoss";
import ProfitLossDetails from "../../pages/Auth/ProfitLossDetails/ProfitLossDetails";
import Profile from "../../pages/Auth/Profile/Profile";
import SidebarRight from "../../component/Auth/SidebarRight/SidebarRight";
import Withdrawal from "../../pages/Auth/Withdrawal/Withdrawal";
import SportsbookPage from "../../pages/Auth/SportsbookPage/SportsbookPage";
import LiveCasino from "../../pages/Auth/LiveCasino/LiveCasino";
import IframeCasino from "../Auth/IframeCasino/IframeCasino";
import Drawer from "../Auth/Drawer/Drawer";
import AffillatePartner from "../../pages/Auth/Affiliate/Affiliate";
import TermCondition from "../../pages/Auth/TearmCondition/TermCondition";
import Promotions from "../../pages/Auth/Promotions/Promotions";
import PrivacyPolicy from "../../pages/Auth/PrivacyPolicy/PrivacyPolicy";
import FaqsPage from "../../pages/Auth/FaqsPage/FaqsPage";
import AboutUs from "../../pages/Auth/AboutUs/AboutUs";
import Lottery from "../../pages/Auth/Lottery/Lottery";
import MobileFooter from "../Auth/MobileFooter/MobileFooter";
import Livecard from "../Auth/Livecard/Livecard";
import SlotGames from "../Auth/Slot Games/SlotGames";
import DepositWidrawal from "../../pages/Auth/DWT/DepositWidrawal";
import IframeQtech from "../Auth/IframeCasino/IframeQtech";
import AccountStatement from "../../pages/Auth/AccountStatment/AccountStatement";
import Teenpattit20 from "../../pages/Auth/DiamondCasino/Casino/Teenpattit20";
import Casino from "../../pages/Auth/DiamondCasino/AllCasino/Casino";
import Lucky7b from "../../pages/Auth/DiamondCasino/Casino/Lucky7b";
import Dragontiger20 from "../../pages/Auth/DiamondCasino/Casino/Dragontiger20";
import Amarakbaranthony from "../../pages/Auth/DiamondCasino/Casino/Amarakbaranthony";
import Worlimatka from "../../pages/Auth/DiamondCasino/Casino/Worlimatka";
import Dragontiger202 from "../../pages/Auth/DiamondCasino/Casino/Dragontiger202";
import Teenpattionday from "../../pages/Auth/DiamondCasino/Casino/Teenpattionday";
import Andarbahar2 from "../../pages/Auth/DiamondCasino/Casino/Andarbahar2";
import Teenpattittest from "../../pages/Auth/DiamondCasino/Casino/Teenpattittest";
import Cards32A from "../../pages/Auth/DiamondCasino/Casino/Cards32A";
import { BalanceProvider } from "../Auth/ContextApi";
import Cards32B from "../../pages/Auth/DiamondCasino/Casino/cards32B";
import Andarbahar from "../../pages/Auth/DiamondCasino/Casino/andarbahar";
import ReferAndEarn from "../../pages/Auth/referAndEarn/ReferAndEarn";
import MatkaList from "../../pages/Auth/Matka/MatkaList/MatkaList";
import MatkaType from "../../pages/Auth/Matka/MatkaType/MatkaType";
import SingleMatka from "../../pages/Auth/Matka/SingleMatka";
import MatkaBetList from "../../pages/Auth/Matka/MatkaBetList/MatkaBetList";
import JodiMatka from "../../pages/Auth/Matka/JodiMatka";
import SinglePattiMatka from "../../pages/Auth/Matka/SinglePattiMatka";
import DoublePattiMatka from "../../pages/Auth/Matka/DoublePattiMatka";
import TripplePattiMatka from "../../pages/Auth/Matka/TripplePattiMatka";
import OddEvenMatka from "../../pages/Auth/Matka/OddEvenMatka";
import DownlineList from "../../pages/Auth/DownlineLists/DownlineLists";



function Layout(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRightSidebar, setIsOpenRightSidebar] = useState(false);
  return (
    <section className="mainView">
      <BalanceProvider>
        <div className="bg-black h-full overflow-y-auto flex flex-col ">
          <HeaderAuth
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setIsOpenRightSidebar={setIsOpenRightSidebar}
          />
          <div className="rightView ">
            <div>
              <SidebarRight
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                isOpenRightSidebar={isOpenRightSidebar}
                setIsOpenRightSidebar={setIsOpenRightSidebar}
              />
              <Drawer isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
            <Routes>
              <Route path="/dashboard" element={<Dashboard isOpen={isOpen} />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/setting" element={<EditStake />} />
              <Route path="/change-mobileno" element={<ChangeMobileNo />} />
              <Route path="/wallet/deposit" element={<Deposit />} />
              <Route path="/bets" element={<Bets />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/profitloss" element={<ProfitAndLoss />} />
              <Route path="/account-statment" element={<AccountStatement />} />
              <Route
                path="/profitloss/details/:marketId?"
                element={<ProfitLossDetails />}
              />
              <Route path="/profile" element={<Profile />} />
              <Route path="/wallet/withdrawal" element={<Withdrawal />} />
              
              <Route path="/matka-list" element={<MatkaList />} />
              <Route path="/matka-type/:matakaName?/:matkaEvent?" element={<MatkaType />} />
              <Route path="/matka-single/:matkaEventId?" element={<SingleMatka />} />
              <Route path="/matka-jodi/:matkaEventId?" element={<JodiMatka />} />
              <Route path="/matka-single-patti/:matkaEventId?" element={<SinglePattiMatka />} />
              <Route path="/matka-double-patti/:matkaEventId?" element={<DoublePattiMatka />} />
              <Route path="/matka-tripple-patti/:matkaEventId?" element={<TripplePattiMatka />} />
              <Route path="/matka-odd-even/:matkaEventId?" element={<OddEvenMatka />} />
              <Route path="/matka-bet-list" element={<MatkaBetList />} />
              <Route path="/downline-list" element={<DownlineList />} />
    
              



              <Route
                path="/viewMatchDetail/:marketId/:eventId"
                element={<MatchViewDeatils {...props} />}
              />
              <Route
                path="/sportsbook/page/:sportId?"
                element={<SportsbookPage {...props} />}
              />
              <Route
                path="/livecasino/page/:sportId?"
                element={<LiveCasino {...props} />}
              />
              <Route path="/livecard/page/:sportId?" element={<Livecard />} />
              <Route path="/slotgames/page/:sportId?" element={<SlotGames />} />
              <Route path="/affillate-partner" element={<AffillatePartner />} />
              <Route path="/term-conditon" element={<TermCondition />} />
              <Route path="/promotions" element={<Promotions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/faqs" element={<FaqsPage />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/loyalty" element={<Lottery />} />
              <Route
                path="/deposit-widrawal-transaction"
                element={<DepositWidrawal />}
              />
              <Route path="/casino" element={<Casino />} />
              <Route path="/teen20/:eventId" element={<Teenpattit20 />} />
              <Route path="/lucky7eu/:eventId" element={<Lucky7b />} />
              <Route path="/dt20/:eventId" element={<Dragontiger20 />} />
              <Route path="/aaa/:eventId" element={<Amarakbaranthony />} />
              <Route path="/abj/:eventId" element={<Andarbahar2 />} />
              <Route path="/worli/:eventId" element={<Worlimatka />} />
              <Route path="/dt202/:eventId" element={<Dragontiger202 />} />
              <Route path="/teen/:eventId" element={<Teenpattionday />} />
              <Route path="/card32eu/:eventId?" element={<Cards32B />} />
              <Route path="/teen9/:eventId" element={<Teenpattittest />} />
              <Route path="/card32-a/:eventId" element={<Cards32A />} />
              <Route path="/reffer-and-earn" element={<ReferAndEarn />} />
            </Routes>
          </div>
        </div>
      </BalanceProvider>
      {/* <MobileFooter /> */}
    </section>
  );
}

export default Layout;
