import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { httpPost } from "../../../../middelware/Http";
import { BetPlaceMatka } from "../../../../component/Auth/BetPlace/BetPlaceMatka";
import { userAction } from "../../../../redux/action/userAction";
import BetListTableMatka from "../../../../component/Auth/MatkaBetList/BatListTableMatka";
import { Button, Col, Input, message, Row, Select, Typography } from "antd";



const OddEvenMatka = () => {
  const [selectedGame, setSelectedGame] = useState('');
  const [sattaList, setSattaList] = useState({});
  const [domainSettingByDomainName, setDomainSettingByDomainName] = useState(null);
  // const [message, setMessage] = useState("");
  const [betChipsData, setBetChipsData] = useState([]);
  const [error, setError] = useState("");
  const [betSlipData, setBetSlipData] = useState({});
  const [backBetModal, setBackBetModal] = useState(false);
  const [backBetModalMobile, setBackBetModalMobile] = useState(false);
  const [time, setTime] = useState(7);
  const [isFetch, setIsFetch] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorType, setErrorType] = useState(0);
  const [resMessage, setResMessage] = useState("");
  const [totalProfitLoss, setTotalProfitLoss] = useState(0);
  const [betList, setBetList] = useState([]);
  const [LoadingBet, setLoadingBet] = useState(false);
  const [betresponse, setBetresponse] = useState(null);
  const section1Ref = useRef();
  const scrollTimeout = useRef(null);
  const inputRef = useRef();
  const [activeTab, setActiveTab] = useState(0)
  const [showBetPlaceModal, setShowBetPlaceModal] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState("");
  const [selectedGameType, setSelectedGameType] = useState("");
  const [amount, setAmount] = useState("");

  const dispatch = useDispatch();

  const params = useParams()
  useEffect(() => {
    const marketEvent = {
      "matkaEventId": params.marketEvent
    };

    getMatkaListByEventID()
    betListFunction(marketEvent);
    betChipData();
    checkWindowWidth();
  }, [params.marketEvent]);

  const checkWindowWidth = () => {
    const isMobile = window.matchMedia('(max-width: 100vw)').matches;
    if (isMobile && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  };

  const betChipData = async () => {
    try {
      let betChipsDatas = JSON.parse(localStorage.getItem('betChipsData'));
      const myArray = betChipsDatas && Object.keys(betChipsDatas).length > 0 ? Object.values(betChipsDatas) : Object.values(betChipsData);
      setBetChipsData(myArray);
    } catch (error) {
      console.error('Error parsing JSON from localStorage: betChipsDatas', error);
      setBetChipsData(betChipsData);
    }
  };






  const handleBackOpen = (data, sectionRef) => {
    if (!selectedMarket) {
      return message.error("please select market type")
    }
    if (ismatkaList.betStatus === false) {
      return message.error('Your Bet is Closed Please Connect With Upline');
    }
    if (scrollTimeout.current) {
      clearInterval(scrollTimeout.current);
    }
    setBackBetModal(true);
    setBackBetModalMobile(true);

    setBetSlipData({ ...data, stake: "0" });
    setTime(7);
    scrollTimeout.current = setInterval(() => {
      setTime(prevTime => {
        if (prevTime <= 0) {
          clearInterval(scrollTimeout.current);
          setBackBetModal(false);
        }
        return prevTime - 1;
      });
    }, 1000);
    setTimeout(() => {
      if (sectionRef && sectionRef.current) {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        if (window.innerWidth <= 768 && inputRef.current && sectionRef && sectionRef.current) {
          sectionRef.current.scrollIntoView({ behavior: 'smooth' });
          setTimeout(() => {
            inputRef.current.focus();
          }, 0);
        }
      }
    }, 0);
  };

  const updateStake = (event) => {
    try {
      let { value } = event.target;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      setBetSlipData(prevBetSlipData => ({
        ...prevBetSlipData,
        stake: value,
      }));
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };

  const updateStackOnclick = (num) => {
    setBetSlipData(prevBetSlipData => ({
      ...prevBetSlipData,
      stake: num,
    }));
  };



  const betListFunction = async (eventId) => {
    let betReq = {
      "matkaEventId": eventId?.matkaEventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      gameType: 'oddEven'
    };

    let getCasinoDetails = await httpPost(`matka/matkaBetList`, betReq);
    let totalProfitLoss = 0;
    let betList = getCasinoDetails?.data;
    if (betList && betList.length > 0) {
      betList.map((bet) => {
        totalProfitLoss += Number(bet.profitLoss);
      });
    }
    if (getCasinoDetails) {
      setBetList(betList);
      setTotalProfitLoss(totalProfitLoss);
    }
  };

  const placeBet = async () => {
    if (!selectedMarket) {
      message.error("select market type")
    } else
      if (!selectedGameType) {
        message.error("select game type")
      } else if (!amount) {
        message.error("enter amount")
      }

    setLoadingBet(true);

    let betObject = {
      "matkaEventId": matkaEventId,
      "gameType": 'ODD_EVEN',
      "betType": selectedMarket,
      "betNumber": selectedGameType,
      "amount": amount
    };

    const result = await httpPost("matka/matkaPlaceBet", betObject);
    if (result) {
      if (!result.error) {
        setBetresponse(result.data);
        setIsFetch(true);
        // setMessage("Bet Successful");
        setError(false);
        setTimeout(() => {
          setIsFetch(false);
        }, 3000);
        betListFunction(params.eventId);
        // dispatch(userActions.getUserBalance());
      } else {
        setIsFetch(true);
        // setMessage(result.message);
        setError(true);
        setTimeout(() => {
          setIsFetch(false);
        }, 3000);
      }
    }
    handleClose();
    setLoadingBet(false);
  };

  const handleClose = () => {
    setBackBetModal(false);
    setBackBetModalMobile(false);
  };

  // const handleBetStatus = () => {
  //   NotificationManager.error('Your Bet is Closed Please Connect With Upline', "Error", 1000, () => {
  //     alert('callback');
  //   });
  // };



  const cardData = Array.from({ length: 10 }, (_, index) => index + 1);







  const handalChnage = (value) => {
    setSelectedMarket(value);
  };

  const handalChnageType = (value) => {
    if (!selectedMarket) {
      return message.error("Please select market Type");
    }
    setSelectedGameType(value);
  };

  const handleInputChange = (e) => {
    if (!selectedMarket) {
      return message.error("Please select market Type");
    } else if (!selectedGameType) {
      return message.error("Please select game Type");
    }
    setAmount(e.target.value);
  };







  const getMatkaListByEventID = async () => {

    let betReq = {
      "matkaEventId": matkaEventId,

    };

    let getMatkaDetails = await httpPost(`matka/getMatkaByMatkaEventId`, betReq);
    if (getMatkaDetails?.data) {
      setIsmatkaList(getMatkaDetails.data ? getMatkaDetails?.data : {});
    }

  }

  let { matkaEventId } = useParams()
  const { Option } = Select
  const [ismatkaList, setIsmatkaList] = useState({});

  const openBets = () => {
    setBackBetModal(false);
  };



  const showOpenBet = ismatkaList?.openBet;
  const showCloseBet = ismatkaList?.closeBet;


  return (
    <section className="px-2 ">
      <Typography style={{ color: "#26122D" }} className=" gx-fs-xxl rounded font-bold text-center uppercase py-2 bg-orange-500 mb-2">{ismatkaList?.name ? ismatkaList?.name : ''}-ODDS-EVEN</Typography>
      <Row justify={"center"}>

        <Col className="flex justify-center mt-4 w-full" >

          <Select size="large" placeholder="Market Type" onChange={handalChnage} style={{ width: 300 }}>
            {showOpenBet && <Option value="OPEN">Open Market</Option>}
            {showCloseBet && <Option value="CLOSE">Close Market</Option>}
          </Select>

        </Col>
      </Row>
      <Row justify={"center"} className="flex space-x-2 mt-4">
        <Col xs={10} sm={8} md={4} lg={4} className=" flex flex-col">
          <label className="text-white minMax  text-md font-bold gx-py-1">Game Type</label>
          <Select onChange={handalChnageType} className="gx-w-100" size="large" placeholder="Select Even/ Odd" >
            <Option value="ODD">ODD</Option>
            <Option value="EVEN">EVEN</Option>
          </Select>
        </Col>
        <Col xs={10} sm={8} md={4} lg={4} className="">
          <label className="text-white minMax  text-md font-bold py-1">Amount</label>
          <Input type="number" size="large" className="gx-rounded-0 gx-w-100" value={amount} onChange={handleInputChange} />
        </Col>
      </Row>



      <Row justify="center" className="mt-3">
        <Col xs={24} sm={12} md={5} className="flex justify-center ">
          <Button size="large" style={{ paddingInline: "50px" }} className="bg-green-800 w-1/2  text-white fontbold " onClick={placeBet}>
            Submit
          </Button>
        </Col>
      </Row>





      <Row justify={"space-between"} className="mt-2 text-white">

        <Col className="flex justify-start " xs={12} sm={8} md={8} lg={4}>
          <div className="text-md gx-bold ">Open Time:
            <span className="text-blue-500 px-1">{ismatkaList?.openTime ? ismatkaList?.openTime : '-'}</span>
          </div>

        </Col>
        <Col className="flex justify-end" xs={12} sm={8} md={4} lg={4}>
          <div className="text-md font-bold">Close Time:
            <span className="text-red-500 px-1">
              {ismatkaList?.closeTime ? ismatkaList?.closeTime : '-'}

            </span>
          </div>
        </Col>
      </Row>

      <Row justify={"space-between"} className="mt-2 text-white">
        <Col className="" xs={8} sm={8} md={4} lg={4}>
          <span className="gx-bold">Min: {ismatkaList?.minStake} </span>
        </Col>

        <Col className="flex justify-end" xs={8} sm={8} md={4} lg={4}>
          <span className="font-bold">Max: {ismatkaList?.maxStake}</span>
        </Col>
      </Row>

      {betList && betList.length > 0 &&
        <div className="my-2">
          <BetListTableMatka betList={betList} />
        </div>}



      <div className="lg:w-1/4 lg:block hidden">
        {backBetModal === true ?
          <div className="">
            <BetPlaceMatka
              openBets={openBets}
              // closeRow={closeRow}
              matchName={`${ismatkaList?.name}-Single`}
              betSlipData={betSlipData}
              placeBet={placeBet}
              count={betSlipData?.betNumber}
              betLoading={LoadingBet}
              section1Ref={section1Ref}
              inputRef={inputRef}
              handleClose={handleClose}
            // increaseCount={increaseCount}
            // decreaseCount={decreaseCount}
            />
          </div> : null}
      </div>


      <div className="lg:w-1/4 lg:hidden block w-full z-50 top-4 left-0 fixed justify-center">
        {backBetModal === true ?
          <div className="">

            <BetPlaceMatka
              openBets={openBets}
              // closeRow={closeRow}
              matchName={`${ismatkaList?.name}-Single`}
              betSlipData={betSlipData}
              placeBet={placeBet}
              count={betSlipData?.betNumber}
              betLoading={LoadingBet}
              section1Ref={section1Ref}
              inputRef={inputRef}
              handleClose={handleClose}
            // increaseCount={increaseCount}
            // decreaseCount={decreaseCount}
            />
          </div> : null}
      </div>
    </section>

  )
};

export default OddEvenMatka;
